<template>
  <div>
    <aside
      id="drawer-user-group-edit"
      class="mdc-drawer -modal -right drawer-user-group-edit"
      @keyup.esc="closeDrawer">
      <div class="header">
        <button
          class="navicon mdc-button mdc-ripple-upgraded"
          @click="closeDrawer"></button>
        <h2 class="title">{{ editMode | drawerTitle }}</h2>
      </div>
      <div class="content">
        <drawer-user-group-edit-form />
      </div>
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="closeDrawer"></div>
  </div>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'
import drawerUserGroupEditForm from '@/components/user-group-page/drawer-user-group-edit-form'

export default {
  filters: {
    drawerTitle(editMode) {
      if (editMode === 'add') {
        return 'ユーザーグループ新規登録'
      } else if (editMode === 'edit') {
        return 'ユーザーグループ編集'
      }
      return ''
    }
  },
  components: {
    drawerUserGroupEditForm
  },
  data() {
    return {
      drawer: {}
    }
  },
  computed: {
    isDrawerOpen: sync('ui/userGroupPage@drawer.isEditUserGroupOpened'),
    editMode: get('userGroup/editForm@mode')
  },
  watch: {
    async isDrawerOpen(value) {
      this.drawer.open = value
      value
        ? document.body.classList.add('-notscroll')
        : document.body.classList.remove('-notscroll')
    }
  },
  mounted() {
    this.drawer = new MDCDrawer(
      document.getElementById('drawer-user-group-edit')
    )
    this.drawer.open = this.isDrawerOpen
  },
  methods: {
    closeDrawer() {
      this.isDrawerOpen = false
    }
  }
}
</script>
