<template>
  <div
    class="tels-content">
    <div class="input">
      <div class="mdc-text-field -outlined -focused tel">
        <input
          v-model.trim="faxNumber"
          type="text"
          class="input">
        <p
          v-show="faxNumberErrorMessage"
          class="telNumber">{{ faxNumberErrorMessage }}</p>
      </div>
      <div class="mdc-text-field -outlined name">
        <input
          v-model.trim="name"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field -outlined namefurigana">
        <input
          v-model.trim="nameFurigana"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field -outlined supplement">
        <input
          v-model.trim="memo"
          type="text"
          class="input">
      </div>
    </div>
    <div class="btns">
      <button
        :ref="`saveFax${id}`"
        :disabled="saveDisabled"
        class="mdc-button -save"
        @click="saveFax"><svg class="icon" />保存</button>
      <button
        class="mdc-button -delete"
        @click="deleteFax"><svg class="icon" />削除</button>
    </div>
  </div>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
export default {
  props: {
    fax: {
      type: Object,
      default: function() {
        return {}
      }
    },
    userId: {
      type: Number,
      default: function() {
        return 0
      }
    },
    index: {
      type: Number,
      default: function() {
        return 0
      }
    },
    faxes: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      id: 0,
      faxNumber: '',
      name: '',
      nameFurigana: '',
      memo: '',
      faxNumberErrorMessage: '',
      saveDisabled: true
    }
  },
  computed: {
    staffId: get('auth@user.id')
  },
  watch: {
    fax() {
      this.setFax()
    },
    faxNumber() {
      this.updateSaveDisabled()

      if (!this.faxNumber && this.id) {
        this.faxNumberErrorMessage = 'FAX番号を入力してください'
        this.setSaveDisabled(true)
        return
      }

      if (!this.faxNumber && !this.id) {
        this.faxNumberErrorMessage = ''
        this.setSaveDisabled(true)
        return
      }

      let bol = this.$helpers.checkValidPhoneNumber(
        this.convertFax(this.faxNumber)
      )
      if (!bol) {
        this.faxNumberErrorMessage = 'FAX番号を確認してください'
        this.setSaveDisabled(true)
        return
      }

      const checkExistFax = this.faxes.filter(
        fax => this.convertFax(fax.fax) === this.convertFax(this.faxNumber)
      )
      if (checkExistFax.length > 0 && this.id !== checkExistFax[0].id) {
        this.setSaveDisabled(true)
        this.faxNumberErrorMessage = 'FAX番号が登録されました'
        return
      }
      this.faxNumberErrorMessage = ''
    },
    memo() {
      this.updateSaveDisabled()
    },
    nameFurigana() {
      this.updateSaveDisabled()
    },
    name() {
      this.updateSaveDisabled()
    }
  },
  created() {
    this.setFax()
  },
  methods: {
    showSnackBar: call('ui/showSnackBar'),
    createUserFax: call('user/faxes/createUserFax'),
    updateUserFax: call('user/faxes/updateUserFax'),
    deleteUserFax: call('user/faxes/deleteUserFax'),
    async saveFax() {
      try {
        let bol = this.$helpers.checkValidPhoneNumber(
          this.convertFax(this.faxNumber)
        )
        if (!bol) {
          this.faxNumberErrorMessage = 'FAX番号を確認してください'
          return
        }

        if (this.id) {
          const updateFax = await this.updateUserFax({
            userId: this.userId,
            faxId: this.id,
            fax: this.faxNumber ? this.convertFax(this.faxNumber) : '',
            name: this.name || '',
            nameFurigana: this.nameFurigana || '',
            memo: this.memo || '',
            staffId: this.staffId || 0
          })

          this.id = updateFax.data.faxId
          this.setSaveDisabled(true)
          this.$emit('update-fax')
          return
        }

        const checkExistFax = this.faxes.filter(
          faxes => faxes.fax === this.faxNumber
        )
        if (checkExistFax.length > 0) {
          this.faxNumberErrorMessage = 'FAX番号が登録されました'
          return
        }
        const createFaxObj = await this.createUserFax({
          userId: this.userId,
          fax: this.faxNumber ? this.convertFax(this.faxNumber) : '',
          name: this.name || '',
          nameFurigana: this.nameFurigana || '',
          memo: this.memo || '',
          staffId: this.staffId || 0
        })
        this.setSaveDisabled(true)
        this.id = createFaxObj.data.id
        this.$emit('update-fax')
      } catch (e) {
        this.showSnackBar({ message: '問題が発生されました' })
        this.$sentry.captureMessage('ユーザーにFAX番号追加が失敗されました。')
      }
    },
    async deleteFax() {
      if (!this.id) {
        this.$emit('remove', { index: this.index })
        return
      }

      if (
        confirm(
          `【${this.fax.fax}】のFAX番号が削除されますが、よろしいでしょうか？`
        )
      ) {
        await this.deleteUserFax({
          staffId: this.staffId || 0,
          faxId: this.id,
          userId: this.userId
        })
        this.$emit('update-fax')
      }
    },
    setFax() {
      this.id = this.fax.id
      this.faxNumber = this.$options.filters.formatPhoneNumber(this.fax.fax)
      this.name = this.fax.name
      this.nameFurigana = this.fax.nameFurigana
      this.memo = this.fax.memo
    },
    updateSaveDisabled() {
      if (this.id === 0) {
        this.setSaveDisabled(true)
      }
      if (
        (!this.faxNumber && !this.fax.fax) ||
        this.convertFax(this.faxNumber) === this.convertFax(this.fax.fax)
      ) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if ((!this.memo && !this.fax.memo) || this.memo === this.fax.memo) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if ((!this.name && !this.fax.name) || this.name === this.fax.name) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if (
        (!this.nameFurigana && !this.fax.nameFurigana) ||
        this.nameFurigana === this.fax.nameFurigana
      ) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }
    },
    setSaveDisabled(value) {
      this.saveDisabled = value
    },
    convertFax(fax) {
      return this.$helpers.zen2Han(fax).replace(/-/g, '')
    }
  }
}
</script>
