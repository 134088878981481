import { make } from 'vuex-pathify'
import {
  API_PARKS_URL,
  API_STATION_URL,
  API_AREAS_URL,
  API_STAFFS_URL
} from '@/config/api-url'

export const state = () => ({
  area: '',
  staffs: []
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  setStaffs: ({ commit }, value) => commit('SET_STAFFS', value),
  async getStaffsByParkingId(context, { parkingId }) {
    try {
      const res = await this.$axios.get(`${API_PARKS_URL}/${parkingId}/staffs`)

      context.commit('SET_AREA', res.data.area)
      context.commit('SET_STAFFS', res.data.staffs)
      if (res.data.staffs.length === 0) {
        this.app.store.set('search/staffs@errorMessage', '見つかりませんでした')
        return
      }
      this.app.store.set('search/staffs@errorMessage', '')
    } catch (e) {
      this.app.store.set(
        'search/staffs@errorMessage',
        this.app.$helpers.getResponseErrorMessage({
          statusCode: 500
        })
      )
      this.$sentry.captureException(e)
    }
  },
  async getStaffsByStation(context, params = {}) {
    try {
      const res = await this.$axios.get(
        `${API_STATION_URL}/${params.stationName}/staffs`,
        {
          params: { ...params }
        }
      )
      context.commit('SET_AREA', res.data.area)
      context.commit('SET_STAFFS', res.data.staffs)
      if (res.data.staffs.length === 0) {
        this.app.store.set('search/staffs@errorMessage', '')
        context.commit('SET_AREA', res.data.area)
        context.commit('SET_STAFFS', res.data.staffs)
        return
      }
      this.app.store.set('search/staffs@errorMessage', '')
    } catch (e) {
      this.app.store.set(
        'search/staffs@errorMessage',
        this.app.$helpers.getResponseErrorMessage({
          statusCode: 500
        })
      )
      this.$sentry.captureException(e)
    }
  },
  async getStaffsByAreas(context, params = {}) {
    try {
      const res = await this.$axios.get(
        `${API_AREAS_URL}/${params.areaName}/staffs`,
        {
          params: { ...params }
        }
      )

      context.commit('SET_AREA', res.data.area)
      context.commit('SET_STAFFS', res.data.staffs)
      if (res.data.staffs.length === 0) {
        this.app.store.set('search/staffs@errorMessage', '')
        context.commit('SET_AREA', res.data.area)
        context.commit('SET_STAFFS', res.data.staffs)
        return
      }
      this.app.store.set('search/staffs@errorMessage', '')
    } catch (e) {
      this.app.store.set(
        'search/staffs@errorMessage',
        this.app.$helpers.getResponseErrorMessage({
          statusCode: 500
        })
      )
      this.$sentry.captureException(e)
    }
  },
  async getStaffsByName(context, event) {
    const name = event.target.value
    try {
      if (!name) {
        context.commit('SET_TRANSFER_STAFFS', [])
        return
      }
      const staffs = await this.$axios.get(`${API_STAFFS_URL}`, {
        params: {
          name
        }
      })
      context.commit('SET_TRANSFER_STAFFS', staffs.data)
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
