import { make } from 'vuex-pathify'
import { API_CALL_HISTORIES_URL } from '@/config/api-url'

export const state = () => ({
  info: {}
})

export const getters = {}

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async getCallHistoriesByParams(context, params = {}) {
    try {
      const callHistoriesRes = await this.$axios.post(
        API_CALL_HISTORIES_URL,
        params
      )
      context.commit('SET_INFO', callHistoriesRes.data || [])
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async updateCallHistories(context, params = {}) {
    try {
      const updateUser = await this.$axios.patch(
        `${API_CALL_HISTORIES_URL}/${params.callHistoryId || 0}`,
        params
      )
      context.commit('SET_INFO', { ...context.state.info, ...updateUser.data })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
