import { make } from 'vuex-pathify'
import { API_PARKING_WAITING_CONTACTS } from '@/config/api-url'

export const EDIT_MODE_ADD = 'add'
export const EDIT_MODE_EDIT = 'edit'

const initialFormData = {
  status: 1,
  parkingId: '',
  contactId: '',
  suggestionFee: '',
  contactCarWidth: '',
  contactCarHeight: '',
  contactCarLength: '',
  contactCarWeight: '',
  contactCarGroundHeight: '',
  contactCarTireWidth: ''
}

export const state = () => ({
  searchParams: {
    parkingId: '',
    parkingName: '',
    contactId: '',
    contactName: '',
    staffId: '',
    statuses: [1],
    regionIds: [],
    cityIds: [],
    registeredDateFrom: '',
    registeredDateTo: '',
    parkingUpdatedDateFrom: '',
    parkingUpdatedDateTo: '',
    page: 1,
    pageSize: 10
  },
  searchResult: {
    list: [],
    numberOfPages: 0,
    canNext: false,
    canPrev: false
  },
  editFormData: {
    ...initialFormData
  },
  editForm: {
    mode: '',
    isPristine: true,
    initialFormData,
    editingId: null
  }
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async doSearchWaitingContacts(context) {
    try {
      const apiResponse = await this.$axios.get(
        `${API_PARKING_WAITING_CONTACTS}`,
        {
          params: { ...filterParams(context.state.searchParams) }
        }
      )
      const numberOfPages = Math.ceil(
        apiResponse.headers['x-total-count'] /
          context.state.searchParams.pageSize
      )
      context.commit('SET_SEARCH_RESULT', {
        ...context.state.searchResult,
        numberOfPages,
        list: apiResponse.data,
        canNext: context.state.searchParams.page < numberOfPages,
        canPrev: context.state.searchParams.page > 1
      })
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },
  async searchWaitingContacts(context) {
    context.commit('SET_SEARCH_PARAMS', {
      ...context.state.searchParams,
      page: 1
    })
    context.dispatch('doSearchWaitingContacts')
  },
  async navigateWaitingContactResult(context, { direction }) {
    const pageToGo =
      direction === 'next'
        ? context.state.searchParams.page + 1
        : context.state.searchParams.page - 1
    context.commit('SET_SEARCH_PARAMS', {
      ...context.state.searchParams,
      page: pageToGo
    })
    context.dispatch('doSearchWaitingContacts')
  },
  submitEditContactForm(context) {
    if (context.state.editForm.mode === EDIT_MODE_ADD) {
      context.dispatch('addNewWaitingContact')
    }
    if (context.state.editForm.mode === EDIT_MODE_EDIT) {
      context.dispatch('editWaitingContact')
    }
  },
  async addNewWaitingContact(context) {
    try {
      await this.$axios.post(`${API_PARKING_WAITING_CONTACTS}`, {
        ...filterParams(context.state.editFormData)
      })
      this.app.store.dispatch('ui/showSnackBar', {
        message: '空き待ちユーザー情報が登録されました。'
      })
      context.dispatch('resetAddNewWaitingContactForm')
      context.dispatch('searchWaitingContacts')
    } catch (error) {
      this.$sentry.captureException(error)
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'エラーが発生しました。'
      })
    }
  },
  prepareAddNewWaitingContactForm(context, data = {}) {
    if (context.state.editForm.mode !== EDIT_MODE_ADD) {
      context.dispatch('resetAddNewWaitingContactForm', data)
    }
  },
  resetAddNewWaitingContactForm(context, data = {}) {
    const formData = { ...initialFormData, ...data }
    context.commit('SET_EDIT_FORM_DATA', { ...formData })
    context.commit('SET_EDIT_FORM', {
      ...context.state.editForm,
      mode: EDIT_MODE_ADD,
      isPristine: true,
      initialFormData: { ...initialFormData },
      editingId: null
    })
  },
  async editWaitingContact(context) {
    try {
      await this.$axios.patch(
        `${API_PARKING_WAITING_CONTACTS}/${context.state.editForm.editingId}`,
        {
          ...filterParams(context.state.editFormData)
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: '空き待ちユーザー情報が変更されました。'
      })
      await context.dispatch('searchWaitingContacts')
      this.app.store.set(
        'ui/parkingWaitingContacts@drawer.editContact.open',
        false
      )
    } catch (error) {
      this.$sentry.captureException(error)
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'エラーが発生しました。'
      })
    }
  },
  prepareEditWaitingContactForm(context, { contactId }) {
    /**
     * if contactId exist the use that value as editing contact id
     *  else use current editing id in store
     *  else return
     */
    const editingContactId = contactId
      ? contactId
      : context.state.editForm.editingId
        ? context.state.editForm.editingId
        : null

    if (!editingContactId) {
      return
    }

    const editingContact = context.state.searchResult.list.find(
      contact => contact.id === editingContactId
    )

    // only select necessary value from original contact object
    const necessaryFormData = Object.keys(initialFormData).reduce(
      (formData, key) => {
        return {
          ...formData,
          [key]: editingContact[key]
        }
      },
      {}
    )
    context.commit('SET_EDIT_FORM_DATA', {
      ...necessaryFormData
    })
    context.commit('SET_EDIT_FORM', {
      ...context.state.editForm,
      mode: EDIT_MODE_EDIT,
      isPristine: true,
      initialFormData: { ...necessaryFormData },
      editingId: editingContact.id
    })
  },
  checkFormPristine(context) {
    const isPristine = Object.keys(
      context.state.editForm.initialFormData
    ).every(attributeName => {
      return (
        context.state.editForm.initialFormData[attributeName] ===
        context.state.editFormData[attributeName]
      )
    })
    context.commit('SET_EDIT_FORM', {
      ...context.state.editForm,
      isPristine
    })
  }
}

function filterParams(params) {
  return Object.keys(params).reduce((filteredParams, paramKey) => {
    if (
      params[paramKey] === '' ||
      params[paramKey] === null ||
      typeof params[paramKey] === 'undefined'
    ) {
      return filteredParams
    }
    return { ...filteredParams, [paramKey]: params[paramKey] }
  }, {})
}
