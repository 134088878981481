<template>
  <nav
    v-if="pageCount > 1"
    aria-label=""
    class="pagination">
    <ul
      id="pagination-content"
      class="mdc-chip-set">
      <li
        :class="{ '-hidden': currentPage === 1}"
        class="mdc-chip"
        @click="prevPage"
      ><p class="text">{{ firstIcon }}</p></li>
      <li
        v-for="(pageNum ,index) in paginationNumber"
        :key="index"
        :class="{ '-active': pageNum === currentPage}"
        class="mdc-chip"
        @click="pageNumClick">
        <p class="text">{{ pageNum }}</p>
      </li>
      <li
        :class="{ '-hidden': currentPage === pageCount}"
        class="mdc-chip"
        @click="nextPage"><p class="text">{{ lastIcon }}</p></li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    pageCount: {
      type: Number,
      default: 0
    },
    curPage: {
      type: Number,
      default: 1
    },
    pageRange: {
      type: Number,
      default: 11
    }
  },
  data() {
    return {
      firstIcon: '<',
      lastIcon: '>',
      lastPageNumber: 2,
      firstPageNumber: 2,
      paginationNumber: [],
      currentPage: this.curPage
    }
  },
  watch: {
    async currentPage(value) {
      await this.rangePaginationNumber()
      this.$emit('click-handler', { curPage: this.currentPage })
    },
    pageCount(value) {
      this.rangePaginationNumber()
    },
    curPage(value) {
      this.currentPage = value
    },
    pageRange(value) {
      this.currentPage = 1
      this.pageRange = value
      this.rangePaginationNumber()
    }
  },
  mounted() {
    this.rangePaginationNumber()
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1
      }
    },
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage += 1
      }
    },
    pageNumClick(event) {
      if (event.target.textContent !== '...') {
        this.currentPage = parseInt(event.target.textContent)
      }
    },
    async rangePaginationNumber() {
      this.paginationNumber = []
      if (this.pageCount === 0) {
        this.paginationNumber = []
        return
      }

      if (
        this.pageCount > 0 &&
        this.pageCount <=
          this.pageRange + this.lastPageNumber + this.firstPageNumber
      ) {
        this.paginationNumber = await this.rangeNumber(1, this.pageCount, 1)
        return
      } else {
        let pageCenterNumber =
          this.pageRange - this.lastPageNumber - this.firstPageNumber
        let pageCenterFirstNumber = 0
        let pageCenterLastNumber = 0

        if (this.currentPage <= Math.floor(pageCenterNumber / 2) + 2) {
          pageCenterFirstNumber = 1
          pageCenterLastNumber = pageCenterFirstNumber + pageCenterNumber
          this.paginationNumber = this.rangeNumber(
            pageCenterFirstNumber,
            pageCenterLastNumber,
            1
          )
          this.paginationNumber = [...this.paginationNumber, '...']
          for (let i = this.lastPageNumber; i > 0; i--) {
            this.paginationNumber = [
              ...this.paginationNumber,
              this.pageCount - i + 1
            ]
          }
          return
        }

        if (
          this.currentPage >=
          this.pageCount - Math.floor(pageCenterNumber / 2) - 2
        ) {
          pageCenterLastNumber = this.pageCount
          pageCenterFirstNumber = this.pageCount - pageCenterNumber
          for (let i = 1; i <= this.firstPageNumber; i++) {
            this.paginationNumber = [...this.paginationNumber, i]
          }
          this.paginationNumber = [...this.paginationNumber, '...']
          this.paginationNumber = [
            ...this.paginationNumber,
            ...this.rangeNumber(pageCenterFirstNumber, pageCenterLastNumber, 1)
          ]
          return
        }

        pageCenterNumber = Math.floor(
          (this.pageRange - this.firstPageNumber - this.lastPageNumber - 2) / 2
        )
        pageCenterFirstNumber = this.currentPage - pageCenterNumber
        pageCenterLastNumber =
          pageCenterFirstNumber +
          (this.pageRange - this.firstPageNumber - this.lastPageNumber - 2) -
          1
        for (let i = 1; i <= this.firstPageNumber; i++) {
          this.paginationNumber = [...this.paginationNumber, i]
        }
        this.paginationNumber = [...this.paginationNumber, '...']
        this.paginationNumber = [
          ...this.paginationNumber,
          ...this.rangeNumber(pageCenterFirstNumber, pageCenterLastNumber, 1)
        ]
        this.paginationNumber = [...this.paginationNumber, '...']
        for (let i = this.lastPageNumber; i > 0; i--) {
          this.paginationNumber = [
            ...this.paginationNumber,
            this.pageCount - i + 1
          ]
        }
      }
    },
    rangeNumber(start, stop, step) {
      return Array.from(
        { length: (stop - start + 1) / step },
        (_, i) => start + i * step
      )
    }
  }
}
</script>
