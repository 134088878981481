<template>
  <div>
    <the-header />
    <the-side-bar />
    <nuxt class="the-biztel-content" />
    <notification-detail />
    <notification-push-dialog />
    <notification-phone-reception-request-notice />
    <circular-indeterminate-progress/>
    <drawer-user-edit />
    <rep-waiting-alert />
    <dialog-snack-bar />
  </div>
</template>
<script>
import theHeader from '@/components/the-header'
import theSideBar from '@/components/biztel/the-side-bar'
import notificationDetail from '@/components/notification-detail'
import notificationPushDialog from '@/components/notification/notification-push-dialog'
import notificationPhoneReceptionRequestNotice from '@/components/notification-phone-reception-request-notice'
import circularIndeterminateProgress from '@/components/circular-indeterminate-progress'
import drawerUserEdit from '@/components/user-page/drawer-user-edit'
import repWaitingAlert from '@/components/alert/rep-waiting-alert'
import dialogSnackBar from '@/components/dialog-snack-bar'
import { get } from 'vuex-pathify'

export default {
  components: {
    theHeader,
    theSideBar,
    notificationDetail,
    notificationPushDialog,
    notificationPhoneReceptionRequestNotice,
    circularIndeterminateProgress,
    drawerUserEdit,
    repWaitingAlert,
    dialogSnackBar
  },
  head() {
    return {
      link: [{ rel: 'icon', type: 'image/png', href: '/biztel-favicon.png' }]
    }
  },
  computed: {
    hasPhoneReceptionRequestNotification: get(
      'notifications/hasPhoneReceptionRequestNotification'
    ),
    hasPhoneNoteNotification: get('notifications/hasPhoneNoteNotification'),
    hasNotification() {
      return (
        this.hasPhoneReceptionRequestNotification ||
        this.hasPhoneNoteNotification
      )
    }
  },
  watch: {
    hasNotification() {
      this.setFavicon()
    }
  },
  mounted() {
    this.setFavicon()
  },
  methods: {
    setFavicon() {
      if (this.hasNotification) {
        this.$helpers.setFavicon('/biztel-n-favicon.png')
      } else {
        this.$helpers.setFavicon('/biztel-favicon.png')
      }
    }
  }
}
</script>
