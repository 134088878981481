<template>
  <transition name="modal">
    <div class="confirm-modal">
      <div class="wrapper">
        <div class="container">
          <div class="body">
            <slot>
            </slot>
          </div>
          <div class="footer">
            <button
              class="mdc-button -cancel"
              @click="onCancel">キャンセル</button>
            <button
              class="mdc-button -confirmed"
              @click="onConfirm">OK</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    onConfirm() {
      this.$emit('confirm')
    },
    onCancel() {
      this.$emit('cancel')
    }
  }
}
</script>
