import { make } from 'vuex-pathify'
import { API_USERS_URL, API_USER_GROUP_URL } from '@/config/api-url'

export const state = () => ({
  info: {
    id: '',
    name: '',
    memo: ''
  },
  users: [],
  currentPage: 1,
  numberOfPages: 1,
  numberOfUsers: 0,
  editForm: {
    mode: '',
    isPristine: true,
    newlyCreatedGroupId: null,
    name: '',
    memo: ''
  }
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async fetchGroupInfo(context, { userGroupId }) {
    try {
      const response = await this.$axios.get(
        `${API_USER_GROUP_URL}/${userGroupId}`
      )
      context.commit('SET_INFO', response.data)
    } catch (error) {
      context.commit('SET_INFO', {
        name: '',
        memo: ''
      })
      this.$sentry.captureException(error)
    }
  },
  async fetchUsers(context, params = {}) {
    const userGroupId = context.state.info.id
    if (!userGroupId) {
      context.commit('SET_USERS', [])
      context.commit('SET_CURRENT_PAGE', 1)
      context.commit('SET_NUMBER_OF_PAGES', 1)
      context.commit('SET_NUMBER_OF_USERS', 0)
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'エラーが発生しました。'
      })
      return
    }
    let { page = 1, pageSize = 10 } = params
    try {
      let response = await this.$axios.get(`${API_USERS_URL}`, {
        params: {
          statuses: [1], // only get the active users
          userGroupId,
          page,
          pageSize
        }
      })
      if (
        Array.isArray(response.data) &&
        response.data.length === 0 &&
        page > 1
      ) {
        // fix for case removing the last user of the last page causing no user found
        page = page - 1
        response = await this.$axios.get(`${API_USERS_URL}`, {
          params: {
            statuses: [1], // only get the active users
            userGroupId,
            page,
            pageSize
          }
        })
      }
      context.commit('SET_USERS', response.data)
      context.commit('SET_CURRENT_PAGE', page)
      context.commit(
        'SET_NUMBER_OF_PAGES',
        Math.ceil(response.headers['x-total-count'] / pageSize)
      )
      context.commit('SET_NUMBER_OF_USERS', response.headers['x-total-count'])
    } catch (error) {
      context.commit('SET_USERS', [])
      context.commit('SET_CURRENT_PAGE', 1)
      context.commit('SET_NUMBER_OF_PAGES', 1)
      context.commit('SET_NUMBER_OF_USERS', 0)
      this.$sentry.captureException(error)
    }
  },
  async addUserToGroup(context, { userId, userName }) {
    const userGroupId = context.state.info.id
    if (!userGroupId) {
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'エラーが発生しました。'
      })
      return
    }
    try {
      await this.$axios.patch(`${API_USERS_URL}/${userId}`, {
        staffId: context.rootGetters['staffId'],
        userGroupId: parseInt(userGroupId)
      })
    } catch (error) {
      this.$sentry.captureException(error)
    }
    context.dispatch('fetchUsers', { page: context.state.currentPage })
    context.dispatch(
      'search/userGroupUsers/updateUserInSearchResult',
      { userId, userGroupId },
      { root: true }
    )
    this.app.store.dispatch('ui/showSnackBar', {
      message: `「${userName}」を「${
        context.state.info.name
      }」グループに追加しました。 `
    })
  },
  async removeUserFromGroup(context, { userId }) {
    const userGroupId = context.state.info.id
    if (!userGroupId) {
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'エラーが発生しました。'
      })
      return
    }
    try {
      await this.$axios.patch(`${API_USERS_URL}/${userId}`, {
        staffId: context.rootGetters['staffId'],
        userGroupId: -1 // set -1 to remove group.
      })
    } catch (error) {
      this.$sentry.captureException(error)
    }
    context.dispatch('fetchUsers', { page: context.state.currentPage })
    context.dispatch(
      'search/userGroupUsers/updateUserInSearchResult',
      { userId, userGroupId: -1 },
      { root: true }
    )
  },
  async prepareEditForm(context) {
    if (context.state.editForm.mode === 'edit') {
      return
    }
    context.commit('SET_EDIT_FORM', {
      mode: 'edit',
      isPristine: true,
      newlyCreatedGroupId: null,
      name: context.state.info.name,
      memo: context.state.info.memo,
      isEnabled: context.state.info.isEnabled
    })
  },
  async prepareAddForm(context) {
    if (context.state.editForm.mode === 'add') {
      return
    }
    context.commit('SET_EDIT_FORM', {
      mode: 'add',
      isPristine: true,
      newlyCreatedGroupId: null,
      name: '',
      memo: ''
    })
  },
  async saveUserGroup(context) {
    if (context.state.editForm.mode === 'add') {
      await context.dispatch('createNewUserGroup')
    } else if (context.state.editForm.mode === 'edit') {
      await context.dispatch('editUserGroup')
    }
  },
  async createNewUserGroup(context) {
    try {
      const response = await this.$axios.post(`${API_USER_GROUP_URL}`, {
        name: context.state.editForm.name,
        memo: context.state.editForm.memo,
        createdManagerId: context.rootGetters['staffId']
      })
      context.commit('SET_INFO', response.data)
      context.commit('SET_EDIT_FORM', {
        ...context.state.editForm,
        newlyCreatedGroupId: response.data.id
      })
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'ユーザーグループ情報が登録されました。'
      })
    } catch (error) {
      this.$sentry.captureException(error)
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'エラーが発生しました。'
      })
    }
  },
  async editUserGroup(context) {
    try {
      const response = await this.$axios.patch(
        `${API_USER_GROUP_URL}/${context.state.info.id}`,
        {
          name: context.state.editForm.name,
          memo: context.state.editForm.memo,
          isEnabled: context.state.editForm.isEnabled,
          updatedManagerId: context.rootGetters['staffId']
        }
      )
      context.commit('SET_INFO', response.data)
      context.commit('SET_EDIT_FORM', {
        ...context.state.editForm,
        isPristine: true,
        name: context.state.info.name,
        memo: context.state.info.memo,
        isEnabled: context.state.info.isEnabled
      })
      context.dispatch('fetchUsers')
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'ユーザーグループ情報が更新されました。'
      })
    } catch (error) {
      this.$sentry.captureException(error)
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'エラーが発生しました。'
      })
    }
  }
}
