<template>
  <div
    v-show="show"
    ref="notification-notice"
    class="component-overlay">
    <div class="notification-phone-reception-request-notice -elevation15">
      <div class="title">
        <img
          :src="noticeTitleImage"
          alt=""
          class="icon">
        <p 
          class="text" 
          v-html="noticeTitleText"></p>
      </div>
      <div class="details">
        <p
          v-show="isWaiting"
          class="prompt">
          <span>{{ waitingPrompt }}</span>
        </p>
        <div
          v-show="isHandled"
          class="prompt">
          {{ handledStaffName }}さん
          <span
            v-show="handledStaffExtensionNumber"
            class="extnumtxt">
            <span id="extnum">（内線：{{ handledStaffExtensionNumber }}）</span>
            <copy-button
              :el-id="'extnum'"
              class="copy-button"/>
          </span>
          が対応可能です。
        </div>
        <div class="content">
          <p
            class="text"
            v-html="notificationMessage"></p>
        </div>
      </div>
      <div class="actions">
        <button
          v-show="notificationState === STATE_WAITING"
          class="mdc-button -waiting"
          @click="hideNotificationNotice">もう少し待つ</button>
        <button
          class="mdc-button -raised -accept"
          @click="acceptButtonAction">{{ notificationState === STATE_HANDLED ? '了解' : '自分対応する' }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { get, sync, call } from 'vuex-pathify'
import { COLLECTION_PHONE_RECEPTION_REQUEST } from '@/store/notifications'
import copyButton from '@/components/copy-button'

const STATE_HANDLED = 'handled'
const STATE_REFUSED = 'refused'
const STATE_WAITING = 'waiting'
const STATE_TIMEOUT = 'timeout'
export default {
  components: {
    copyButton
  },
  data() {
    return {
      STATE_HANDLED,
      STATE_REFUSED,
      STATE_WAITING,
      STATE_TIMEOUT,
      titleImage: {
        reminder: require('@/assets/svg/notice-reminder.svg'),
        timeout: require('@/assets/svg/notice-timeout.svg'),
        allRefused: require('@/assets/svg/notice-all-refused.svg'),
        accepted: require('@/assets/svg/notice-accepted.svg')
      }
    }
  },
  computed: {
    openNotificationNotice: sync('ui/notificationNotice@open'),
    notification: get('notifications/notificationNotice@notification'),
    isNotification() {
      return this.notification && this.notification.id
    },
    isWaiting() {
      return (
        this.isNotification &&
        (this.notificationState === STATE_WAITING ||
          this.notificationState === STATE_TIMEOUT)
      )
    },
    isHandled() {
      return this.isNotification && this.notificationState === STATE_HANDLED
    },
    show() {
      return (
        this.openNotificationNotice &&
        this.isNotification &&
        this.notification.type === COLLECTION_PHONE_RECEPTION_REQUEST &&
        this.notification.attributes &&
        this.notification.sender &&
        this.notification.attributes.handlerId !==
          this.notification.sender.id &&
        (this.notificationState === STATE_WAITING ||
          this.notificationState !== STATE_WAITING)
      )
    },
    notificationState() {
      if (!this.isNotification) return STATE_WAITING
      if (this.notification.attributes.handlerId) {
        // someone will take the call
        return STATE_HANDLED
      }
      if (this.notification.attributes.timeout) {
        return STATE_TIMEOUT
      }
      const isRefused =
        !this.notification.attributes.handlerId &&
        this.notification.attributes.pendingIds.length === 0
      if (isRefused || this.notification.attributes.timeout) {
        return STATE_REFUSED
      }
      return STATE_WAITING
    },
    noticeTitleImage() {
      switch (this.notificationState) {
        case STATE_HANDLED:
          return this.titleImage.accepted
        case STATE_REFUSED:
          return this.titleImage.allRefused
        case STATE_WAITING:
          return this.titleImage.reminder
        case STATE_TIMEOUT:
          return this.titleImage.timeout
      }
    },
    noticeTitleText() {
      switch (this.notificationState) {
        case STATE_HANDLED:
          return '対応可能です。'
        case STATE_REFUSED:
          return '全員対応不可でした。'
        case STATE_WAITING:
        case STATE_TIMEOUT:
          if (!this.notification || !this.notification.attributes) {
            return ''
          }
          const receiver = this.notification.attributes.receiverName
            ? `<span class="receivername">「${
                this.notification.attributes.receiverName
              }」</span>に`
            : ''
          const elapsedTime = this.notification.attributes.elapsedTime
            ? `リクエストを送信してから<span class="elapsedtime">${
                this.notification.attributes.elapsedTime
              }</span>秒経過しました。`
            : 'リクエストを送信しました。'
          return `${receiver}${elapsedTime}`
      }
    },
    waitingPrompt() {
      return this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.elapsedTime
        ? `通知してから${
            this.notification.attributes.elapsedTime
          }秒が経過しました。自分で対応しますか？`
        : ''
    },
    receiver() {
      return this.isNotification &&
        this.notification.receivers &&
        this.notification.attributes &&
        this.notification.attributes.receiverName
        ? `「${this.notification.attributes.receiverName}」に`
        : ''
    },
    handledStaffName() {
      return this.isNotification &&
        this.notification.receivers &&
        this.notification.attributes &&
        this.notification.attributes.handlerId &&
        this.notification.receivers[this.notification.attributes.handlerId] &&
        this.notification.receivers[this.notification.attributes.handlerId]
          .username
        ? this.notification.receivers[this.notification.attributes.handlerId]
            .username
        : ''
    },
    handledStaffExtensionNumber() {
      return this.isNotification &&
        this.notification.receivers &&
        this.notification.attributes &&
        this.notification.attributes.handlerId &&
        this.notification.receivers[this.notification.attributes.handlerId] &&
        this.notification.receivers[this.notification.attributes.handlerId]
          .extensionNumber
        ? this.notification.receivers[this.notification.attributes.handlerId]
            .extensionNumber
        : ''
    },
    notificationMessage() {
      return this.isNotification && this.notification.content
        ? this.$helpers.nl2br(this.notification.content.message)
        : ''
    }
  },
  methods: {
    setRequestIsAccepted: call('notifications/setRequestIsAccepted'),
    hideNotificationNotice: call('notifications/hideNotificationNotice'),
    acceptButtonAction() {
      if (this.notificationState !== STATE_HANDLED) {
        this.setRequestIsAccepted({ requestId: this.notification.id })
      } else {
        this.hideNotificationNotice()
      }
    }
  }
}
</script>
