<template>
  <div
    v-if="open"
    class="phone-reception-request-list -elevation8">
    <div class="title">
      <p class="text">新規対応の可否</p>
    </div>
    <div class="status">
      <div class="statuschooser">
        <div class="mdc-checkbox -unhandled">
          <input
            v-model="unhandled"
            type="checkbox"
            class="nativecontrol">
        </div>
        <p class="text">未対応</p>
        <span
          :class="{'-empty': unconfirmedPhoneReceptionRequestCount === 0 }"
          class="notification">{{ unconfirmedPhoneReceptionRequestCount }}</span>
      </div>
    </div>
    <div class="phone-reception-request-container">
      <div
        v-if="phoneReceptionRequests.length === 0"
        class="nonotification">
        新規通知がありません。
      </div>
      <div
        v-for="request in phoneReceptionRequests"
        :key="request.id"
        :class="{
          '-unread': !request.attributes.handlerId && !request.attributes.timeout && request.attributes.unreadIds.includes(staffId),
          '-disable': request.attributes.handlerId || request.attributes.timeout || (!request.attributes.handlerId && !request.attributes.pendingIds.includes(staffId))
        }"
        class="item">
        <div class="addtionalinfo">
          <span class="date">{{ formatDate(request.createdAt) }} from {{ request.sender.username }}</span>
        </div>
        <div class="phone-reception-request-list-content">
          <div
            class="content"
            @click="showDetailNotification(request)">
            <p
              v-show="request.content.message"
              class="text"
              v-html="$helpers.nl2br($helpers.formatMessage(request.content.message, 4, 20))"></p>
          </div>
          <div class="actions">
            <div class="statebox">
              <div
                :class="{ '-group': request.receiverIds.length > 1 }"
                class="iconcontainer">
                <img
                  :src="setIconImage(request)"
                  alt=""
                  class="icon">
              </div>
              <div
                v-show="!request.attributes.handlerId"
                :class="{ '-urgent': !request.attributes.timeout && request.attributes.elapsedTime > 0, '-timeout': request.attributes.timeout }"
                class="state">
                <span class="text">{{ !request.attributes.timeout ? '未対応' : 'タイムアウト' }}</span>
              </div>
              <div
                v-show="!request.attributes.handlerId && !request.attributes.timeout &&request.attributes.elapsedTime > 0"
                class="elapsedtime">
                <img
                  :src="iconImage.elapsedTime"
                  alt=""
                  class="icon -circle">
                <span class="text">{{ request.attributes.elapsedTime }}</span>
              </div>
            </div>
            <div
              v-show="!request.attributes.handlerId"
              class="buttons">
              <button
                :disabled="request.attributes.timeout || request.attributes.handlerId || (!request.attributes.handlerId && !request.attributes.pendingIds.includes(staffId))"
                class="mdc-button -refuse"
                @click="refuseRequest(request.id)">対応不可</button>
              <button
                :disabled="request.attributes.timeout || request.attributes.handlerId || (!request.attributes.handlerId && !request.attributes.pendingIds.includes(staffId))"
                class="mdc-button -raised -accept"
                @click="acceptRequest(request.id)">
                対応する
              </button>
            </div>
            <div
              v-show="request.attributes.handlerId"
              class="acceptor">{{ getHandleStaffName(request) }}さんが対応しました</div>
          </div>
        </div>
      </div>
      <div
        v-show="canLoadNextPhoneReceptionRequests"
        class="loadmore">
        <button
          class="mdc-button loadmorebtn"
          @click="showNextRequests">
          もっと見る
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { get, sync, call } from 'vuex-pathify'
export default {
  data() {
    return {
      unhandled: false,
      iconImage: {
        mine: require('@/assets/svg/baseline-notifications_active-24px.svg'),
        group: require('@/assets/svg/baseline-people-24px.svg'),
        elapsedTime: require('@/assets/svg/notice-timeout.svg')
      }
    }
  },
  computed: {
    staffId: get('staffId'),
    phoneReceptionRequests: get('notifications/phoneReceptionRequests'),
    canLoadNextPhoneReceptionRequests: get(
      'notifications/canLoadNextPhoneReceptionRequests'
    ),
    phoneReceptionRequestsPages: sync(
      'notifications/phoneReceptionRequestsPages'
    ),
    unconfirmedPhoneReceptionRequestCount: get(
      'notifications/unconfirmedPhoneReceptionRequestCount'
    ),
    phoneReceptionRequestsDisplayUnhandled: sync(
      'notifications/phoneReceptionRequestsDisplayUnhandled'
    ),
    open: sync('ui/phoneReceptionRequestList@open')
  },
  watch: {
    unhandled() {
      this.phoneReceptionRequestsDisplayUnhandled = this.unhandled
      this.watchPhoneReceptionRequest()
    }
  },
  methods: {
    setRequestIsAccepted: call('notifications/setRequestIsAccepted'),
    setRequestIsRefused: call('notifications/setRequestIsRefused'),
    showNotificationDetail: call('notifications/showNotificationDetail'),
    watchPhoneReceptionRequest: call(
      'notifications/watchPhoneReceptionRequest'
    ),
    formatDate(createdAt) {
      return this.$helpers.formatDateFromFirestore(createdAt)
    },
    setIconImage(request) {
      return request.receiverIds.length === 1
        ? this.iconImage.mine
        : this.iconImage.group
    },
    getHandleStaffName(request) {
      if (request.attributes.handlerId) {
        const handlerId = request.attributes.handlerId
        if (request.receivers[handlerId]) {
          return request.receivers[handlerId].username
        }
        if (handlerId === request.sender.id) {
          return request.sender.username
        }
      }
      return ''
    },
    acceptRequest(requestId) {
      this.setRequestIsAccepted({ requestId })
    },
    refuseRequest(requestId) {
      this.setRequestIsRefused({ requestId })
    },
    showDetailNotification(request) {
      this.showNotificationDetail({
        notificationId: request.id,
        notificationType: request.type
      })
      this.open = false
    },
    showNextRequests() {
      this.phoneReceptionRequestsPages = this.phoneReceptionRequestsPages + 1
      this.watchPhoneReceptionRequest()
    }
  }
}
</script>
