<template>
  <div
    v-if="isShowSearchHintUsers && searchHintUsersResult.totalCount > 0"
    class="parking-providing-user-search-hint">
    <div class="hint">
      <button
        :class="isShowResult ? 'open' : 'close'"
        class="mdc-button"
        @click="showHintUsers()">
        {{ hintButtonLabel }}
      </button>
    </div>
    <parking-providing-user-search-result
      v-if="isShowResult"
      :users="searchHintUsersResult"
      :is-searching-hint-user="true"/>
  </div>
</template>
<script>
import { get, dispatch, call } from 'vuex-pathify'
import { UserType } from '@/config/user'
import parkingProvidingUserSearchResult from '@/components/drawer/parking-providing-user-search-result'

export default {
  components: {
    parkingProvidingUserSearchResult
  },
  data() {
    return {
      isShowResult: false
    }
  },
  computed: {
    searchParams: get('search/parkingProvidingUsers@searchParams'),
    searchHintUsersResult: get('search/parkingProvidingUsers@searchHintResult'),
    isShowSearchHintUsers: get('ui/searchParkingProvidingHintUsers@isShow'),
    hintButtonLabel() {
      if (
        // 管理会社の場合
        this.searchParams.userTypes.length === 1 &&
        this.searchParams.userTypes[0] === UserType.PROJECT_MANAGEMENT
      ) {
        return this.isShowResult
          ? 'オーナーあるいは問い合わせユーザーを閉じる'
          : `オーナーあるいは問い合わせユーザーも表示 (${
              this.searchHintUsersResult.totalCount
            }件)`
      }
      if (
        // オーナーの場合
        this.searchParams.userTypes.length === 1 &&
        this.searchParams.userTypes[0] === UserType.OWNER
      ) {
        return this.isShowResult
          ? '管理会社あるいは問い合わせユーザーを閉じる'
          : `管理会社あるいは問い合わせユーザーも表示 (${
              this.searchHintUsersResult.totalCount
            }件)`
      }
      // 問い合わせユーザーの場合
      return this.isShowResult
        ? '問い合わせユーザーを閉じる'
        : `問い合わせユーザーも表示 (${
            this.searchHintUsersResult.totalCount
          }件)`
    }
  },
  watch: {
    isShowSearchHintUsers(value) {
      if (value) {
        this.isShowResult = false
        this.getHintUsers()
      }
    }
  },
  methods: {
    getHintUsers() {
      // Storeに検索パラメーターを設定する
      dispatch('search/parkingProvidingUsers/setSearchParams', {
        ...this.searchParams,
        searchHintUser: true
      })
      // ヒントユーザーリストを取得する
      dispatch('search/parkingProvidingUsers/getHintUsers')
    },
    showHintUsers() {
      this.isShowResult = !this.isShowResult
    }
  }
}
</script>
