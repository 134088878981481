<template>
  <div
    class="parking-providing-user-search-form">
    <div class="title">
      <h1 class="text">検索条件</h1>
    </div>
    <div class="mdc-layout-grid content">
      <div class="inner">
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span class="label">属性</span>
          <div class="checkbox -usertypes">
            <div
              v-for="userTypeEntry in UserType.entries"
              :key="userTypeEntry.value"
              class="checkboxcontainer"
            >
              <div class="mdc-checkbox -unconfirmed -notlast">
                <input
                  :id="`${userTypeEntry.value}-checkbox`"
                  v-model="userTypes"
                  :value="userTypeEntry.value"
                  type="checkbox"
                  class="nativecontrol"
                >
              </div>
              <label
                :for="`${userTypeEntry.value}-checkbox`"
                class="checkboxlabel"
              >{{ userTypeEntry.label }}</label>
            </div>
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span
            class="label"
            v-html="nameLabel"></span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="name"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span class="label">TEL</span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="tel"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span class="label">MAIL</span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="email"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span class="label">駐車場ID</span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="parkingId"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span class="label">Sansanデータ</span>
          <div class="checkbox -sansanstatuses">
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="parking-providing-user-sansan-statuses-checkbox-1"
                v-model.number="sansanStatuses"
                type="checkbox"
                value="1"
                class="nativecontrol">
            </div>
            <label
              class="checkboxlabel"
              for="parking-providing-user-sansan-statuses-checkbox-1">有り</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="parking-providing-user-sansan-statuses-checkbox-2"
                v-model.number="sansanStatuses"
                type="checkbox"
                value="0"
                class="nativecontrol">
            </div>
            <label
              class="checkboxlabel"
              for="parking-providing-user-sansan-statuses-checkbox-2">無し</label>
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span class="label">状態</span>
          <div class="checkbox -userstatuses">
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="parking-providing-user-statuses-checkbox-1"
                v-model.number="statuses"
                type="checkbox"
                value="1"
                class="nativecontrol">
            </div>
            <label
              class="checkboxlabel"
              for="parking-providing-user-statuses-checkbox-1">有効</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="parking-providing-user-statuses-checkbox-2"
                v-model.number="statuses"
                type="checkbox"
                value="0"
                class="nativecontrol">
            </div>
            <label
              class="checkboxlabel"
              for="parking-providing-user-statuses-checkbox-2">無効</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="parking-providing-user-statuses-checkbox-3"
                v-model.number="statuses"
                type="checkbox"
                value="2"
                class="nativecontrol">
            </div>
            <label for="parking-providing-user-statuses-checkbox-3">名寄せ</label>
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input">
          <span class="label">反社チェック</span>
          <div class="checkbox -has-anti-social-forces-checked">
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="has-anti-social-forces-checked-checkbox-1"
                v-model.number="hasAntiSocialForcesChecked"
                type="checkbox"
                value="1"
                class="nativecontrol">
            </div>
            <label
              class="checkboxlabel"
              for="has-anti-social-forces-checked-checkbox-1">済み</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="has-anti-social-forces-checked-checkbox-2"
                v-model.number="hasAntiSocialForcesChecked"
                type="checkbox"
                value="0"
                class="nativecontrol">
            </div>
            <label
              class="checkboxlabel"
              for="has-anti-social-forces-checked-checkbox-2">無し</label>
          </div>
        </div>
        <div class="cell -span12desktop -span12tablet -span12mobile parking-providing-user-search-form-input">
          <span class="label">物件確認先</span>
          <div class="checkbox -user-parking-source-checked-source-type">
            <template v-for="(sourceTypeEntry) in searchingSourceType">
              <div
                :key="`${sourceTypeEntry.value}-input`"
                class="mdc-checkbox -unconfirmed -notlast"
              >
                <input
                  :id="`${sourceTypeEntry.value}-checkbox`"
                  v-model="userParkingSourceTypes"
                  :value="sourceTypeEntry.value"
                  type="checkbox"
                  class="nativecontrol">
              </div>
              <label
                :key="`${sourceTypeEntry.value}-label`"
                :for="`${sourceTypeEntry.value}-checkbox`"
                class="checkboxlabel"
              >{{ sourceTypeEntry.label }}</label>
            </template>
          </div>
        </div>
        <div
          v-show="isShowUserParkingSourceCompanyName"
          class="cell -span12desktop -span12tablet -span12mobile parking-providing-user-search-form-input"
        >
          <span class="label"></span>
          <div class="checkbox -user-parking-source-checked-source-type">
            <template v-for="(companyNameEntry) in searchingCompanyName">
              <div
                :key="`${companyNameEntry.value}-input`"
                class="mdc-checkbox -unconfirmed -notlast"
              >
                <input
                  :id="`${companyNameEntry.value}-checkbox`"
                  v-model="userParkingSourceCompanyNames"
                  :value="companyNameEntry.value"
                  type="checkbox"
                  class="nativecontrol">
              </div>
              <label
                :key="`${companyNameEntry.value}-label`"
                :for="`${companyNameEntry.value}-checkbox`"
                class="checkboxlabel"
              >{{ companyNameEntry.label }}</label>
            </template>
          </div>
        </div>
        <div
          v-show="isShowUserParkingSourceCompanySource"
          class="cell -span6desktop -span8tablet -span4mobile parking-providing-user-search-form-input"
        >
          <span class="label">物件確認先URL</span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="userParkingSourceCompanySource"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync, dispatch, call } from 'vuex-pathify'
import { UserType } from '@/config/user'
import { paginationConstants } from '@/config/pagination'
import { SourceType, CompanyName } from '@/store/user/userParkingSourceCheckeds'

export default {
  data() {
    const searchingCompanyName = CompanyName.entries.filter(
      ({ value }) => value !== CompanyName.NONE
    )
    const searchingSourceType = SourceType.entries.filter(
      ({ value }) => value !== SourceType.NONE
    )

    return {
      UserType,
      searchingCompanyName,
      searchingSourceType
    }
  },
  computed: {
    isSearching: sync('ui/searchParkingProvidingUsers@isSearching'),
    searchParams: get('search/parkingProvidingUsers@searchParams'),
    searchUsersResult: get('search/parkingProvidingUsers@searchResult'),
    isShowSearchHintUsers: sync('ui/searchParkingProvidingHintUsers@isShow'),
    isShowUserParkingSourceCompanyName() {
      return this.userParkingSourceTypes.includes(SourceType.WEB)
    },
    isShowUserParkingSourceCompanySource() {
      return (
        this.userParkingSourceCompanyNames.includes(CompanyName.OTHER) ||
        this.userParkingSourceCompanyNames.includes(
          CompanyName.PROVIDER_COMPANY
        )
      )
    },
    nameLabel() {
      if (
        this.userTypes.length === 1 &&
        this.userTypes[0] === UserType.PROJECT_MANAGEMENT
      ) {
        return '管理会社名'
      }
      if (this.userTypes.length === 1 && this.userTypes[0] === UserType.OWNER) {
        return 'オーナー名'
      }
      return '管理会社名<br/>オーナー名'
    },
    name: {
      get() {
        return this.searchParams.name
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          name: value
        })
      }
    },
    tel: {
      get() {
        return this.searchParams.tel
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          tel: value
        })
      }
    },
    email: {
      get() {
        return this.searchParams.email
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          email: this.$helpers.zen2Han(value)
        })
      }
    },
    parkingId: {
      get() {
        return this.searchParams.parkingId
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          parkingId: value
        })
      }
    },
    userTypes: {
      get() {
        return this.searchParams.userTypes
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          userTypes: value
        })
      }
    },
    sansanStatuses: {
      get() {
        return this.searchParams.sansanStatuses
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          sansanStatuses: value
        })
      }
    },
    statuses: {
      get() {
        return this.searchParams.statuses
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          statuses: value
        })
      }
    },
    hasAntiSocialForcesChecked: {
      get() {
        return this.searchParams.hasAntiSocialForcesChecked
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          hasAntiSocialForcesChecked: value
        })
      }
    },
    userParkingSourceTypes: {
      get() {
        return this.searchParams.userParkingSourceTypes
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          userParkingSourceTypes: value
        })
      }
    },
    userParkingSourceCompanyNames: {
      get() {
        return this.searchParams.userParkingSourceCompanyNames
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          userParkingSourceCompanyNames: value
        })
      }
    },
    userParkingSourceCompanySource: {
      get() {
        return this.searchParams.userParkingSourceCompanySource
      },
      set(value) {
        dispatch('search/parkingProvidingUsers/setSearchParams', {
          ...this.searchParams,
          userParkingSourceCompanySource: value
        })
      }
    }
  },
  watch: {
    userTypes(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.searchUsers()
      }
    },
    sansanStatuses(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.searchUsers()
      }
    },
    statuses(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.searchUsers()
      }
    },
    hasAntiSocialForcesChecked(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.searchUsers()
      }
    },
    userParkingSourceTypes(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return
      }

      if (
        !val.includes(SourceType.WEB) &&
        this.userParkingSourceCompanyNames.length
      ) {
        this.userParkingSourceCompanyNames = []

        return
      }
      this.searchUsers()
    },
    userParkingSourceCompanyNames(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return
      }

      const isCleanUserParkingSourceCompanySource =
        !this.isShowUserParkingSourceCompanySource &&
        this.userParkingSourceCompanySource
      if (isCleanUserParkingSourceCompanySource) {
        this.userParkingSourceCompanySource = null

        return
      }
      this.searchUsers()
    }
  },
  mounted() {
    this.searchUsers()
  },
  methods: {
    getUsersResult: call('search/parkingProvidingUsers/getUsers'),
    async searchUsers() {
      this.isSearching = true
      // ユーザー検索属性を設定する
      dispatch('search/parkingProvidingUsers/setSearchParams', {
        ...this.searchParams,
        searchHintUser: false,
        page: paginationConstants.userSearchDrawer.DEFAULT_PAGE,
        pageSize: paginationConstants.userSearchDrawer.DEFAULT_PAGE_SIZE
      })
      // Storeのユーザー検索メソッドを呼び出す
      //Cannot await dispatch, so use call instead
      await this.getUsersResult()
      this.isSearching = false
    }
  }
}
</script>
