<template>
  <div
    :class="{active: isActive}"
    class="search-input -elevation1">
    <div class="titlearea">
      <img
        class="icon"
        src="@/assets/svg/directions_transit-24px.svg">
      <p>駅名で確認</p>
    </div>
    <div
      :class="{'-showoverlay': isOverlayShow}"
      class="mdc-text-field -fullwidth -ripple">
      <input
        id="parking_tab"
        v-model="keyword"
        class="input"
        type="text"
        placeholder="駅名を入力"
        aria-label="駅名を入力"
        autocomplete="off"
        @click="stationOnClick"
        @keyup="inputKeyup"
        @keydown="inputKeydown"
        @keydown.enter="stationEnter"
        @keyup.tab="stationOnClick">
    </div>
    <span class="description">※ 漢字・ひらがなの入力のみを許可します</span>

    <ul
      v-show="isShowStaionsList"
      id="station-autocomplete"
      ref="stationAutocomplete"
      :class="{'-showoverlay': isOverlayShow}"
      class="mdc-list station-autocomplete"
      aria-orientation="vertical">
      <li
        v-for="(station, key) in stationsList"
        :key="key"
        :class="{'-current': stationIndex === key}"
        class="item result"
        @click="getStaffsByStation(key)">
        <span class="station-icon"></span>
        <span class="station">{{ station.name }}駅 ( {{ getAddress(station.prefectureName,station.cityName,station.regionName) }} )</span>
      </li>
    </ul>
    <div
      v-show="isOverlayShow"
      id="overlay"
      class="component-overlay -transparent"
      @click="overlayClick"></div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { get, sync, call } from 'vuex-pathify'
import { STATION_SEARCH_STAFFS } from '@/store/search'
import { API_STATION_URL } from '@/config/api-url'

export default {
  data() {
    return {
      id: STATION_SEARCH_STAFFS,
      isOverlayShow: false,
      isShowStaionsList: false,
      inputKeyword: '',
      stationsList: [],
      stationIndex: 0
    }
  },
  computed: {
    isShowListAreas: sync('ui/search@isShowListAreas'),
    searchType: sync('search/staffs@searchType'),
    searchKeyword: sync('search/staffs@keyword'),
    searchTitle: sync('search/staffs@searchTitle'),
    isFirstAccess: get('search/staffs@isFirstAccess'),
    errorMessage: sync('search/staffs@errorMessage'),
    isActive() {
      return (
        (!this.isFirstAccess || this.errorMessage) &&
        (this.searchType.localeCompare(this.id) === 0 && !this.isShowListAreas)
      )
    },
    keyword: {
      get() {
        if (this.searchType.localeCompare(this.id) === 0) {
          return this.searchKeyword
        }
        return ''
      },
      set(event) {
        this.searchKeyword = event
      }
    }
  },
  watch: {
    stationsList(value) {
      if (this.stationsList.length > 0) {
        this.isOverlayShow = true
        this.isShowStaionsList = true
      }
    }
  },
  methods: {
    getStaffs: call('staffs/getStaffsByStation'),
    getStaffsByAreas: call('staffs/getStaffsByAreas'),
    stationOnClick() {
      if (document.getElementById('search-staffs-content')) {
        document.getElementById('search-staffs-content').scroll({
          top: 0,
          behavior: 'smooth'
        })
      }
      if (this.searchType.localeCompare(this.id) !== 0) {
        this.searchKeyword = ''
        this.searchType = this.id
      }
    },
    async stationEnter(event) {
      if (!event.target.value) {
        this.stationsList = []
        this.errorMessage = 'ご指定された駅は見つかりませんでした'
        return
      }
      if (
        this.stationsList.length > 0 &&
        event.target.value === this.searchKeyword
      ) {
        this.getStaffsByStation(this.stationIndex >= 0 ? this.stationIndex : 0)
        this.stationsList = []
        return
      }

      this.inputKeyword = event.target.value.includes(' (')
        ? event.target.value.split(' (')[0]
        : ''
      await this.getStationsData(event.target.value.split(' (')[0])

      if (
        this.stationsList.length === 0 &&
        this.searchKeyword === event.target.value &&
        this.inputKeyword !== event.target.value.split(' (')[0]
      ) {
        this.stationsList = []
        this.errorMessage = 'ご指定された駅は見つかりませんでした'
      }
    },
    async inputKeydown(event) {
      if (event.key === 'ArrowDown' && this.stationsList.length > 0) {
        this.stationIndex =
          this.stationIndex + 1 === this.stationsList.length
            ? this.stationIndex
            : this.stationIndex + 1

        if (this.stationIndex > 3) {
          if (
            this.stationIndex * 41 <
            this.$refs.stationAutocomplete.scrollTop + 3 * 41
          ) {
            return
          }
          this.$refs.stationAutocomplete.scroll({
            top: (this.stationIndex - 3) * 41,
            behavior: 'smooth'
          })
        }
        return
      }

      if (event.key === 'ArrowUp' && this.stationsList.length > 0) {
        this.stationIndex =
          this.stationIndex - 1 >= 0 ? this.stationIndex - 1 : this.stationIndex
        if (
          this.stationIndex * 41 >=
          this.$refs.stationAutocomplete.scrollTop
        ) {
          return
        }
        if (this.stationIndex < this.stationsList.length - 3) {
          this.$refs.stationAutocomplete.scroll({
            top: this.stationIndex * 41,
            behavior: 'smooth'
          })
        }
        return
      }
    },
    async inputKeyup(event) {
      if (event.key === 'ArrowDown' && this.stationsList.length > 0) {
        return
      }

      if (event.key === 'ArrowUp' && this.stationsList.length > 0) {
        return
      }

      if (event.key === 'Enter') {
        return
      }

      this.stationsList = []
      this.stationIndex = 0
      await this.getStationsData(event.target.value.split(' (')[0])
    },
    getStationsData: debounce(async function(stationName) {
      //駅名で駅リストを取得する
      this.inputKeyword = stationName

      if (this.inputKeyword === '') {
        this.stationsList = {}
        this.isShowStaionsList = false
        this.isOverlayShow = false
        return
      }

      if (stationName) {
        await this.$axios
          .get(`${API_STATION_URL}`, {
            params: {
              name: stationName,
              limit: 50
            }
          })
          .then(result => {
            if (result.data || result.data.length) {
              this.stationsList = result.data
            }
          })
      }
    }, 250),
    overlayClick(event) {
      this.isOverlayShow = false
      this.isShowStaionsList = false
    },
    async getStaffsByStation(index) {
      this.isOverlayShow = false
      this.isShowStaionsList = false

      const station = this.stationsList[index]

      this.searchKeyword = `${station.name} (${this.getAddress(
        station.prefectureName,
        station.cityName,
        station.regionName
      )})`
      //担当者を取得する
      this.getStaffs({
        stationName: station.name,
        prefectureCode: station.prefectureId,
        cityCode: station.cityId,
        regionCode: station.regionId || 0
      })
      this.searchTitle = `${station.name}駅`
    },
    getAddress(prefectureName, cityName, regionName) {
      let address =
        (prefectureName || '') + (cityName || '') + (regionName || '')

      return address
    }
  }
}
</script>
