<template>
  <div>
    <aside
      id="company-phones"
      class="mdc-drawer -modal -right company-phone-drawer drawer"
      @keyup.esc="closeDrawer">
      <div class="mdc-top-app-bar mdc-top-app-bar-fixed -x-section">
        <div class="row">
          <section
            class="section -alignstart">
            <button
              class="navicon"
              @click="closeDrawer"></button>
            <h1 class="title">内線・外線番号</h1>
          </section>
        </div>
      </div>

      <company-phones />
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="onClickOverlay"></div>
  </div>
</template>


<script>
import companyPhones from '@/components/company-phones'
import { sync } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'

export default {
  components: {
    companyPhones
  },
  computed: {
    isShowCompanyPhones: sync('ui/companyPhones@isShow')
  },
  watch: {
    isShowCompanyPhones(value) {
      this.companyPhonesDrawer.open = value
      value
        ? document.body.classList.add('-notscroll')
        : document.body.classList.remove('-notscroll')
    }
  },
  mounted() {
    this.companyPhonesDrawer = new MDCDrawer(
      document.getElementById('company-phones')
    )
    this.companyPhonesDrawer.open = this.isShowCompanyPhones
  },
  methods: {
    closeDrawer() {
      this.isShowCompanyPhones = false
    },
    onClickOverlay() {
      this.isShowCompanyPhones = false
    }
  }
}
</script>
