import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
import { userConstants, UserType } from '@/config/user'
import { hasAntiSocialForcesOptions } from '@/config/anti-social-forces-checked'
import { paginationConstants } from '@/config/pagination'
import { SourceType } from '@/store/user/userParkingSourceCheckeds'

const INITIAL_SEARCH_RESULT = {
  list: [],
  totalCount: 0,
  nextPage: '',
  errorMessage: ''
}

// 必須なパラメタをチェックする
function validateParams(params) {
  return params.name || params.tel || params.email || params.parkingId
}

// エラーメッセージを設定する
function convertErrorMessageByUserTypes(userTypes) {
  const initMessage =
    '「TEL」、「MAIL」、「駐車場ID」のいずれかを必ず入力してください。'
  if (userTypes.length === 1 && userTypes[0] === UserType.PROJECT_MANAGEMENT) {
    return `'「管理会社名」'、${initMessage}`
  }
  if (userTypes.length === 1 && userTypes[0] === UserType.OWNER) {
    return `'「オーナー名」'、${initMessage}`
  }
  return `'「管理会社名/オーナー名」'、${initMessage}`
}

// 検索パラメーターをフォマットする
function formatSearchParams(context, params = {}) {
  return Object.keys(params).reduce((previous, current) => {
    return params[current]
      ? {
          ...previous,
          [current]:
            current === 'tel'
              ? context.$helpers.zen2Han(params[current]).replace(/-/g, '')
              : params[current]
        }
      : previous
  }, {})
}

export const state = () => ({
  searchParams: {
    name: '',
    tel: '',
    email: '',
    userTypes: [
      UserType.PROJECT_MANAGEMENT,
      UserType.OWNER,
      UserType.ASSISTANT_MANAGEMENT,
      UserType.OTHER_COMPANY_SUBLEASE,
      UserType.OTHER_COMPANY_SASS,
      UserType.MAINTENANCE
    ],
    sansanStatuses: [
      userConstants.sansanStatuses.ACTIVE,
      userConstants.sansanStatuses.INACTIVE
    ],
    statuses: [userConstants.userStatuses.ACTIVE],
    hasAntiSocialForcesChecked: [
      hasAntiSocialForcesOptions.YES,
      hasAntiSocialForcesOptions.NO
    ],
    userParkingSourceTypes: [],
    userParkingSourceCompanyNames: [],
    userParkingSourceCompanySource: '',
    searchType: userConstants.userSearchTypes.PROVIDER,
    parkingId: '',
    sort: '',
    searchHintUser: false,
    page: paginationConstants.userSearchDrawer.DEFAULT_PAGE,
    pageSize: paginationConstants.userSearchDrawer.DEFAULT_PAGE_SIZE
  },
  searchResult: INITIAL_SEARCH_RESULT,
  searchHintResult: INITIAL_SEARCH_RESULT
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),

  async getUsers(context) {
    // ヒントユーザーを非表示にする
    this.app.store.dispatch('ui/showParkingProvidingHintUsers', {
      isShow: false
    })

    // Null、ブランク、False以外の値がある検索パラメータをフィルタする
    const searchParams = formatSearchParams(this, context.state.searchParams)

    // 必須な項目をチェックする
    if (!validateParams(searchParams)) {
      context.commit('SET_SEARCH_RESULT', {
        ...INITIAL_SEARCH_RESULT,
        errorMessage: convertErrorMessageByUserTypes(searchParams.userTypes)
      })
      return
    }

    try {
      // ユーザー検索実施
      const res = await this.$axios.get(`${API_USERS_URL}`, {
        params: searchParams
      })
      // ユーザーデータがない場合
      if (!res || !res.data || res.data.length === 0) {
        context.commit('SET_SEARCH_RESULT', INITIAL_SEARCH_RESULT)

        // ヒントユーザーを表示する
        this.app.store.dispatch('ui/showParkingProvidingHintUsers', {
          isShow: true
        })
      } else {
        // ユーザーデータがある場合
        context.commit('SET_SEARCH_RESULT', {
          list: res.data,
          totalCount: res.headers['x-total-count'],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          errorMessage: ''
        })
      }
    } catch (e) {
      // エラー発生の場合
      context.commit('SET_SEARCH_RESULT', {
        ...INITIAL_SEARCH_RESULT,
        errorMessage:
          e.response && e.response.status === 400
            ? '入力内容に誤りがございます。ご確認ください。'
            : 'エラーが発生しました。しばらくしてからもう一度お試しください。'
      })
    }
  },

  async getHintUsers(context) {
    // Null、ブランク、False以外の値がある検索パラメータをフィルタする
    const searchParams = formatSearchParams(this, context.state.searchParams)

    try {
      // ユーザー検索実施
      const res = await this.$axios.get(`${API_USERS_URL}`, {
        params: searchParams
      })
      // ユーザーデータがない場合
      if (!res || !res.data || res.data.length === 0) {
        context.commit('SET_SEARCH_HINT_RESULT', INITIAL_SEARCH_RESULT)
      } else {
        // ユーザーデータがある場合
        context.commit('SET_SEARCH_HINT_RESULT', {
          list: res.data,
          totalCount: res.headers['x-total-count'],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          errorMessage: ''
        })
      }
    } catch (e) {
      // エラー発生の場合
      context.commit('SET_SEARCH_HINT_RESULT', {
        list: [],
        totalCount: 0,
        nextPage: '',
        errorMessage: 'エラーが発生しました。'
      })
    }
  }
}
