import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
import { generateConst } from '@/utils/constants'

const sourceTypeRegistry = {
  NONE: { value: '', label: '不明' },
  TEL: { value: 'tel', label: '電話/ Tel' },
  MAIL: { value: 'mail', label: 'メール' },
  WEB: { value: 'web', label: 'Web' },
  MAISOKO_COMPANY: { value: 'Maisoko company', label: 'マイソク' }
}

const companyNameRegistry = {
  NONE: { value: '', label: '不明' },
  REINS: { value: 'Reins', label: 'レインズ' },
  ATBB: { value: 'ATBB', label: 'ATBB' },
  ITANDI_BB: { value: 'ITANDI BB', label: 'ITANDI BB' },
  OTHER: { value: 'other', label: 'その他不動産情報サイト' },
  PROVIDER_COMPANY: { value: 'provider company', label: '管理会社サイト' }
}

export const SourceType = generateConst(sourceTypeRegistry)
export const CompanyName = generateConst(companyNameRegistry)

export const state = () => ({
  list: []
})

export const getters = {
  ...make.getters(state),
  list: state => {
    return (
      state.list.map(userParkingSourceChecked => {
        return {
          ...userParkingSourceChecked,
          sourceType: userParkingSourceChecked.sourceType || '',
          companyName: userParkingSourceChecked.companyName || ''
        }
      }) || []
    )
  }
}

export const mutations = { ...make.mutations(state) }

export const actions = {
  ...make.actions(state),
  async createUserParkingSourceChecked(context, params = {}) {
    try {
      const createdUserParkingSourceChecked = await this.$axios.post(
        `${API_USERS_URL}/${params.userId}/user-parking-source-checkeds`,
        {
          sourceType: params.sourceType || '',
          companyName: params.companyName || '',
          companySource: params.companySource || '',
          sameParkingIds: params.sameParkingIds || '',
          memo: params.memo || ''
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: '物件確認先情報を保存しました'
      })

      return createdUserParkingSourceChecked
    } catch (e) {
      this.$sentry.captureMessage(e)
    }
  },

  async updateUserParkingSourceChecked(context, params = {}) {
    try {
      const updatedUserParkingSourceChecked = await this.$axios.patch(
        `${API_USERS_URL}/${params.userId}/user-parking-source-checkeds/${
          params.userParkingSourceCheckedId
        }`,
        {
          sourceType: params.sourceType || '',
          companyName: params.companyName || '',
          companySource: params.companySource || '',
          sameParkingIds: params.sameParkingIds || '',
          memo: params.memo || ''
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: '物件確認先情報を保存しました'
      })

      return updatedUserParkingSourceChecked
    } catch (e) {
      this.$sentry.captureMessage(e)
    }
  },

  async deleteUserParkingSourceChecked(context, params = {}) {
    try {
      await this.$axios.delete(
        `${API_USERS_URL}/${params.userId}/user-parking-source-checkeds/${
          params.userParkingSourceCheckedId
        }`
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: '物件確認先情報を削除しました'
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
