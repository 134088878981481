import { make } from 'vuex-pathify'
import { API_AREAS_URL, API_USERS_URL } from '@/config/api-url'
import { DRAWER_TYPE_CONTACT_USER_SEARCH } from '@/config/search'

export const PARKING_SEARCH_STAFFS = 'parkingIdSearchStaffs'
export const AREAS_SEARCH_STAFFS = 'areasSearchStaffs'
export const STATION_SEARCH_STAFFS = 'stationSearchStaffs'

export const state = () => ({
  staffs: {
    searchType: '',
    keyword: '',
    listAreas: [],
    areasTitle: '',
    searchTitle: '',
    isFirstAccess: true,
    message: '',
    errorMessage: '',
    stationArea: ''
  },
  // user - 問い合わせユーザー検索
  // provider - 管理会社・オーナー検索
  currentDrawerType: DRAWER_TYPE_CONTACT_USER_SEARCH
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async setStaffsMessage(context) {
    let html = ''
    switch (context.state.staffs.searchType) {
      case PARKING_SEARCH_STAFFS:
        html += `駐車場ID　：${context.state.staffs.keyword || 'なし'} \n`
        break
      case AREAS_SEARCH_STAFFS:
        html += `住所　　　：${context.state.staffs.keyword || 'なし'} \n`
        break
      case STATION_SEARCH_STAFFS:
        html += `駅名　　　：${
          context.state.staffs.keyword
            ? context.state.staffs.keyword.replace(' (', '駅 (')
            : 'なし'
        } \n`
        break
    }

    const area = this.app.store.get('staffs/area@name')
    const inProgressCallHistory = this.app.store.get(
      'biztel/inProgressCallHistory@info'
    )

    let res = { data: [] }
    if (inProgressCallHistory.userId && inProgressCallHistory.userId > 0) {
      res = await this.$axios.get(
        `${API_USERS_URL}/${inProgressCallHistory.userId}`
      )
    }
    const phoneNumber = this._vm.$options.filters.formatPhoneNumber(
      inProgressCallHistory.inProgressTel
    )
    const userInfoMessage =
      res.data.name || inProgressCallHistory.inProgressTel
        ? `ユーザー名：${res.data.name ||
            'なし'}　\n電話番号　：${phoneNumber || 'なし'}`
        : `ユーザー　：未登録`
    html += `エリア　　：${area} \n${userInfoMessage}`
    context.commit('SET_STAFFS', { ...context.state.staffs, message: html })
  },
  async getListAreaSearchStaffs(context, params = {}) {
    const res = await this.$axios.get(`${API_AREAS_URL}`, {
      params: { ...params }
    })
    context.commit('SET_STAFFS', {
      ...context.state.staffs,
      ...{ listAreas: res.data, errorMessage: '' }
    })
  }
}
