<template>
  <div
    class="user-search-info-card -elevation1"
    @click="redirectToUserPage">
    <div class="title">
      <div
        :class="{ '-disabled': isOpacityUser }"
        class="userclass">
        <span
          :class="['type', userClass]">
          {{ user.userClass | userClassToString }}</span>
        <span class="name">{{ user.name }}</span>
      </div>
      <div class="userinfo">
        <span
          v-if="isSansanUser"
          class="sansan">
          <span class="icon"></span>
          <span class="text">Sansan有</span>
        </span>
        <span
          v-if="isDisabledUser"
          class="disabled"> {{ user.status | userStatusStr }}</span>
        <span
          v-if="isDuplicatedUser"
          class="duplicated">
          <span class="text"> {{ user.status | userStatusStr }}</span>
          <span class="id">（ID：{{ user.duplicatedUser || '未登録' }}）</span>
        </span>
      </div>
    </div>
    <div class="content">
      <user-info-status :user="user"/>
      <div class="user-info-content">
        <div class="info -address">{{ user.address || '住所なし' }}</div>
        <div class="info -contact">
          <div class="tel">
            <div
              v-for="telObj in tels"
              :key="telObj.tel"
              class="tel-item">
              <p class="label">{{ telObj.name }}</p>
              <p class="text">{{ telObj.tel | formatPhoneNumber }}</p>
            </div>
            <div v-if="user.tels.length > 5">...</div>
            <div
              v-if="user.tels.length === 0"
              class="tel-item">
              <p class="text">電話番号なし</p>
            </div>
          </div>
          <div class="mail">
            <div
              v-for="emailObj in emails"
              :key="emailObj.email"
              class="mail-item">
              <p class="label">{{ emailObj.name }}</p>
              <p class="text">{{ emailObj.email }}</p>
            </div>
            <div v-if="user.emails.length > 5">...</div>
            <div
              v-if="user.emails.length === 0"
              class="mail-item">
              <p class="text">メールなし</p>
            </div>
          </div>
        </div>
        <div class="info -memo">
          <p class="text">{{ user.memo || 'メモなし' }}</p>
        </div>
        <div class="info -parking">
          <div
            :class="{'-inactive': isInactiveManagingParkingCount, '-underlined': isUnderlinedManagingParkingCount}"
            class="item">
            <span class="text">{{ `管理物件${user.managingParkingCount}件` }}</span>
          </div>
          <div
            :class="{'-inactive': isInactiveOwningParkingCount, '-underlined': isUnderlinedOwningParkingCount}"
            class="item">
            <span class="text">{{ `オーナー物件${user.owningParkingCount}件` }}</span>
          </div>
          <div
            :class="{'-inactive': isInactiveServingParkingCount, '-underlined': isUnderlinedServingContactsCount}"
            class="item">
            <span class="text">{{ `進行中案件${user.servingContactsCount}件` }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sync, get, dispatch } from 'vuex-pathify'
import { MDCRipple } from '@rsmdc/ripple'
import { SWITCHER_TYPE_CUSTOMER } from '@/config/switcher-user-type'
import userInfoStatus from '@/components/user-info-status'
import { userConstants, UserType } from '@/config/user'
import { DRAWER_TYPE_USER_PROVIDER_SEARCH } from '@/config/search'

export default {
  components: {
    userInfoStatus
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    searchType: {
      type: String,
      default: ''
    }
  },
  computed: {
    userPageSwitcher: sync('ui/userPage@switcher'),
    isShowParkingProvidingUserSearch: sync(
      'ui/searchParkingProvidingUsers@isShow'
    ),
    isShowServingContactUserSearch: sync('ui/searchServingContactUsers@isShow'),
    parkingProvidingUsersSearchParams: get(
      'search/parkingProvidingUsers@searchParams'
    ),
    staffId: get('staffId'),
    currentDrawerType: get('search/currentDrawerType'),
    inProgressCallHistory: get('biztel/inProgressCallHistory@info'),
    isSansanUser() {
      return this.user.hasSansan === userConstants.sansanStatuses.ACTIVE
    },
    isDisabledUser() {
      return this.user.status === userConstants.userStatuses.INACTIVE
    },
    isDuplicatedUser() {
      return this.user.status === userConstants.userStatuses.DUPLICATED
    },
    userClass() {
      return this.$options.filters.convertUserClass(this.user.userClass)
    },
    isOpacityUser() {
      return this.user.status !== userConstants.userStatuses.ACTIVE
    },
    isInactiveManagingParkingCount() {
      return this.user.managingParkingCount < 1
    },
    isInactiveOwningParkingCount() {
      return this.user.owningParkingCount < 1
    },
    isInactiveServingParkingCount() {
      return this.user.servingContactsCount < 1
    },
    isUnderlinedManagingParkingCount() {
      return (
        this.searchType === userConstants.userSearchTypes.PROVIDER &&
        (this.parkingProvidingUsersSearchParams.userTypes.length >= 2 ||
          this.parkingProvidingUsersSearchParams.userTypes.length === 0 ||
          (this.parkingProvidingUsersSearchParams.userTypes.length === 1 &&
            this.parkingProvidingUsersSearchParams.userTypes[0] ===
              UserType.PROJECT_MANAGEMENT))
      )
    },
    isUnderlinedOwningParkingCount() {
      return (
        this.searchType === userConstants.userSearchTypes.PROVIDER &&
        (this.parkingProvidingUsersSearchParams.userTypes.length >= 2 ||
          this.parkingProvidingUsersSearchParams.userTypes.length === 0 ||
          (this.parkingProvidingUsersSearchParams.userTypes.length === 1 &&
            this.parkingProvidingUsersSearchParams.userTypes[0] ===
              UserType.OWNER))
      )
    },
    isUnderlinedServingContactsCount() {
      return this.searchType === userConstants.userSearchTypes.CUSTOMER
    },
    tels() {
      // 最初の五つの電話番号バリューを取得する
      if (Array.isArray(this.user.tels) && this.user.tels.length > 5) {
        return this.user.tels.slice(0, 5)
      }
      return this.user.tels
    },
    emails() {
      // 最初の五つのメールバリューを取得する
      if (Array.isArray(this.user.emails) && this.user.emails.length > 5) {
        return this.user.emails.slice(0, 5)
      }
      return this.user.emails
    }
  },
  mounted() {
    this.applyRippleToInfoBox()
  },
  updated() {
    this.applyRippleToInfoBox()
  },
  methods: {
    redirectToUserPage() {
      const isSearchingForCustomer =
        this.searchType === userConstants.userSearchTypes.CUSTOMER
      if (isSearchingForCustomer) {
        this.userPageSwitcher = {
          ...this.userPageSwitcher,
          forceUserType: SWITCHER_TYPE_CUSTOMER
        }
      }
      if (this.isShowParkingProvidingUserSearch) {
        this.isShowParkingProvidingUserSearch = false
      }
      if (this.isShowServingContactUserSearch) {
        this.isShowServingContactUserSearch = false
      }
      if (
        this.$route.name ===
          'biztel-callId-callId-extensionNumber-extensionNumber' &&
        this.inProgressCallHistory.managerId === this.staffId &&
        !this.inProgressCallHistory.endedAt
      ) {
        dispatch('biztel/inProgressCallHistory/setInfo', {
          ...this.inProgressCallHistory,
          userId: this.user.id,
          userType: this.setUserType(),
          userGroupId: this.user.userGroupId
        })
      } else {
        this.$router.push({ path: `/users/${this.user.id}` })
      }
    },
    setUserType() {
      if (this.currentDrawerType === DRAWER_TYPE_USER_PROVIDER_SEARCH) {
        const userType = this.$options.filters.getUserTypeInProgressCallHistory(
          this.user
        )
        return userType === 'customer' ? 'management' : userType
      } else {
        return 'customer'
      }
    },
    applyRippleToInfoBox() {
      const rippleElements = document.querySelectorAll('.sidebar-user-info-box')
      if (rippleElements.length > 0) {
        rippleElements.forEach(element => {
          new MDCRipple(element)
        })
      }
    }
  }
}
</script>
