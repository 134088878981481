<template>
  <div>
    <aside
      id="parking-providing-user-search-drawer"
      class="mdc-drawer -modal -right drawer parking-providing-user-search-drawer"
      @keyup.esc="closeDrawer">
      <!-- ヘッダ -->
      <div class="header">
        <button
          class="navicon mdc-button mdc-ripple-upgraded"
          @click="closeDrawer"></button>
        <h1 class="title">管理会社・オーナー検索</h1>
      </div>
      <!-- 検索フォームと検索結果 -->
      <div 
        id="parking-providing-user-search-content"
        class="content">
        <parking-providing-user-search-form />
        <parking-providing-user-search-result 
          v-show="!isSearching"
          :users="searchUsersResult"
        />
        <circular-indeterminate-progress v-show="isSearching" />
        <parking-providing-user-search-hint />
      </div>
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="closeDrawer"></div>
  </div>
</template>

<script>
import circularIndeterminateProgress from '@/components/circular-indeterminate-progress'
import parkingProvidingUserSearchForm from '@/components/drawer/parking-providing-user-search-form'
import parkingProvidingUserSearchResult from '@/components/drawer/parking-providing-user-search-result'
import parkingProvidingUserSearchHint from '@/components/drawer/parking-providing-user-search-hint'
import { sync, get, dispatch } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'
import { DRAWER_TYPE_USER_PROVIDER_SEARCH } from '@/config/search'

export default {
  components: {
    parkingProvidingUserSearchForm,
    parkingProvidingUserSearchResult,
    parkingProvidingUserSearchHint,
    circularIndeterminateProgress
  },
  data() {
    return {
      parkingProvidingUserSearchDrawer: {}
    }
  },
  computed: {
    isShowParkingProvidingUserSearch: sync(
      'ui/searchParkingProvidingUsers@isShow'
    ),
    searchParams: get('search/parkingProvidingUsers@searchParams'),
    isSearching: sync('ui/searchParkingProvidingUsers@isSearching'),
    searchUsersResult: get('search/parkingProvidingUsers@searchResult')
  },
  watch: {
    async isShowParkingProvidingUserSearch(value) {
      this.parkingProvidingUserSearchDrawer.open = value
      if (value) {
        dispatch(
          'search/setCurrentDrawerType',
          DRAWER_TYPE_USER_PROVIDER_SEARCH
        )
        document.body.classList.add('-notscroll')
      } else {
        document.body.classList.remove('-notscroll')
      }
    }
  },
  mounted() {
    this.parkingProvidingUserSearchDrawer = new MDCDrawer(
      document.getElementById('parking-providing-user-search-drawer')
    )
    this.parkingProvidingUserSearchDrawer.open = this.isShowParkingProvidingUserSearch
  },
  methods: {
    closeDrawer() {
      this.isShowParkingProvidingUserSearch = false
    }
  }
}
</script>
