import { make } from 'vuex-pathify'
import { API_PARKS_URL } from '@/config/api-url'

export const state = () => ({
  searchResult: {
    parkingAreas: [],
    totalCount: 0,
    nextPage: '',
    errorMessage: ''
  }
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),

  // 最初ページの駐車場エリアリストを取得する
  async getParkingAreas(context, params = {}) {
    try {
      const result = await this.$axios.get(`${API_PARKS_URL}/areas`, {
        params
      })
      if (result.data.length === 0) {
        context.commit('SET_SEARCH_RESULT', {
          parkingAreas: [],
          totalCount: 0,
          nextPage: '',
          errorMessage: ''
        })
      } else {
        context.commit('SET_SEARCH_RESULT', {
          parkingAreas: result.data,
          totalCount: result.headers['x-total-count'],
          nextPage: this.app.$helpers.parseLinkHeader(result.headers['link'])
            .next,
          errorMessage: ''
        })
      }
    } catch (e) {
      context.commit('SET_SEARCH_RESULT', {
        parkingAreas: [],
        totalCount: 0,
        nextPage: '',
        errorMessage: 'エラーが発生しました。'
      })
    }
  },

  // 次のページの駐車場エリアリストを取得する
  async getMoreParkingAreas(context) {
    try {
      if (!context.state.searchResult.nextPage) {
        return
      }
      const result = await this.$axios.get(context.state.searchResult.nextPage)
      context.commit('SET_SEARCH_RESULT', {
        parkingAreas: [
          ...context.state.searchResult.parkingAreas,
          ...result.data
        ],
        totalCount: result.headers['x-total-count'],
        nextPage: this.app.$helpers.parseLinkHeader(result.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
