<template>
  <div class="name-search-input">
    <div class="mdc-text-field -ripple">
      <input
        v-model="staffName"
        :placeholder="placeholderDisabled ? '': `通知先を検索`"
        :aria-label="placeholderDisabled ? '': `通知先を検索`"
        class="input"
        type="text"
        @keyup="searchTransferStaffs"
        @keyup.enter="emitParent">
    </div>
    <span
      v-show="isShowError"
      class="errormessage">{{ errorMessage }}</span>
    <ul
      v-show="staffs && staffs.length > 0"
      class="mdc-list search-input-autocomplete -name">
      <li
        v-for="(staff, index) in staffs"
        :key="staff.id"
        :id="staff.id"
        :value="staff.name"
        class="item result"
        @click="setTransferStaff(index)">
        <p
          :id="staff.id"
          class="text">{{ staff.username }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { API_STAFFS_URL } from '@/config/api-url'
import debounce from 'lodash/debounce'

export default {
  props: {
    placeholderDisabled: {
      type: Boolean,
      default: () => false
    },
    showError: {
      type: Boolean,
      default: () => true
    },
    initStaffName: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      staffName: '',
      staff: {},
      staffs: [],
      errorMessage: ''
    }
  },
  computed: {
    isShowError() {
      if (!this.showError) {
        return this.staffName && this.errorMessage
      }

      return this.errorMessage
    }
  },
  watch: {
    initStaffName() {
      this.staffName = this.initStaffName
    }
  },
  mounted() {
    this.staffName = this.initStaffName
  },
  methods: {
    searchTransferStaffs: debounce(async function(event) {
      if (event.key === 'Enter') {
        return
      }
      await this.getListStaffs(event.target.value)
    }, 250),
    setTransferStaff(index) {
      this.staffName = this.staffs[index].username
      this.staff = this.staffs[index]
      this.staffs = []
      this.emitSelectedStaff()
    },
    async getListStaffs(staffName) {
      this.staffs = []
      this.staff = {}
      this.emitSelectedStaff()
      if (!staffName) {
        this.errorMessage = ''
        return
      }

      const params = { name: staffName }
      const staffs = await this.$axios.get(`${API_STAFFS_URL}`, { params })
      if (!staffs.data || staffs.data.length === 0) {
        this.errorMessage = '検索した担当者は見つかりませんでした。'
        return
      }
      this.staffs = staffs.data
      this.errorMessage = ''
    },
    emitParent() {
      if (this.$el.id === 'autocomplete-staff-for-search-users') {
        this.$emit('keyupEnter')
      }
    },
    emitSelectedStaff() {
      this.$emit('set-selected-staff', {
        staffName: this.staffName,
        staff: this.staff
      })
    }
  }
}
</script>
