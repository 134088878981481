<template>
  <div class="drawer-user-group-search-result">
    <template v-if="userGroups.length > 0">
      <h2 class="resulttitle">{{ totalCount }}件のユーザーグループがヒットしました。</h2>
      <div class="resultcontainer">
        <user-group-info-box
          v-for="(group, index) in userGroups"
          :group="group"
          :key="index"
          @click-user-group-info-box="onClickUserGroupInfoBox"/>
        <button
          v-if="nextPage"
          class="mdc-button seemorebtn"
          @click="loadMoreUsers">
          <span class="text">もっと見る</span>
        </button>
      </div>
    </template>
    <template v-else>
      <div class="error-message">
        <p class="content">ユーザーグループ情報がありません。</p>
      </div>
    </template>
  </div>
</template>
<script>
import { get, sync, dispatch } from 'vuex-pathify'
import userGroupInfoBox from '@/components/user-group-page/user-group-info-box'

export default {
  components: {
    userGroupInfoBox
  },
  computed: {
    userGroups: get('search/userGroup/searchResult@list'),
    nextPage: get('search/userGroup/searchResult@nextPage'),
    totalCount: get('search/userGroup/searchResult@totalCount'),
    isDrawerOpen: sync('ui/userGroupPage@drawer.isSearchUserGroupOpened')
  },
  methods: {
    onClickUserGroupInfoBox(group) {
      if (group && group.id) {
        this.$router.push({ path: `/user-group/${group.id}` })
        this.isDrawerOpen = false
      }
    },
    loadMoreUsers() {
      dispatch('search/userGroup/getMoreUserGroup')
    }
  }
}
</script>
