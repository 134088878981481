import { make } from 'vuex-pathify'
import { API_USER_GROUP_URL } from '@/config/api-url'

const INITIAL_SEARCH_RESULT = {
  list: [],
  totalCount: 0,
  nextPage: '',
  errorMessage: ''
}

export const state = () => ({
  searchParams: {
    groupId: '',
    groupName: '',
    createdManagerId: '',
    createdAt: '',
    isEnabled: [1]
  },
  searchResult: INITIAL_SEARCH_RESULT,
  lastSearchResult: null,
  hasError: false
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async fetchUserGroup(context) {
    if (context.state.hasError) {
      context.commit('SET_SEARCH_RESULT', INITIAL_SEARCH_RESULT)
      return
    }
    if (context.state.searchParams.staffId === 0) {
      context.commit('SET_SEARCH_RESULT', INITIAL_SEARCH_RESULT)
      return
    }
    const filteredSearchParams = Object.keys(context.state.searchParams).reduce(
      (accumulate, current) => {
        if (context.state.searchParams[current]) {
          if (current === 'groupId') {
            const parsedId = parseInt(
              this.$helpers.zen2Han(context.state.searchParams[current])
            )
            return parsedId ? { ...accumulate, groupId: parsedId } : accumulate
          }
          return {
            ...accumulate,
            [current]: context.state.searchParams[current]
          }
        }
        return accumulate
      },
      {}
    )
    const resPromise = this.$axios
      .get(`${API_USER_GROUP_URL}`, {
        params: filteredSearchParams
      })
      .then(res => {
        if (resPromise !== context.state.lastSearchResult) {
          return
        }
        if (!res.data || res.data.length === 0) {
          context.commit('SET_SEARCH_RESULT', INITIAL_SEARCH_RESULT)
        } else {
          context.commit('SET_SEARCH_RESULT', {
            list: res.data,
            totalCount: res.headers['x-total-count'],
            nextPage: this.app.$helpers.parseLinkHeader(res.headers['link'])
              .next,
            errorMessage: ''
          })
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          context.commit('SET_SEARCH_RESULT', {
            ...INITIAL_SEARCH_RESULT,
            errorMessage: '入力内容に誤りがございます。ご確認ください。'
          })
          return
        }

        context.commit('SET_SEARCH_RESULT', {
          ...INITIAL_SEARCH_RESULT,
          errorMessage:
            'エラーが発生しました。しばらくしてからもう一度お試しください。'
        })
      })
    context.commit('SET_LAST_SEARCH_RESULT', resPromise)
  },
  async updateUserInSearchResult(context, { userId, userGroupId }) {
    context.commit('SET_SEARCH_RESULT', {
      ...context.state.searchResult,
      list: context.state.searchResult.list.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            userGroupId
          }
        }
        return user
      })
    })
  },
  async getMoreUserGroup(context) {
    try {
      if (!context.state.searchResult.nextPage) {
        return
      }
      const res = await this.$axios.get(context.state.searchResult.nextPage)
      context.commit('SET_SEARCH_RESULT', {
        list: [...context.state.searchResult.list, ...res.data],
        totalCount: res.headers['x-total-count'],
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
