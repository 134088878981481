<template>
  <div>
    <the-header />
    <nuxt/>
  </div>
</template>
<script>
import theHeader from '@/components/the-header'
import loginError from '@/components/login-error'

export default {
  components: {
    theHeader,
    loginError
  }
}
</script>
