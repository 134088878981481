<template>
  <div>
    <aside
      ref="userEdit"
      class="mdc-drawer -modal -right drawer"
      @keyup.esc="closeDrawer">
      <div
        class="staffs-search">
        <div class="mdc-top-app-bar mdc-top-app-bar-fixed -x-section">
          <div class="row">
            <section
              class="section -alignstart">
              <button
                class="navicon"
                @click="closeDrawer"></button>
              <h1 class="title">{{ inProgressCallHistory.userType | convertCurrentUserType }}情報編集</h1>
            </section>
          </div>
        </div>
        <div
          id="user-edit-page-content"
          class="user-edit-page-content content">
          <user-info-edit
            ref="userUpdate"
            :user-info="userInfo"
            :is-view-biztel-page="isViewBiztelPage"
            @update-user-info="updateUserInfo"/>
          <user-tel-edit
            :user-info="userInfo"
            :user-tels="userTels"
            @update-user-tels="updateUserInfo"/>
          <user-email-edit
            :user-info="userInfo"
            :user-emails="userEmails"
            @update-user-emails="updateUserInfo"/>
          <user-fax-edit
            :user-info="userInfo"
            :user-faxes="userFaxes"
            @update-user-faxes="updateUserInfo"/>
          <user-parking-source-checked-edit
            :user-info="userInfo"
            :user-parking-source-checkeds="userParkingSourceCheckeds"
            @update-user-parking-source-checkeds="updateUserInfo"/>
        </div>
      </div>
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="closeDrawer"></div>
  </div>
</template>

<script>
import userInfoEdit from '@/components/user-page/user-info-edit'
import userEmailEdit from '@/components/user-page/user-email-edit'
import userTelEdit from '@/components/user-page/user-tel-edit'
import userFaxEdit from '@/components/user-page/user-fax-edit'
import userParkingSourceCheckedEdit from '@/components/user-page/user-parking-source-checked-edit'
import { get, sync, call, dispatch } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'
import { API_USERS_URL } from '@/config/api-url'

export default {
  components: {
    userInfoEdit,
    userTelEdit,
    userEmailEdit,
    userFaxEdit,
    userParkingSourceCheckedEdit
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      userInfo: {},
      userTels: [],
      userEmails: [],
      userFaxes: [],
      userParkingSourceCheckeds: []
    }
  },
  computed: {
    curUserInfo: get('user/info@data'),
    curUserTels: get('user/tels@list'),
    curUserEmails: get('user/emails@list'),
    curUserFaxes: get('user/faxes@list'),
    curUserParkingSourceCheckeds: get('user/userParkingSourceCheckeds/list'),
    isUserEditPageOpen: sync('ui/userEditPage@open'),
    inProgressCallHistory: get('biztel/inProgressCallHistory@info'),
    isViewBiztelPage() {
      return (
        this.$route.name ===
        'biztel-callId-callId-extensionNumber-extensionNumber'
      )
    }
  },
  watch: {
    async isUserEditPageOpen(value) {
      this.userEditDrawer.open = value

      if (value) {
        if (
          this.isViewBiztelPage &&
          this.inProgressCallHistory.userId &&
          this.inProgressCallHistory.userId > 0
        ) {
          this.getBiztelUser()
        } else {
          this.userInfo = this.curUserInfo
          this.userTels = this.curUserTels
          this.userEmails = this.curUserEmails
          this.userFaxes = this.curUserFaxes
          this.userParkingSourceCheckeds = this.curUserParkingSourceCheckeds
        }
        document.getElementById('user-edit-page-content').scrollTop = 0
      }
    },
    curUserInfo() {
      if (!this.isViewBiztelPage) {
        this.userInfo = this.curUserInfo
      }
    },
    curUserTels() {
      if (!this.isViewBiztelPage) {
        this.userTels = this.curUserTels
      }
    },
    curUserEmails() {
      if (!this.isViewBiztelPage) {
        this.userEmails = this.curUserEmails
      }
    },
    curUserFaxes() {
      if (!this.isViewBiztelPage) {
        this.userFaxes = this.curUserFaxes
      }
    },
    curUserParkingSourceCheckeds() {
      if (!this.isViewBiztelPage) {
        this.userParkingSourceCheckeds = this.curUserParkingSourceCheckeds
      }
    },
    async inProgressCallHistory() {
      if (
        this.isViewBiztelPage &&
        this.inProgressCallHistory.userId &&
        this.inProgressCallHistory.userId > 0
      ) {
        await this.getBiztelUser()
      }
    }
  },
  mounted() {
    if (this.$refs.userEdit) {
      this.userEditDrawer = new MDCDrawer(this.$refs.userEdit)
    }
  },
  methods: {
    getUserInfoById: call('user/info/getUserInfoById'),
    closeDrawer() {
      this.isUserEditPageOpen = false
    },
    async updateUserInfo(userInfo) {
      if (this.isViewBiztelPage) {
        dispatch('biztel/inProgressCallHistory/setInfo', {
          ...this.inProgressCallHistory
        })
      } else {
        await this.getUserInfoById(this.userInfo.id)
      }
    },
    async getBiztelUser() {
      const userRes = await this.$axios.get(
        `${API_USERS_URL}/${this.inProgressCallHistory.userId}`
      )
      this.userInfo = userRes.data
      this.userTels = userRes.data.tels
      this.userEmails = userRes.data.emails
      this.userFaxes = userRes.data.faxes
    }
  }
}
</script>
