import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({
  inProgressContact: {
    nextPage: '',
    list: [],
    total: 0
  },
  signedContact: {
    nextPage: '',
    list: [],
    total: 0
  },
  otherContact: {
    nextPage: '',
    list: [],
    total: 0
  }
})

export const mutations = { ...make.mutations(state) }

export const actions = {
  ...make.actions(state),
  async getInProgressContact(context, { userId, getType }) {
    try {
      if (getType === 'push') {
        const res = await this.$axios.get(
          context.state.inProgressContact.nextPage
        )
        context.commit('SET_IN_PROGRESS_CONTACT', {
          list: [...context.state.inProgressContact.list, ...res.data] || [],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          total: res.headers['x-total-count'] || res.data.length || 0
        })
      } else {
        const res = await this.$axios.get(
          `${API_USERS_URL}/${userId}/contacts`,
          {
            params: { type: 'inProgress' }
          }
        )
        context.commit('SET_IN_PROGRESS_CONTACT', {
          list: res.data,
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          total: res.headers['x-total-count'] || res.data.length || 0
        })
      }
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async getSignedContact(context, { userId, getType }) {
    try {
      if (getType === 'push') {
        const res = await this.$axios.get(context.state.signedContact.nextPage)
        context.commit('SET_SIGNED_CONTACT', {
          list: [...context.state.signedContact.list, ...res.data] || [],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          total: res.headers['x-total-count'] || res.data.length || 0
        })
      } else {
        const res = await this.$axios.get(
          `${API_USERS_URL}/${userId}/contacts`,
          {
            params: { type: 'signed' }
          }
        )
        context.commit('SET_SIGNED_CONTACT', {
          list: res.data || [],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          total: res.headers['x-total-count'] || res.data.length || 0
        })
      }
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async getOtherContact(context, { userId, getType }) {
    try {
      if (getType === 'push') {
        const res = await this.$axios.get(context.state.otherContact.nextPage)
        context.commit('SET_OTHER_CONTACT', {
          list: [...context.state.otherContact.list, ...res.data],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          total: res.headers['x-total-count'] || res.data.length || 0
        })
      } else {
        const res = await this.$axios.get(
          `${API_USERS_URL}/${userId}/contacts`,
          {
            params: { type: 'other' }
          }
        )
        context.commit('SET_OTHER_CONTACT', {
          list: res.data || [],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          total: res.headers['x-total-count'] || res.data.length || 0
        })
      }
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
