<template>
  <div>
    <aside
      id="search-staffs"
      class="mdc-drawer -modal -right drawer"
      @keyup.esc="closeDrawer">
      <div
        class="staffs-search">
        <div class="mdc-top-app-bar mdc-top-app-bar-fixed -x-section">
          <div class="row">
            <section
              class="section -alignstart">
              <button
                class="navicon"
                @click="closeDrawer"></button>
              <h1 class="title">エリア担当確認</h1>
            </section>
          </div>
        </div>
        <div
          id="search-staffs-content"
          class="content">
          <search-staffs />
        </div>
      </div>
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="onClickOverlay"></div>
  </div>
</template>

<script>
import searchStaffs from '@/components/search-staffs/index'
import { sync } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'

export default {
  components: {
    'search-staffs': searchStaffs
  },
  computed: {
    isShowSearchStaffs: sync('ui/staffs@isShow')
  },
  watch: {
    isShowSearchStaffs(value) {
      this.searchStaffsDrawer.open = value

      if (value) {
        document.getElementById('search-staffs-content').scrollTop = 0
      }
    }
  },
  mounted() {
    this.searchStaffsDrawer = new MDCDrawer(
      document.getElementById('search-staffs')
    )
    this.searchStaffsDrawer.open = this.isShowSearchStaffs
  },
  methods: {
    closeDrawer() {
      this.isShowSearchStaffs = false
    },
    onClickOverlay() {
      this.isShowSearchStaffs = false
    }
  }
}
</script>
