<template>
  <div 
    class="user-group-info-box -elevation1"
    @click="onClickUserGroupInfoBox">
    <div class="title">
      <span
        v-show="group.isEnabled === 0"
        class="disabledbadge">無効</span>
      {{ group.name }} (ID: {{ group.id }})
    </div>
    <div class="content">
      <div class="item -userlist">
        <div class="title">
          支店一覧
          <div>({{ group.users.length || 0 }}件)</div>
        </div>
        <div class="content">
          <template v-if="group.users.length === 0">なし</template>
          <template v-else>
            <div class="mdc-layout-grid user-group-info-box-user-list">
              <div class="inner">
                <div
                  v-for="user in userDisplayList"
                  :key="user.id"
                  class="cell -span4desktop -span4tablet user">
                  {{ user.name }}
                </div>
              </div>
              <div 
                v-if="shouldShowUserRemainingMark" 
                class="userremain">...</div>
            </div>
          </template>
        </div>
      </div>
      <div class="item -memo -last">
        <div class="title">メモ</div>
        <div class="content">{{ group.memo | formatMemo }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { MDCRipple } from '@rsmdc/ripple'
const NUMBER_OF_DISPLAY_USER = 12

export default {
  filters: {
    formatUserList(users = []) {
      if (users.length === 0) {
        return 'なし'
      }
      return users.map(user => user.name).join(', ')
    },
    formatMemo(memo = '') {
      if (memo === '') {
        return 'なし'
      }
      return memo
    }
  },
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    userDisplayList() {
      return this.group &&
        this.group.users &&
        this.group.users.length > NUMBER_OF_DISPLAY_USER
        ? this.group.users.slice(0, 12)
        : this.group.users
    },
    shouldShowUserRemainingMark() {
      return (
        this.group &&
        this.group.users &&
        this.group.users.length > NUMBER_OF_DISPLAY_USER
      )
    }
  },
  mounted() {
    this.applyRippleToInfoBox()
  },
  updated() {
    this.applyRippleToInfoBox()
  },
  methods: {
    onClickUserGroupInfoBox() {
      this.$emit('click-user-group-info-box', this.group)
    },
    applyRippleToInfoBox() {
      const rippleElements = document.querySelectorAll('.user-group-info-box')
      if (rippleElements.length > 0) {
        rippleElements.forEach(element => {
          new MDCRipple(element)
        })
      }
    }
  }
}
</script>
