<template>
  <div>
    <div class="search-form">
      <parking-search-input />
      <area-search-input />
      <station-search-input />
    </div>

    <search-result />
  </div>
</template>

<script>
import searchResult from '@/components/search-staffs/search-result'
import areaSearchInput from '@/components/search-staffs/area-search-input'
import stationSearchInput from '@/components/search-staffs/station-search-input'
import parkingSearchInput from '@/components/search-staffs/parking-search-input'

export default {
  components: {
    'search-result': searchResult,
    'area-search-input': areaSearchInput,
    'station-search-input': stationSearchInput,
    'parking-search-input': parkingSearchInput
  }
}
</script>
