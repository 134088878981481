import { make } from 'vuex-pathify'
import { API_PARKS_URL } from '@/config/api-url'

export const state = () => ({
  list: [],
  totalCount: 0,
  errorMessage: ''
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),

  async getParkingAreas(context, params = {}) {
    try {
      const result = await this.$axios.get(`${API_PARKS_URL}/areas`, {
        params
      })

      context.commit('SET_LIST', result.data)
      context.commit('SET_TOTAL_COUNT', result.headers['x-total-count'] || 0)
      context.commit('SET_ERROR_MESSAGE', '')
    } catch (error) {
      context.commit('SET_LIST', [])
      context.commit('SET_TOTAL_COUNT', 0)
      context.commit('SET_ERROR_MESSAGE', 'エラーが発生しました')
      this.$sentry.captureException(error)
    }
  }
}
