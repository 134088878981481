<template>
  <div
    :class="{'disable-faxes': disableFlag}"
    class="user-fax-edit">
    <div class="title">
      <h1 class="text">FAX</h1>
    </div>
    <div class="content -elevation1">
      <div class="main-content">
        <div class="title">
          <div class="main">
            <p class="tel">FAX<span class="caution -necessary">(必要)</span></p>
            <p class="name">名前</p>
            <p class="namefurigana">ふりがな</p>
            <p class="supplement">補足</p>
          </div>
          <div class="addnew">
            <button
              class="mdc-button"
              @click="addNewFaxObj"><svg class="icon" />追加</button>
          </div>
        </div>
        <div class="tel-obj">
          <user-fax-edit-box
            v-for="(fax, index) in faxes"
            :key="index"
            :fax="fax"
            :index="index"
            :user-id="userInfo.id"
            :faxes="faxes"
            @remove="removeFax"
            @update-fax="updateFax"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
import UserFaxEditBox from '@/components/user-page/user-fax-edit-box'
export default {
  components: { UserFaxEditBox },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {
          id: 0,
          userClass: 1,
          postcode: '',
          name: '',
          address: '',
          cautionLevel: 1,
          status: 1,
          memo: ''
        }
      }
    },
    userFaxes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      faxes: []
    }
  },
  computed: {
    staffId: get('auth@user.id'),
    disableFlag() {
      return !this.userInfo.id
    }
  },
  watch: {
    userFaxes() {
      this.setFaxes()
    }
  },
  mounted() {
    this.setFaxes()
  },
  methods: {
    addNewFaxObj() {
      this.faxes = [
        ...this.faxes,
        {
          id: 0,
          name: null,
          faxes: null,
          nameFurigana: null,
          memo: null
        }
      ]
    },
    removeFax(params) {
      this.$emit('update-user-faxes')
      this.faxes.splice(params.index, 1)
    },
    setFaxes() {
      this.faxes =
        this.userFaxes && this.userFaxes.length > 0
          ? this.userFaxes
          : [
              {
                id: 0,
                name: null,
                fax: null,
                nameFurigana: null,
                memo: null
              }
            ]

      this.faxes = this.faxes.slice().reverse()
    },
    updateFax() {
      this.$emit('update-user-faxes')
    }
  }
}
</script>
