<template>
  <div
    class="user-parking-source-checked-content">
    <div class="inputs">
      <div class="mdc-select field -outlined -source-type">
        <select
          v-model.number="sourceType"
          class="nativecontrol"
        >
          <option
            v-for="sourceTypeEntry in SourceType.entries"
            :key="sourceTypeEntry.value"
            :value="sourceTypeEntry.value"
            class="sourcetype"
          >
            {{ sourceTypeEntry.label }}
          </option>
        </select>
      </div>
      <div
        :class="{'-disabled': isDisabledCompanyInputs}"
        class="mdc-select field -outlined -company-name"
      >
        <select
          v-model.trim="companyName"
          class="nativecontrol"
        >
          <option
            v-for="companyName in CompanyName.entries"
            :key="companyName.value"
            :value="companyName.value"
            class="companyname"
          >
            {{ companyName.label }}
          </option>
        </select>
      </div>
      <div
        :class="{'-disabled': isDisabledCompanyInputs}"
        class="mdc-text-field field -outlined -company-source"
      >
        <input
          v-model.trim="companySource"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field field -outlined -same-parking-ids">
        <input
          v-model.trim="sameParkingIds"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field field -outlined -memo">
        <input
          v-model.trim="memo"
          type="text"
          class="input">
      </div>
    </div>
    <div class="btns">
      <button
        :ref="`saveUserParkingSourceChecked${userParkingSourceCheckedId}`"
        :disabled="isDisabledSaveButton"
        class="mdc-button -save"
        @click="saveUserParkingSourceChecked"
      >
        <svg class="icon"/>
        保存
      </button>
      <button
        class="mdc-button -delete"
        @click="removeUserParkingSourceChecked"
      >
        <svg class="icon"/>
        削除
      </button>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { SourceType, CompanyName } from '@/store/user/userParkingSourceCheckeds'

const CompaniesHref = {
  Reins: 'https://system.reins.jp/',
  ATBB: 'https://atbb.athome.jp/',
  'ITANDI BB': 'https://itandibb.com/'
}

export default {
  props: {
    userParkingSourceChecked: {
      type: Object,
      default: function() {
        return {}
      }
    },
    userId: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    const {
      id: userParkingSourceCheckedId,
      sourceType = '',
      companyName = '',
      companySource = '',
      sameParkingIds,
      memo
    } = this.userParkingSourceChecked
    return {
      SourceType,
      CompanyName,
      userParkingSourceCheckedId,
      sourceType,
      companyName,
      companySource,
      sameParkingIds,
      memo,
      isDisabledSaveButton: true
    }
  },
  computed: {
    staffId: get('auth@user.id'),
    userParkingSourceCheckedParams() {
      return {
        userParkingSourceCheckedId: this.userParkingSourceCheckedId,
        userId: this.userId,
        sourceType: this.sourceType,
        companyName: this.companyName,
        companySource: this.companySource,
        sameParkingIds: this.sameParkingIds,
        memo: this.memo
      }
    },
    isDisabledCompanyInputs() {
      return this.sourceType !== SourceType.WEB
    },
    isValidParkingSourceCheckedData() {
      return (
        this.sourceType ||
        this.companyName ||
        this.companySource ||
        this.sameParkingIds ||
        this.memo
      )
    },
    isUpdateParkingSourceChecked() {
      const isUpdateSourceType =
        (this.sourceType || this.userParkingSourceChecked.sourceType) &&
        this.sourceType !== this.userParkingSourceChecked.sourceType
      const isUpdateCompanyName =
        (this.companyName || this.userParkingSourceChecked.companyName) &&
        this.companyName !== this.userParkingSourceChecked.companyName
      const isUpdateCompanySource =
        (this.companySource || this.userParkingSourceChecked.companySource) &&
        this.companySource !== this.userParkingSourceChecked.companySource
      const isUpdateSameParkingIds =
        (this.sameParkingIds || this.userParkingSourceChecked.sameParkingIds) &&
        this.sameParkingIds !== this.userParkingSourceChecked.sameParkingIds
      const isUpdateMemo =
        (this.memo || this.userParkingSourceChecked.memo) &&
        this.memo !== this.userParkingSourceChecked.memo

      return (
        isUpdateSourceType ||
        isUpdateCompanyName ||
        isUpdateCompanySource ||
        isUpdateSameParkingIds ||
        isUpdateMemo
      )
    }
  },
  watch: {
    userParkingSourceChecked() {
      this.setUserParkingSourceChecked()
    },
    sourceType() {
      if (this.isDisabledCompanyInputs) {
        this.companyName = ''
        this.companySource = ''
      }
    },
    companyName() {
      if (Object.keys(CompaniesHref).includes(this.companyName)) {
        this.companySource = CompaniesHref[this.companyName]
      } else {
        this.companySource = ''
      }
    }
  },
  created() {
    this.$watch(
      vm => [
        vm.sourceType,
        vm.companyName,
        vm.companySource,
        vm.sameParkingIds,
        vm.memo
      ],
      () => {
        this.isDisabledSaveButton =
          !this.isValidParkingSourceCheckedData ||
          !this.isUpdateParkingSourceChecked
      }
    )
  },
  methods: {
    showSnackBar: call('ui/showSnackBar'),
    createUserParkingSourceChecked: call(
      'user/userParkingSourceCheckeds/createUserParkingSourceChecked'
    ),
    updateUserParkingSourceChecked: call(
      'user/userParkingSourceCheckeds/updateUserParkingSourceChecked'
    ),
    deleteUserParkingSourceChecked: call(
      'user/userParkingSourceCheckeds/deleteUserParkingSourceChecked'
    ),
    async saveUserParkingSourceChecked() {
      const userParkingSourceCheckedParams = {
        userId: this.userId,
        sourceType: this.sourceType,
        companyName: this.companyName,
        companySource: this.companySource,
        sameParkingIds: this.sameParkingIds,
        memo: this.memo
      }

      try {
        if (this.userParkingSourceCheckedId) {
          const updatedUserParkingSourceChecked = await this.updateUserParkingSourceChecked(
            {
              userParkingSourceCheckedId: this.userParkingSourceCheckedId,
              ...userParkingSourceCheckedParams
            }
          )

          this.isDisabledSaveButton = true
          this.$emit('update-user-parking-source-checked')

          return
        }

        const createdUserParkingSourceChecked = await this.createUserParkingSourceChecked(
          userParkingSourceCheckedParams
        )
        this.isDisabledSaveButton = true
        this.userParkingSourceCheckedId =
          createdUserParkingSourceChecked.data.id
        this.$emit('update-user-parking-source-checked')
      } catch (e) {
        this.showSnackBar({ message: '問題が発生されました' })
        this.$sentry.captureMessage(
          'ユーザーに物件確認先追加が失敗されました。'
        )
      }
    },
    async removeUserParkingSourceChecked() {
      if (!this.userParkingSourceCheckedId) {
        this.$emit('remove', { index: this.index })
        return
      }

      if (confirm(`物件確認先情報が削除されますが、よろしいでしょうか？`)) {
        await this.deleteUserParkingSourceChecked({
          userId: this.userId,
          userParkingSourceCheckedId: this.userParkingSourceCheckedId
        })
        this.$emit('update-user-parking-source-checked')
      }
    },
    setUserParkingSourceChecked() {
      this.userParkingSourceCheckedId = this.userParkingSourceChecked.id
      this.sourceType = this.userParkingSourceChecked.sourceType
      this.companyName = this.userParkingSourceChecked.companyName
      this.companySource = this.userParkingSourceChecked.companySource
      this.sameParkingIds = this.userParkingSourceChecked.sameParkingIds
      this.memo = this.userParkingSourceChecked.memo
    }
  }
}
</script>
