<template>
  <div class="user-group-name-input">
    <div class="mdc-text-field field -outlined">
      <input
        v-model="inputtedName"
        class="input"
        type="text"
        @keyup="onKeyUp">
      <ul
        v-show="suggestingGroups && suggestingGroups.length > 0"
        class="mdc-list search-input-autocomplete suggestion">
        <li
          v-for="group in suggestingGroups"
          :key="group.id"
          :id="group.id"
          :value="group.name"
          class="item result"
          @click="selectGroup(group)">
          <p
            :id="group.id"
            class="text">{{ group.name }} (ID: {{ group.id }})</p>
        </li>
      </ul>
      <button 
        v-show="shouldShowClearInputButton" 
        class="clearselectionbtn"
        @click="onClickClearInputButton"></button>
    </div>
    <button 
      v-show="shouldShowDetailGroupButton" 
      class="mdc-button groupdetailbtn"
      @click="onClickDetailGroupButton">グループ詳細</button>
    <span
      v-show="shouldShowError"
      class="error">{{ errorMessage }}</span>
    <user-group-create-form 
      :show="shouldShowAddGroupButton" 
      :model="newGroupInfo" 
      :on-input="emitNewGroupInfo" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { API_USER_GROUP_URL } from '@/config/api-url'
import UserGroupCreateForm from '@/components/user-page/user-group-create-form'

export default {
  components: {
    UserGroupCreateForm
  },
  props: {
    disablePlaceholder: {
      type: Boolean,
      default: () => false
    },
    disableError: {
      type: Boolean,
      default: () => false
    },
    initGroupId: {
      type: Number,
      default: () => -1
    },
    createGroupError: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      inputtedName: '',
      selectedGroup: {},
      suggestingGroups: [],
      newGroupInfo: {
        name: '',
        memo: ''
      },
      errorMessage: ''
    }
  },
  computed: {
    shouldShowError() {
      if (!this.disableError) {
        return this.inputtedName && this.errorMessage
      }
      return this.errorMessage
    },
    shouldShowAddGroupButton() {
      return !this.inputtedName
    },
    shouldShowDetailGroupButton() {
      return this.selectedGroup && this.selectedGroup.id
    },
    shouldShowClearInputButton() {
      return this.inputtedName
    }
  },
  watch: {
    async initGroupId() {
      this.selectedGroup = await this.fetchInitGroupDetail(this.initGroupId)
      this.inputtedName = this.selectedGroup.name
    },
    createGroupError() {
      this.errorMessage = this.createGroupError
    },
    inputtedName(value) {
      if (value) {
        this.newGroupInfo = {
          name: '',
          memo: ''
        }
        this.emitNewGroupInfo()
      }
    }
  },
  async mounted() {
    this.selectedGroup = await this.fetchInitGroupDetail(this.initGroupId)
    this.inputtedName = this.selectedGroup.name
  },
  methods: {
    onKeyUp: debounce(async function(event) {
      await this.fetchGroupList(event.target.value)
    }, 200),
    onClickDetailGroupButton() {
      if (this.selectedGroup && this.selectedGroup.id) {
        window.open(`/user-group/${this.selectedGroup.id}`, '_blank')
      }
    },
    onClickClearInputButton() {
      this.inputtedName = ''
      this.selectedGroup = {}
      this.suggestingGroups = []
      this.errorMessage = ''
      this.emitSelectedGroup()
    },
    async fetchGroupList(inputtedName) {
      this.selectedGroup = {}
      this.suggestingGroups = []
      // to reset the selected group
      this.emitSelectedGroup()
      if (!inputtedName) {
        this.errorMessage = ''
        return
      }

      const params = { groupName: inputtedName, isEnabled: 1 }
      const response = await this.$axios.get(`${API_USER_GROUP_URL}`, {
        params
      })
      if (!response || !response.data || response.data.length === 0) {
        this.errorMessage = '検索したユーザーグループは見つかりませんでした。'
        return
      }
      this.suggestingGroups = response.data
      this.errorMessage = ''
    },
    async fetchInitGroupDetail(userGroupId) {
      if (!userGroupId || userGroupId === -1) {
        return {}
      }
      try {
        const response = await this.$axios.get(
          `${API_USER_GROUP_URL}/${userGroupId}`
        )
        return response.data
      } catch (error) {
        this.errorMessage = 'ユーザーグループ情報が取得できませんでした。'
        return {}
      }
    },
    selectGroup(group) {
      this.inputtedName = group.name
      this.selectedGroup = group
      this.suggestingGroups = []
      this.emitSelectedGroup()
    },
    emitSelectedGroup() {
      this.$emit('on-select-group', this.selectedGroup)
    },
    emitNewGroupInfo() {
      this.$emit('on-input-new-group-info', this.newGroupInfo)
    }
  }
}
</script>
