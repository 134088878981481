<template>
  <div class="staff-list">
    <div
      class="mdc-tab-bar -elevation1"
      role="tablist">
      <div class="mdc-tab-scroller">
        <div class="scrollarea">
          <div class="scrollcontent tab-content">
            <button
              id="head-office"
              class="mdc-tab -active"
              role="tab"
              tabindex="0"
              aria-selected="true"
              @click="tabClick('headOffice')">
              <span class="content">
                <span class="text-label">本社エリア</span>
                <div class="indicator -active">
                  <div class="content -underline">
                  </div>
                </div>
              </span>
              <span class="mdc-tab-indicator">
                <span class="content -underline"></span>
              </span>
              <span class="tabripple"></span>
            </button>
            <button
              class="mdc-tab"
              role="tab"
              tabindex="0"
              @click="tabClick('other')">
              <span class="content">
                <span class="text-label">西日本エリア</span>
              </span>
              <span class="mdc-tab-indicator">
                <span class="content -underline"></span>
              </span>
              <span class="tabripple"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <section
      id="staff-list-result"
      class="result">
      <div
        v-for="area in areas"
        :key="area.id"
        class="staff-list-box">
        <div class="title">
          <p class="text">{{ area.name }}</p>
          <button
            :disabled="area.staffs.length === 0 || (area.staffs.length === 1 && area.staffs[0].id === staffId)"
            :class="{'-disabled': area.staffs.length === 0 || (area.staffs.length === 1 && area.staffs[0].id === staffId)}"
            class="mdc-button"
            @click="notificationClick(area)">対応可否リクエスト</button>
        </div>
        <ul class="mdc-list">
          <li
            v-for="(staff, index) in area.staffs"
            :key="index"
            class="item">
            <p class="text">{{ staff.username }}</p>
            <p class="meta">内線：{{ staff.extensionNumber || 'なし' }}</p>
          </li>
        </ul>
      </div>

      <div class="pagination">
        <pagination
          :cur-page="curPage"
          :page-count="totalCount"
          @click-handler="paginationClickHandler"/>
      </div>
    </section>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
import { API_ORIGINS_URL, API_USERS_URL } from '@/config/api-url'
import { sync, get, dispatch } from 'vuex-pathify'

export default {
  components: {
    pagination
  },
  data() {
    return {
      openSearchBox: false,
      curPage: 1,
      areas: [],
      searchType: 'headOffice',
      totalCount: 0,
      pageSize: 18,
      notificationMessage: '',
      notificationTitle: ''
    }
  },
  computed: {
    isShowAreasStaffs: sync('ui/areasStaffs@isShow'),
    staffId: get('staffId'),
    inProgressCallHistory: get('biztel/inProgressCallHistory@info')
  },
  watch: {
    searchType() {
      this.getAreasStaffs()
    },
    isShowAreasStaffs(value) {
      if (value) {
        document.getElementById('staff-list-result').scrollTop = 0
        document.getElementById('head-office').click()
      }
    }
  },
  async mounted() {
    await this.getAreasStaffs({
      page: this.curPage,
      pageSize: this.pageSize,
      searchType: 'headOffice'
    })
  },
  methods: {
    async getAreasStaffs(params = {}) {
      const searchType = params.searchType ? params.searchType : this.searchType
      const res = await this.$axios.get(
        `${API_ORIGINS_URL}/${searchType}/areas`,
        {
          params: {
            page: params.page ? params.page : 1,
            pageSize: params.pageSize ? params.pageSize : this.pageSize
          }
        }
      )
      this.areas = res.data
      this.totalCount = Math.ceil(res.headers['x-total-count'] / this.pageSize)
    },
    paginationClickHandler(params = {}) {
      this.curPage = params.curPage
      this.getAreasStaffs({ page: params.curPage })
      document.getElementById('staff-list-result').scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    tabClick(tabName) {
      if (this.searchType !== tabName) {
        document.getElementById('staff-list-result').scrollTop = 0
        this.curPage = 1
        this.searchType = tabName
      }
    },
    async notificationClick(area) {
      try {
        let res = { data: [] }
        if (
          this.inProgressCallHistory.userId &&
          this.inProgressCallHistory.userId > 0
        ) {
          res = await this.$axios.get(
            `${API_USERS_URL}/${this.inProgressCallHistory.userId}`
          )
        }
        const phoneNumber = this.$options.filters.formatPhoneNumber(
          this.inProgressCallHistory.inProgressTel
        )
        const userInfoMessage =
          res.data.name || this.inProgressCallHistory.inProgressTel
            ? `ユーザー名：${res.data.name ||
                'なし'}　\n電話番号　：${phoneNumber || 'なし'}`
            : `ユーザー　：未登録`
        dispatch('notifications/notificationPush/setInfo', {
          area: area.name,
          message: `エリア　　：${area.name}\n${userInfoMessage}`,
          staffs: area.staffs,
          isShow: true
        })
      } catch (e) {
        this.$sentry.captureException(e)
      }
    }
  }
}
</script>
