import { encodeQuery, parseQuery, randomString } from '../utilities'
import { API_AUTH_URL } from '@/config/api-url'
import { firebase } from '@/plugins/firebase'

const DEFAULTS = {
  token_type: 'Bearer',
  response_type: 'token',
  tokenName: 'Authorization',
  access_token_endpoint: 'https://www.googleapis.com/oauth2/v4/token',
  grant_type: 'authorization_code',
  do_refresh_token: false,
  domain: 'azoom.jp',
  exchange_code_endpoint: '/auth/exchange-code',
  refresh_token_endpoint: '/auth/refresh-token'
}
export const isSameURL = (a, b) => a.split('?')[0] === b.split('?')[0]
export const isRelativeURL = u =>
  u && u.length && /^\/[a-zA-Z0-9@\-%_~]*[?]?([^#]*)#?([^#]*)$/.test(u)

export default class Oauth2Scheme {
  constructor(auth, options) {
    this.$auth = auth
    // have to rewrite redirect here to redirect to the page before login
    // cannot override in plugin/auth, because if rewrite there, the redirect method not get override when being called
    this.$auth.redirect = function(name, noRouter = false) {
      if (!this.options.redirect) {
        return
      }

      const from = this.options.fullPathRedirect
        ? this.ctx.route.fullPath
        : this.ctx.route.path

      let to = this.options.redirect[name]
      if (!to) {
        return
      }

      // Apply rewrites
      if (this.options.rewriteRedirects) {
        if (name === 'login' && isRelativeURL(from) && !isSameURL(to, from)) {
          this.$storage.setUniversal('redirect', from)
        }

        if (name === 'home') {
          const redirect =
            this.$storage.getLocalStorage('redirect') ||
            this.ctx.route.query.redirect
          this.$storage.setLocalStorage('redirect', null)

          if (isRelativeURL(redirect)) {
            to = redirect
          }
        }
      }

      // Prevent infinity redirects
      if (isSameURL(to, from)) {
        return
      }

      if (process.browser) {
        if (noRouter) {
          window.location.replace(to)
        } else {
          this.ctx.redirect(to)
        }
      } else {
        this.ctx.redirect(to, { ...this.ctx.route.query, redirect: from })
      }
    }

    this.name = options._name
    this.refreshInterval = undefined

    this.options = Object.assign({}, DEFAULTS, options)
  }

  get _scope() {
    return Array.isArray(this.options.scope)
      ? this.options.scope.join(' ')
      : this.options.scope
  }

  get _redirectURI() {
    const url = this.options.redirect_uri

    if (url) {
      return url
    }

    if (process.browser) {
      return window.location.origin + this.$auth.options.redirect.callback
    }
  }

  async mounted() {
    // Sync token
    const token = this.$auth.syncToken(this.name)
    // Set axios token
    if (token) {
      this._setToken(token)
    }

    // Handle callbacks on page load
    const redirected = await this._handleCallback()

    if (!redirected && token && this.$auth.syncRefreshToken(this.name)) {
      const accessTokenExpiresTime = this.$auth.$storage.getLocalStorage(
        `_expires_at.${this.name}`
      )
      if (
        this.options.do_refresh_token &&
        (!accessTokenExpiresTime ||
          accessTokenExpiresTime - Date.now() / 1000 <= 3600 * 0.25)
      ) {
        await this._updateTokenInLocalStorage()
        this._scheduleTokenRefresh()
      } else if (this.options.do_refresh_token) {
        let self = this
        setTimeout(async function() {
          await self._updateTokenInLocalStorage()
          self._scheduleTokenRefresh()
        }, (accessTokenExpiresTime - Date.now() / 1000 - 3600 * 0.25) * 1000)
      }
      return this.$auth.fetchUserOnce()
    }
  }

  async _updateTokenInLocalStorage() {
    await this._tokenRefresh()
    this.$auth.syncToken(this.name)
    this.$auth.$storage.syncUniversal(`_expires_at.${this.name}`)
  }

  _setToken(token) {
    // Set Authorization token for all axios requests
    this.$auth.ctx.app.$axios.setHeader(this.options.tokenName, token)
  }

  _clearToken() {
    // Clear Authorization token for all axios requests
    this.$auth.ctx.app.$axios.setHeader(this.options.tokenName, false)
  }

  async logout() {
    this.refreshInterval = undefined
    this._clearToken()
    this.$auth.$storage.setUniversal(`_access_token.${this.name}`, false)
    this.$auth.$storage.setUniversal(`_expires_at.${this.name}`, false)
    if (!process.server) {
      firebase
        .auth()
        .signOut()
        .catch(error => {
          console.log(error)
        })
    }
    return this.$auth.reset()
  }

  login() {
    let opts = {
      protocol: 'oauth2',
      response_type: this.options.response_type,
      client_id: this.options.client_id,
      redirect_uri: this._redirectURI,
      scope: this._scope,
      state: randomString()
    }
    if (this.options.response_type.includes('code')) {
      opts = {
        ...opts,
        access_type: 'offline',
        prompt: 'consent'
      }
    }

    this.$auth.$storage.setLocalStorage(this.name + '.state', opts.state)
    const url = this.options.authorization_endpoint + '?' + encodeQuery(opts)
    window.location = url
  }

  async fetchUser() {
    if (!this.$auth.getToken(this.name)) {
      return
    }

    const accessToken = this.$auth.$storage.syncUniversal(
      `_access_token.${this.name}`
    )
    if (!accessToken) {
      return
    }

    if (!this.options.userinfo_endpoint) {
      this.$auth.setUser({})
      return
    }

    const googleUserInfo = await this.$auth.requestWith(this.name, {
      url: this.options.userinfo_endpoint,
      headers: { Authorization: `Bearer ${accessToken}` }
    })

    if (googleUserInfo.hd !== this.options.domain) {
      await this.$auth.logout()
      this.$auth.redirect('loginError', true)
      return
    }

    let staff = {}
    try {
      staff = await this.$auth.requestWith(this.name, {
        method: 'post',
        url: API_AUTH_URL,
        data: {
          googleId: googleUserInfo.sub,
          email: googleUserInfo.email
        }
      })

      if (!staff || !staff.id) {
        await this.$auth.logout()
        this.$auth.redirect('loginError', true)
        return
      }
    } catch (error) {
      await this.$auth.logout()
      this.$auth.redirect('loginError', true)
      return
    }

    this.$auth.setUser({
      googleId: googleUserInfo.sub,
      email: googleUserInfo.email,
      ...staff
    })
  }

  async _handleCallback() {
    // Callback flow is not supported in server side
    if (process.server) {
      return
    }

    // Parse query from both search and hash fragments
    const hash = parseQuery(window.location.hash.substr(1))
    const search = parseQuery(window.location.search.substr(1))
    const parsedQuery = Object.assign({}, search, hash)

    let token
    let accessToken = parsedQuery['access_token']
    let idToken = parsedQuery['id_token']
    let expiresIn = 3600

    // refresh token
    let refreshToken =
      parsedQuery[this.options.refresh_token_key || 'refresh_token']

    // -- Authorization Code Grant --
    if (this.options.response_type === 'code' && parsedQuery.code) {
      let data
      try {
        data = await this.$auth.ctx.app.$axios.$post(
          `${window.location.origin}${this.options.exchange_code_endpoint}`,
          {
            code: parsedQuery.code,
            client_id: this.options.client_id,
            redirect_uri: this._redirectURI,
            response_type: this.options.response_type,
            grant_type: this.options.grant_type
          },
          { headers: { Authorization: null } }
        )
      } catch (error) {
        return
      }

      accessToken = data.access_token ? data.access_token : accessToken
      idToken = data.id_token ? data.id_token : idToken
      refreshToken = data.refresh_token ? data.refresh_token : refreshToken
      expiresIn = data.expires_in ? data.expires_in : expiresIn
    }

    if (
      (!accessToken || !accessToken.length) &&
      (!idToken || !idToken.length)
    ) {
      return
    }

    // Validate state
    const state = this.$auth.$storage.getLocalStorage(this.name + '.state')
    this.$auth.$storage.setLocalStorage(this.name + '.state', null)
    if (state && parsedQuery.state !== state) {
      return
    }

    // Append token_type
    if (this.options.token_type) {
      token =
        this.options.token_key === 'access_token'
          ? `${this.options.token_type} ${accessToken}`
          : `${this.options.token_type} ${idToken}`
    }

    // Store token
    this.$auth.setToken(this.name, token)
    this.$auth.$storage.setUniversal(`_access_token.${this.name}`, accessToken)
    this.$auth.$storage.setUniversal(
      `_expires_at.${this.name}`,
      parseInt(Date.now() / 1000) + expiresIn
    )

    // Set axios token
    this._setToken(token)

    // Store refresh token
    if (refreshToken && refreshToken.length) {
      this.$auth.setRefreshToken(this.name, refreshToken)
    }

    // Redirect to home
    this.$auth.redirect('home', true)

    return true // True means a redirect happened
  }

  async _updateTokens(result) {
    const {
      access_token: accessToken,
      id_token: idToken,
      expires_in: expiresIn
    } = result

    let token
    // Append token_type
    if (this.options.token_type) {
      token =
        this.options.token_key === 'access_token'
          ? `${this.options.token_type} ${accessToken}`
          : `${this.options.token_type} ${idToken}`
    }

    // Store token
    this.$auth.setToken(this.name, token)
    this.$auth.$storage.setUniversal(`_access_token.${this.name}`, accessToken)
    this.$auth.$storage.setUniversal(
      `_expires_at.${this.name}`,
      parseInt(Date.now() / 1000) + expiresIn
    )

    // Set axios token
    this._setToken(token)

    const credential = firebase.auth.GoogleAuthProvider.credential(idToken)
    // Sign in with credential from the Google user.
    await firebase
      .auth()
      .signInAndRetrieveDataWithCredential(credential)
      .catch(function(error) {
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.email
        const credential = error.credential
        console.log(errorCode, errorMessage, email, credential)
      })
  }

  _tokenRefresh() {
    if (process.server) {
      return
    }
    return this.$auth.ctx.app.$axios
      .post(
        `${window.location.origin}${this.options.refresh_token_endpoint}`,
        {
          refresh_token: this.$auth.getRefreshToken(this.name),
          client_id: this.options.client_id,
          grant_type: 'refresh_token'
        },
        { headers: { Authorization: null } }
      )
      .then(async response => {
        await this._updateTokens(response.data)
      })
      .catch(err => {
        this.$auth.logout()
      })
  }

  _scheduleTokenRefresh() {
    let self = this
    this.refreshInterval = setInterval(function() {
      self._tokenRefresh()
    }, 3600 * 1000 * 0.75)
  }
}
