import { make } from 'vuex-pathify'
import { SWITCHER_TYPE_CUSTOMER } from '@/config/switcher-user-type'

export const state = () => ({
  phoneReceptionRequestList: { open: false },
  phoneNoteList: { open: false },
  notificationDetail: { open: false },
  notificationNotice: { open: false },
  userEditPage: { open: false },
  userAddPage: {
    open: false,
    type: ''
  },
  addTels: { open: false },
  addMails: { open: false },
  addFaxes: { open: false },
  notificationList: {
    open: false,
    loadedAllNotifications: false,
    lastLoadedNotificationId: null
  },
  search: {
    isShowListAreas: false
  },
  staffs: {
    isShow: false
  },
  searchUsers: {
    isShow: false,
    type: ''
  },
  searchParkingProvidingUsers: {
    isSearching: false,
    isShow: false
  },
  searchServingContactUsers: {
    isShow: false
  },
  searchParkingProvidingHintUsers: {
    isShow: false
  },
  searchServingContactHintUsers: {
    isShow: false
  },
  searchHintUsers: {
    isShow: false
  },
  areasStaffs: {
    isShow: false
  },
  companyPhones: {
    isShow: false
  },
  snackBar: {
    isShow: false,
    message: ''
  },
  callEndDialog: {
    isShow: false,
    message: ''
  },
  indeterminateProgress: {
    isShow: false
  },
  branchListDialog: {
    isShow: false
  },
  userGroupPage: {
    drawer: {
      isAddUserToGroupOpened: false,
      isSearchUserGroupOpened: false,
      isEditUserGroupOpened: false
    }
  },
  userPage: {
    switcher: {
      userType: SWITCHER_TYPE_CUSTOMER,
      open: true,
      forceUserType: ''
    }
  },
  parkingWaitingContacts: {
    drawer: {
      editContact: {
        open: false
      }
    }
  }
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  showSnackBar(context, params = {}) {
    const { message } = params

    context.commit('SET_SNACK_BAR', {
      isShow: true,
      message: message
    })

    setTimeout(() => {
      context.commit('SET_SNACK_BAR', {
        isShow: false,
        message: ''
      })
    }, 5000)
  },
  showServingContactHintUsers(context, params = {}) {
    context.commit('SET_SEARCH_SERVING_CONTACT_HINT_USERS', {
      isShow: params.isShow
    })
  },
  showParkingProvidingHintUsers(context, params = {}) {
    context.commit('SET_SEARCH_PARKING_PROVIDING_HINT_USERS', {
      isShow: params.isShow
    })
  }
}
