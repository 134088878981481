<template>
  <div>
    <aside
      id="areas-staffs"
      class="mdc-drawer -modal -right drawer"
      @keyup.esc="closeDrawer">
      <div
        class="areas-staffs">
        <div class="mdc-top-app-bar mdc-top-app-bar-fixed -x-section">
          <div class="row">
            <section
              class="section -alignstart">
              <button
                class="navicon"
                @click="closeDrawer"></button>
              <h1 class="title">エリア担当リスト</h1>
            </section>
          </div>
        </div>

        <areas-staffs />
      </div>
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="onClickOverlay"></div>
  </div>
</template>


<script>
import areasStaffs from '@/components/areas-staffs'
import { sync } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'

export default {
  components: {
    'areas-staffs': areasStaffs
  },
  computed: {
    isShowAreasStaffs: sync('ui/areasStaffs@isShow')
  },
  watch: {
    isShowAreasStaffs(value) {
      this.searchAreasStaffsDrawer.open = value
      value
        ? document.body.classList.add('-notscroll')
        : document.body.classList.remove('-notscroll')
    }
  },
  mounted() {
    this.searchAreasStaffsDrawer = new MDCDrawer(
      document.getElementById('areas-staffs')
    )
    this.searchAreasStaffsDrawer.open = this.isShowAreasStaffs
  },
  methods: {
    closeDrawer() {
      this.isShowAreasStaffs = false
    },
    onClickOverlay() {
      this.isShowAreasStaffs = false
    }
  }
}
</script>
