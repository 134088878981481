import { make } from 'vuex-pathify'
import {
  API_CALL_HISTORIES_URL,
  API_TELS_URL,
  API_USERS_URL
} from '@/config/api-url'

export const state = () => ({
  list: []
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async getCallHistories(context, { userId }) {
    try {
      const callHistoriesRes = await this.$axios.get(
        `${API_USERS_URL}/${userId}/call-histories`
      )
      context.commit('SET_LIST', callHistoriesRes.data)
    } catch (e) {
      context.commit('SET_LIST', [])
      this.$sentry.captureException(e)
    }
  },
  async createCallHistories(context, params = {}) {
    await this.$axios.post(`${API_CALL_HISTORIES_URL}`, {
      staffId: params.staffId || 0,
      tel: params.tel || '',
      callType: params.callType || 1,
      userId: params.userId || 0,
      historyType: params.historyType || 'callOut'
    })
    context.dispatch('getCallHistories', { userId: params.userId || 0 })
  }
}
