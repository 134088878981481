<template>
  <div
    :class="{'disable-mails': disableFlag}"
    class="user-email-edit">
    <div class="title">
      <h1 class="text">メール</h1>
    </div>
    <div class="content -elevation1">
      <div class="main-content">
        <div class="title">
          <div class="main">
            <p class="email">メール<span class="caution -necessary">(必要)</span></p>
            <p class="name">名前</p>
            <p class="namefurigana">ふりがな</p>
            <p class="supplement">補足</p>
          </div>
          <div class="addnew">
            <button
              class="mdc-button"
              @click="addNewEmailObj"><svg class="icon" />追加</button>
          </div>
        </div>
        <div class="email-obj">
          <user-email-edit-box
            v-for="(email, index) in emails"
            :key="index"
            :email="email"
            :index="index"
            :user-id="userInfo.id"
            :emails="emails"
            @remove="removeEmail"
            @update-email="updateEmail"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
import userEmailEditBox from '@/components/user-page/user-email-edit-box'
export default {
  components: { userEmailEditBox },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {
          id: 0,
          userClass: 1,
          postcode: '',
          name: '',
          address: '',
          cautionLevel: 1,
          status: 1,
          memo: ''
        }
      }
    },
    userEmails: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      emails: []
    }
  },
  computed: {
    staffId: get('auth@user.id'),
    disableFlag() {
      return !this.userInfo.id
    }
  },
  watch: {
    userEmails() {
      this.setEmails()
    }
  },
  mounted: async function() {
    this.setEmails()
  },
  methods: {
    showSnackBar: call('ui/showSnackBar'),
    addNewEmailObj() {
      this.emails = [
        ...this.emails,
        {
          id: 0,
          name: null,
          email: null,
          nameFurigana: null,
          memo: null
        }
      ]
    },
    removeEmail(params) {
      this.emails.splice(params.index, 1)
      this.$emit('update-user-emails')
    },
    updateEmail() {
      this.$emit('update-user-emails')
    },
    setEmails() {
      this.emails =
        this.userEmails && this.userEmails.length > 0
          ? this.userEmails
          : [
              {
                id: 0,
                name: null,
                email: null,
                nameFurigana: null,
                memo: null
              }
            ]
      this.emails = this.emails.slice().reverse()
    }
  }
}
</script>
