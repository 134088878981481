<template>
  <div id="default-layout">
    <the-header />
    <nuxt class="the-home-content"/>
    <notification-detail />
    <notification-phone-reception-request-notice />
    <notification-push-dialog />
    <circular-indeterminate-progress/>
    <rep-waiting-alert />
    <dialog-snack-bar />
    <drawer-parking-providing-user-search />
    <drawer-serving-contact-user-search />
    <drawer-add-user />
    <drawer-user-group-search />
    <drawer-user-group-edit />
    <drawer-search-staffs />
    <drawer-areas-staffs />
    <drawer-company-phone />
    <drawer-parking-waiting-contact />
  </div>
</template>
<script>
import { get } from 'vuex-pathify'
import theHeader from '@/components/the-header'
import notificationDetail from '@/components/notification-detail'
import notificationPhoneReceptionRequestNotice from '@/components/notification-phone-reception-request-notice'
import notificationPushDialog from '@/components/notification/notification-push-dialog'
import circularIndeterminateProgress from '@/components/circular-indeterminate-progress'
import repWaitingAlert from '@/components/alert/rep-waiting-alert'
import dialogSnackBar from '@/components/dialog-snack-bar'
import drawerParkingProvidingUserSearch from '@/components/drawer/parking-providing-user-search-drawer'
import drawerServingContactUserSearch from '@/components/drawer/serving-contact-user-search-drawer'
import drawerAddUser from '@/components/user-page/drawer-user-add'
import drawerUserGroupSearch from '@/components/user-group-page/drawer-user-group-search'
import drawerUserGroupEdit from '@/components/user-group-page/drawer-user-group-edit'
import drawerSearchStaffs from '@/components/drawer-search-staffs'
import drawerAreasStaffs from '@/components/drawer-areas-staffs'
import drawerCompanyPhone from '@/components/drawer-company-phone'
import drawerParkingWaitingContact from '@/components/parking/waiting-contacts/drawer'

export default {
  components: {
    theHeader,
    notificationDetail,
    notificationPhoneReceptionRequestNotice,
    notificationPushDialog,
    circularIndeterminateProgress,
    repWaitingAlert,
    dialogSnackBar,
    drawerParkingProvidingUserSearch,
    drawerServingContactUserSearch,
    drawerAddUser,
    drawerUserGroupSearch,
    drawerUserGroupEdit,
    drawerSearchStaffs,
    drawerAreasStaffs,
    drawerCompanyPhone,
    drawerParkingWaitingContact
  },
  head() {
    return {
      title: 'ホーム｜PAPP',
      link: [{ rel: 'icon', type: 'image/png', href: '/favicon.png' }]
    }
  },
  computed: {
    hasPhoneReceptionRequestNotification: get(
      'notifications/hasPhoneReceptionRequestNotification'
    ),
    hasPhoneNoteNotification: get('notifications/hasPhoneNoteNotification'),
    hasNotification() {
      return (
        this.hasPhoneReceptionRequestNotification ||
        this.hasPhoneNoteNotification
      )
    }
  },
  watch: {
    hasNotification() {
      if (this.hasNotification) {
        this.$helpers.setFavicon('/favicon-notification.png')
      } else {
        this.$helpers.setFavicon('/favicon.png')
      }
    }
  },
  mounted() {
    if (this.hasNotification) {
      this.$helpers.setFavicon('/favicon-notification.png')
    } else {
      this.$helpers.setFavicon('/favicon.png')
    }
  }
}
</script>
