<template>
  <div
    class="email-content">
    <div class="input">
      <div class="mdc-text-field -outlined -focused email">
        <input
          v-model.trim="emailAddress"
          type="text"
          class="input">
        <p
          v-show="emailAddressErrorMessage"
          class="emailAddressError">{{ emailAddressErrorMessage }}</p>
      </div>
      <div class="mdc-text-field -outlined name">
        <input
          v-model.trim="name"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field -outlined namefurigana">
        <input
          v-model.trim="nameFurigana"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field -outlined supplement">
        <input
          v-model.trim="memo"
          type="text"
          class="input">
      </div>
    </div>
    <div class="btns">
      <button
        :ref="`saveEmail${id}`"
        :disabled="saveDisabled"
        class="mdc-button -save"
        @click="saveEmail"><svg class="icon" />保存</button>
      <button
        class="mdc-button -delete"
        @click="deleteEmail"><svg class="icon" />削除</button>
    </div>
  </div>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
export default {
  props: {
    email: {
      type: Object,
      default: () => {}
    },
    userId: {
      type: Number,
      default: () => 0
    },
    index: {
      type: Number,
      default: () => 0
    },
    emails: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      id: 0,
      emailAddress: '',
      name: '',
      nameFurigana: '',
      memo: '',
      emailAddressErrorMessage: '',
      saveDisabled: true
    }
  },
  computed: {
    staffId: get('auth@user.id')
  },
  watch: {
    email() {
      this.setEmail()
    },
    emailAddress() {
      this.updateSaveDisabled()

      if (!this.emailAddress && this.id) {
        this.emailAddressErrorMessage = 'メールアドレスを入力してください'
        this.setSaveDisabled(true)

        return
      }

      const checkExistEmail = this.emails.filter(
        email => email.email === this.emailAddress
      )
      if (checkExistEmail.length > 0 && this.id !== checkExistEmail[0].id) {
        this.emailAddressErrorMessage = 'メールアドレスが登録されました'
        this.setSaveDisabled(true)
        return
      }
      this.emailAddressErrorMessage = ''
    },
    memo() {
      this.updateSaveDisabled()
    },
    nameFurigana() {
      this.updateSaveDisabled()
    },
    name() {
      this.updateSaveDisabled()
    }
  },
  created() {
    this.setEmail()
  },
  methods: {
    showSnackBar: call('ui/showSnackBar'),
    async saveEmail() {
      try {
        if (this.id) {
          const updateEmail = await this.$axios.patch(
            `${API_USERS_URL}/${this.userId}/emails/${this.id}`,
            {
              email: this.emailAddress || '',
              name: this.name || '',
              nameFurigana: this.nameFurigana || '',
              memo: this.memo || '',
              staffId: this.staffId || 0
            }
          )

          this.$emit('update-email')
          this.id = updateEmail.data.emailId
          this.setSaveDisabled(true)
          this.showSnackBar({ message: 'メールを保存しました' })
          return
        }

        const checkExistEmail = this.emails.filter(
          email => email.email === this.emailAddress
        )
        if (checkExistEmail.length > 0) {
          this.emailAddressErrorMessage = 'メールが登録されました'
          return
        }
        const creatEmailObj = await this.$axios.post(
          `${API_USERS_URL}/${this.userId}/emails`,
          {
            email: this.emailAddress || '',
            name: this.name || '',
            nameFurigana: this.nameFurigana || '',
            memo: this.memo || '',
            staffId: this.staffId || 0
          }
        )

        this.$emit('update-email')
        this.id = creatEmailObj.data.id
        this.showSnackBar({ message: 'メールを追加しました' })
        this.setSaveDisabled(true)
      } catch (e) {
        this.showSnackBar({ message: '問題が発生されました' })
        this.$sentry.captureException(e)
      }
    },
    async deleteEmail() {
      try {
        if (!this.id) {
          this.$emit('remove', { index: this.index })
          return
        }

        if (
          confirm(
            `【${
              this.email.email
            }】のメールが削除されますが、よろしいでしょうか？`
          )
        ) {
          await this.$axios.delete(
            `${API_USERS_URL}/${this.userId}/emails/${this.id}`,
            {
              params: { staffId: this.staffId || 0 }
            }
          )

          this.$emit('update-email')
          let emailsUpdate = this.emails
          emailsUpdate.splice(this.index, 1)
          this.showSnackBar({ message: 'メールを削除しました' })
        }
      } catch (e) {
        this.$sentry.captureException(e)
      }
    },
    setEmail() {
      this.id = this.email.id
      this.emailAddress = this.email.email
      this.name = this.email.name
      this.nameFurigana = this.email.nameFurigana
      this.memo = this.email.memo
    },
    updateSaveDisabled() {
      if (!this.id) {
        this.setSaveDisabled(true)
      }

      if (
        (!this.emailAddress && !this.email.email) ||
        this.emailAddress === this.email.email
      ) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if ((!this.memo && !this.email.memo) || this.memo === this.email.memo) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if ((!this.name && !this.email.name) || this.name === this.email.name) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if (
        (!this.nameFurigana && !this.email.nameFurigana) ||
        this.nameFurigana === this.email.nameFurigana
      ) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }
    },
    setSaveDisabled(value) {
      this.saveDisabled = value
    }
  }
}
</script>
