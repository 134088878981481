<template>
  <div
    v-show="show"
    class="notification-detail-phone-note">
    <div class="additionalinfo">
      <p class="text">{{ additionalInfo }}</p>
      <div class="notificationstatus">
        <p
          :class="statusClass"
          class="status -ellipse">{{ statusText }}</p>
      </div>
    </div>
    <div class="note-notification-detail">
      <div class="content">
        <p
          class="text"
          v-html="detailMessage"></p>
      </div>
    </div>
  </div>
</template>
<script>
import { get, call } from 'vuex-pathify'
import {
  COLLECTION_PHONE_NOTE,
  PHONE_NOTE_DETAIL_TYPE_SENDER
} from '@/store/notifications'

export default {
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    staffId: get('staffId'),
    notificationAttributes: get('notifications/notificationDetail@attributes'),
    isNotification() {
      return this.notification && this.notification.id
    },
    isDone() {
      return (
        this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.isDone
      )
    },
    isHandled() {
      return (
        this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.isHandled
      )
    },
    show() {
      return (
        this.isNotification &&
        this.notification.type === COLLECTION_PHONE_NOTE &&
        this.notificationAttributes &&
        this.notificationAttributes.to === PHONE_NOTE_DETAIL_TYPE_SENDER
      )
    },
    additionalInfo() {
      if (
        this.isNotification &&
        this.notification.sender &&
        this.notification.updatedAt &&
        this.notification.receivers &&
        this.notification.receiverIds &&
        this.notification.receivers[this.notification.receiverIds[0]]
      ) {
        return `${this.$helpers.formatDateFromFirestore(
          this.notification.updatedAt
        )} to ${
          this.notification.receivers[this.notification.receiverIds[0]].username
        }`
      }
      return ''
    },
    statusClass() {
      return this.isDone || this.isHandled
        ? { '-handled': true }
        : { '-sentwaiting': true }
    },
    statusText() {
      return this.isDone || this.isHandled ? '承諾済' : '承諾待ち'
    },
    detailMessage() {
      return this.isNotification &&
        this.notification.content &&
        this.notification.content.message
        ? this.$helpers.nl2br(this.notification.content.message)
        : ''
    }
  },
  methods: {
    hideNotificationDetail: call('notifications/hideNotificationDetail')
  }
}
</script>
