<template>
  <div class="alert-box-item">
    <div class="time">
      <div
        :class="{ '-timeout': isTimeout }"
        class="in">
        <div
          :class="{ '-timeout': isTimeout }"
          class="clock">
        </div>
        <div class="runtime">
          {{ minutes }}:{{ seconds }}
        </div>
      </div>
    </div>
    <div 
      class="name" 
      @click="onClickNameAction">
      {{ item.receiverName }}
    </div>
    <button 
      class="mdc-button acceptbtn" 
      @click="onClickAcceptAction">
      自分対応
    </button>
  </div>
</template>

<script>
import { dispatch } from 'vuex-pathify'
import {
  COLLECTION_PHONE_RECEPTION_REQUEST,
  NOTIFICATION_TRIGGER_USER_ACTION
} from '@/store/notifications'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newTime: this.item.createdTime,
      minutes: '0',
      seconds: '00'
    }
  },
  computed: {
    isTimeout() {
      return parseInt(this.minutes) * 60 + parseInt(this.seconds) > 15
    }
  },
  mounted() {
    this.newTime = Math.floor(Date.now() / 1000) - this.item.createdTime
    this.toggleTimer()
  },
  methods: {
    toggleTimer() {
      setInterval(this.setTime, 1000)
    },
    setTime() {
      // todo: moment
      this.newTime = parseInt(this.newTime) + 1
      this.minutes = parseInt(this.newTime / 60)
      this.seconds = this.convertTime(this.newTime % 60)
    },
    convertTime(val) {
      return String(val).length < 2 ? '0' + val : String(val)
    },
    onClickNameAction() {
      dispatch('notifications/handleNotification', {
        notificationId: this.item.id,
        notificationType: COLLECTION_PHONE_RECEPTION_REQUEST,
        from: NOTIFICATION_TRIGGER_USER_ACTION
      })
    },
    onClickAcceptAction() {
      dispatch('notifications/setRequestIsAccepted', {
        requestId: this.item.id
      })
    }
  }
}
</script>
