<template>
  <div
    v-show="openNotificationDetail && hasNotification">
    <div class="notification-detail -elevation15">
      <div class="title">
        <p class="text">{{ dialogTitle }}</p>
        <button
          class="mdc-icon-button"
          @click="hideNotificationDetail">
        </button>
      </div>
      <notification-detail-phone-note :notification="notification" />
      <notification-detail-sent-phone-note :notification="notification" />
      <notification-detail-phone-reception-request :notification="notification" />
    </div>
    <div
      class="component-overlay"
      @click="hideNotificationDetail"></div>
  </div>
</template>
<script>
import { get, call } from 'vuex-pathify'
import { COLLECTION_PHONE_NOTE } from '@/store/notifications'
import notificationDetailPhoneNote from '@/components/notification-detail-phone-note'
import notificationDetailSentPhoneNote from '@/components/notification-detail-sent-phone-note'
import notificationDetailPhoneReceptionRequest from '@/components/notification-detail-phone-reception-request'

export default {
  components: {
    notificationDetailPhoneNote,
    notificationDetailSentPhoneNote,
    notificationDetailPhoneReceptionRequest
  },
  computed: {
    openNotificationDetail: get('ui/notificationDetail@open'),
    notification: get('notifications/notificationDetail@notification'),
    hasNotification() {
      return this.notification && this.notification.id
    },
    dialogTitle() {
      return this.hasNotification &&
        this.notification.type === COLLECTION_PHONE_NOTE
        ? '伝言メモ'
        : '新規対応の可否'
    }
  },
  watch: {
    openNotificationDetail(value) {
      value
        ? document.body.classList.add('-notscroll')
        : document.body.classList.remove('-notscroll')
    }
  },
  methods: {
    hideNotificationDetail: call('notifications/hideNotificationDetail')
  }
}
</script>
