import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({
  list: []
})

export const mutations = { ...make.mutations(state) }

export const actions = {
  ...make.actions(state)
}
