<template>
  <div>
    <div class="the-side-bar">
      <ul class="mdc-list list">
        <li
          class="item -searchuser"
          @click="openItem('servingContactUserSearch')"
          @mouseenter="showTooltip('servingContactUserSearchTooltip')"
          @mouseleave="closeTooltip('servingContactUserSearchTooltip')">
        </li>
        <span
          :class="{'-show': servingContactUserSearchTooltip}"
          class="tooltip">問い合わせユーザー検索</span>
        <li
          class="item -searchuserprovider"
          @click="openItem('searchParkingProvidingUsers')"
          @mouseenter="showTooltip('searchParkingProvidingUsersTooltip')"
          @mouseleave="closeTooltip('searchParkingProvidingUsersTooltip')">
        </li>
        <span
          :class="{'-show': searchParkingProvidingUsersTooltip}"
          class="tooltip">管理会社・オーナー検索</span>
        <li
          class="item -searchstaffs"
          @click="openItem('searchStaffs')"
          @mouseenter="showTooltip('searchStaffsTooltip')"
          @mouseleave="closeTooltip('searchStaffsTooltip')"></li>
        <span
          :class="{'-show': searchStaffsTooltip}"
          class="tooltip">エリア担当確認</span>
        <li
          class="item -areastaffs"
          @click="openItem('areasStaffs')"
          @mouseenter="showTooltip('areasStaffsTooltip')"
          @mouseleave="closeTooltip('areasStaffsTooltip')"></li>
        <span
          :class="{'-show': areasStaffsTooltip}"
          class="tooltip">エリア担当リスト</span>
        <li
          class="item -phones"
          @click="openItem('companyPhones')"
          @mouseenter="showTooltip('companyPhonesTooltip')"
          @mouseleave="closeTooltip('companyPhonesTooltip')"></li>
        <span
          :class="{'-show': companyPhonesTooltip}"
          class="tooltip">内線・外線番号</span>
        <li
          class="item -notificationpush"
          @click="showNotificationPush"
          @mouseenter="showTooltip('notificationToOneUser')"
          @mouseleave="closeTooltip('notificationToOneUser')"></li>
        <span
          :class="{'-show': notificationToOneUser}"
          class="tooltip -notificationpush">対応可否リクエスト送信</span>
      </ul>
    </div>
    <drawer-serving-contact-user-search />
    <drawer-parking-providing-user-search />
    <drawer-search-staffs />
    <drawer-areas-staffs />
    <drawer-company-phone />
  </div>
</template>
<script>
import drawerServingContactUserSearch from '@/components/drawer/serving-contact-user-search-drawer'
import drawerParkingProvidingUserSearch from '@/components/drawer/parking-providing-user-search-drawer'
import drawerAreasStaffs from '@/components/drawer-areas-staffs'
import drawerCompanyPhone from '@/components/drawer-company-phone'
import drawerSearchStaffs from '@/components/drawer-search-staffs'
import { sync, dispatch, get } from 'vuex-pathify'
import { OLD_EDIT_PARKING_URL, API_USERS_URL } from '@/config/api-url'

export default {
  components: {
    drawerServingContactUserSearch,
    drawerParkingProvidingUserSearch,
    drawerAreasStaffs,
    drawerCompanyPhone,
    drawerSearchStaffs
  },
  data() {
    return {
      servingContactUserSearchTooltip: false,
      searchParkingProvidingUsersTooltip: false,
      searchStaffsTooltip: false,
      areasStaffsTooltip: false,
      companyPhonesTooltip: false,
      notificationToOneUser: false
    }
  },
  computed: {
    searchStaffs: sync('ui/staffs@isShow'),
    servingContactUserSearch: sync('ui/searchServingContactUsers@isShow'),
    searchParkingProvidingUsers: sync('ui/searchParkingProvidingUsers@isShow'),
    areasStaffs: sync('ui/areasStaffs@isShow'),
    companyPhones: sync('ui/companyPhones@isShow'),
    inProgressCallHistory: sync('biztel/inProgressCallHistory@info'),
    notificationPush: get('notifications/notificationPush@info')
  },
  methods: {
    openItem(item) {
      this[item] = true
    },
    closeItem(item) {
      this[item] = false
    },
    showTooltip(item) {
      this[item] = true
    },
    closeTooltip(item) {
      this[item] = false
    },
    async showNotificationPush() {
      let userName = '未登録'
      if (
        this.inProgressCallHistory.userId &&
        this.inProgressCallHistory.userId > 0
      ) {
        const userInfoRes = await this.$axios.get(
          `${API_USERS_URL}/${this.inProgressCallHistory.userId}`
        )
        userName = userInfoRes.data.name || '未登録'
      }
      const phoneNumber = this.$options.filters.formatPhoneNumber(
        this.inProgressCallHistory.inProgressTel
      )
      const userInfoMessage = `${this.$options.filters.convertCurrentUserType(
        this.inProgressCallHistory.userType
      )}名：${userName}\n電話番号　：${phoneNumber || '未登録'}\n`
      dispatch('notifications/notificationPush/setInfo', {
        ...this.notificationPush,
        title: '電話対応可否リクエスト送信',
        message: userInfoMessage,
        isShow: true,
        isShowSearchUser: true
      })
    }
  }
}
</script>
