<template>
  <div
    :class="{'disable-tels': disableFlag}"
    class="user-tel-edit">
    <div class="title">
      <h1 class="text">電話</h1>
    </div>
    <div class="content -elevation1">
      <div class="main-content">
        <div class="title">
          <div class="main">
            <p class="tel">電話<span class="caution -necessary">(必要)</span></p>
            <p class="name">名前</p>
            <p class="namefurigana">ふりがな</p>
            <p class="supplement">補足</p>
          </div>
          <div class="addnew">
            <button
              class="mdc-button"
              @click="addNewTelObj"><svg class="icon" />追加</button>
          </div>
        </div>
        <div class="tel-obj">
          <user-tel-edit-box
            v-for="(tel, index) in tels"
            :key="index"
            :tel="tel"
            :index="index"
            :user-id="userInfo.id"
            :tels="tels"
            @remove="removeTel"
            @update-tel="updateTel"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
import UserTelEditBox from '@/components/user-page/user-tel-edit-box'
export default {
  components: { UserTelEditBox },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {
          id: 0,
          userClass: 1,
          postcode: '',
          name: '',
          address: '',
          cautionLevel: 1,
          status: 1,
          memo: ''
        }
      }
    },
    userTels: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tels: []
    }
  },
  computed: {
    staffId: get('auth@user.id'),
    disableFlag() {
      return !this.userInfo.id
    }
  },
  watch: {
    userTels() {
      this.setTels()
    }
  },
  mounted() {
    this.setTels()
  },
  methods: {
    addNewTelObj() {
      this.tels = [
        ...this.tels,
        {
          id: 0,
          name: null,
          tels: null,
          nameFurigana: null,
          memo: null
        }
      ]
    },
    removeTel(params) {
      this.$emit('update-user-tels')
      this.tels.splice(params.index, 1)
    },
    setTels() {
      this.tels =
        this.userTels && this.userTels.length > 0
          ? this.userTels
          : [
              {
                id: 0,
                name: null,
                tel: null,
                nameFurigana: null,
                memo: null
              }
            ]

      this.tels = this.tels.slice().reverse()
    },
    updateTel() {
      this.$emit('update-user-tels')
    }
  }
}
</script>
