<template>
  <div>
    <aside
      id="serving-contact-user-search-drawer"
      class="mdc-drawer -modal -right drawer serving-contact-user-search-drawer"
      @keyup.esc="closeDrawer">
      <!-- ヘッダ -->
      <div class="header">
        <button
          class="navicon mdc-button mdc-ripple-upgraded"
          @click="closeDrawer"></button>
        <h1 class="title">問い合わせユーザー検索</h1>
      </div>
      <!-- 検索フォームと検索結果 -->
      <div 
        id="serving-contact-user-search-content"
        class="content">
        <serving-contact-user-search-form />
        <serving-contact-user-search-result :users="searchUsersResult" />
        <serving-contact-user-search-hint />
      </div>
    </aside>
    <!-- Drawerを閉じる -->
    <div
      class="mdc-drawer-scrim"
      @click="closeDrawer"></div>
  </div>
</template>

<script>
import servingContactUserSearchForm from '@/components/drawer/serving-contact-user-search-form'
import servingContactUserSearchResult from '@/components/drawer/serving-contact-user-search-result'
import servingContactUserSearchHint from '@/components/drawer/serving-contact-user-search-hint'
import { sync, get, dispatch } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'
import { DRAWER_TYPE_CONTACT_USER_SEARCH } from '@/config/search'

export default {
  components: {
    servingContactUserSearchForm,
    servingContactUserSearchResult,
    servingContactUserSearchHint
  },
  data() {
    return {
      servingContactUserSearchDrawer: {}
    }
  },
  computed: {
    isShowServingContactUserSearch: sync('ui/searchServingContactUsers@isShow'),
    searchParams: get('search/servingContactUsers@searchParams'),
    searchUsersResult: get('search/servingContactUsers@searchResult')
  },
  watch: {
    async isShowServingContactUserSearch(value) {
      this.servingContactUserSearchDrawer.open = value
      if (value) {
        dispatch('search/setCurrentDrawerType', DRAWER_TYPE_CONTACT_USER_SEARCH)
        document.body.classList.add('-notscroll')
      } else {
        document.body.classList.remove('-notscroll')
      }
    }
  },
  mounted() {
    this.servingContactUserSearchDrawer = new MDCDrawer(
      document.getElementById('serving-contact-user-search-drawer')
    )
    this.servingContactUserSearchDrawer.open = this.isShowServingContactUserSearch
  },
  methods: {
    closeDrawer() {
      this.isShowServingContactUserSearch = false
    }
  }
}
</script>
