import { make } from 'vuex-pathify'

// collection name
export const COLLECTION_PHONE_RECEPTION_REQUEST = 'phoneReceptionRequests'
export const COLLECTION_PHONE_NOTE = 'phoneNotes'

// notification trigger
export const NOTIFICATION_TRIGGER_SW = 'service-worker'
export const NOTIFICATION_TRIGGER_FCM = 'fcm'
export const NOTIFICATION_TRIGGER_URL = 'url'
export const NOTIFICATION_TRIGGER_USER_ACTION = 'user'

export const PHONE_NOTE_DETAIL_TYPE_RECEIVER = 'receiver'
export const PHONE_NOTE_DETAIL_TYPE_SENDER = 'sender'

// message
export const MESSAGE_PHONE_RECEPTION_REQUEST_ALL_REFUSED =
  '現在全員対応不可です。自分で対応してください。'
export const MESSAGE_CONFIRM_FINISH_NOTE =
  '完了にしたらリストから削除されます。完了にしますか？'
export const MESSAGE_CLIENT_BLOCKED_NOTIFICATION =
  'サイトからの通知が無効になっています。通知設定で通知を許可してください'

// number of items loaded for each page
const PAGING_ITEMS_NUMBER = 20

export const state = () => ({
  // phone reception request
  [COLLECTION_PHONE_RECEPTION_REQUEST]: [],
  phoneReceptionRequestsDocsFirestoreRef: [],
  phoneReceptionRequestsDisplayUnhandled: false,
  phoneReceptionRequestsPages: 1,
  unreadPhoneReceptionRequestCount: 0,
  unconfirmedPhoneReceptionRequestCount: 0,
  canLoadNextPhoneReceptionRequests: true,
  // received phone notes
  [COLLECTION_PHONE_NOTE]: [],
  phoneNotesDocsFirestoreRef: [],
  receivedPhoneNotesDisplayUnconfirmed: false,
  receivedPhoneNotesDisplayUnfinished: false,
  receivedPhoneNotesPages: 1,
  unreadPhoneNoteCount: 0,
  unconfirmedPhoneNoteCount: 0,
  unfinishedPhoneNoteCount: 0,
  canLoadNextReceivedPhoneNotes: true,
  // sent phone notes
  sentPhoneNotes: [],
  sentPhoneNotesDocsFirestoreRef: [],
  sentPhoneNotesDisplayUnhandled: false,
  sentPhoneNotesPages: 1,
  unreadSentPhoneNoteCount: 0,
  unhandledPhoneNoteCount: 0,
  canLoadNextSentPhoneNotes: true,
  // notification detail and notice
  notificationDetail: {
    id: null,
    type: null,
    notification: {},
    ref: null,
    attributes: {}
  },
  notificationNotice: {
    id: null,
    type: null,
    notification: {},
    ref: null
  },
  pendingPhoneReceptionRequests: [],
  isNotificationBlocked: false
})

export const mutations = {
  ...make.mutations(state)
}

export const getters = {
  unreadPhoneNoteCount: _ =>
    _.unreadPhoneNoteCount + _.unreadSentPhoneNoteCount,
  phoneReceptionRequestNotificationCount: _ =>
    _.unconfirmedPhoneReceptionRequestCount,
  phoneNoteNotificationCount: _ =>
    _.unconfirmedPhoneNoteCount +
    _.unfinishedPhoneNoteCount +
    _.unhandledPhoneNoteCount,
  hasPhoneReceptionRequestNotification: _ =>
    _.unconfirmedPhoneReceptionRequestCount > 0,
  hasPhoneNoteNotification: _ =>
    _.unconfirmedPhoneNoteCount +
      _.unfinishedPhoneNoteCount +
      _.unhandledPhoneNoteCount >
    0,
  hasReceivedPhoneNoteNotification: _ =>
    _.unconfirmedPhoneNoteCount + _.unfinishedPhoneNoteCount > 0,
  hasSentPhoneNoteNotification: _ => _.unhandledPhoneNoteCount > 0
}

export const actions = {
  ...make.actions(state),
  async setRequestIsRead(context, { requestId }) {
    const staffId = context.rootGetters['staffId']
    return await context.dispatch('updateItemInFirebase', {
      collection: COLLECTION_PHONE_RECEPTION_REQUEST,
      notificationId: requestId,
      data: {
        ['attributes.unreadIds']: this.$firebase.firestore.FieldValue.arrayRemove(
          staffId
        )
      },
      updateTime: false
    })
  },
  async setRequestIsAccepted(context, { requestId }) {
    const staffId = context.rootGetters['staffId']
    return await context.dispatch('updateItemInFirebase', {
      collection: COLLECTION_PHONE_RECEPTION_REQUEST,
      notificationId: requestId,
      data: {
        [`attributes.handlerId`]: staffId,
        ['attributes.pendingIds']: this.$firebase.firestore.FieldValue.arrayRemove(
          staffId
        ),
        ['attributes.unreadIds']: this.$firebase.firestore.FieldValue.arrayRemove(
          staffId
        )
      }
    })
  },
  async setRequestIsRefused(context, { requestId }) {
    const staffId = context.rootGetters['staffId']
    return await context.dispatch('updateItemInFirebase', {
      collection: COLLECTION_PHONE_RECEPTION_REQUEST,
      notificationId: requestId,
      data: {
        ['attributes.pendingIds']: this.$firebase.firestore.FieldValue.arrayRemove(
          staffId
        ),
        ['attributes.unreadIds']: this.$firebase.firestore.FieldValue.arrayRemove(
          staffId
        )
      }
    })
  },

  async setNoteHandler(context, { noteId }) {
    return await context.dispatch('updateItemInFirebase', {
      collection: COLLECTION_PHONE_NOTE,
      notificationId: noteId,
      data: {
        [`attributes.isHandled`]: true,
        [`attributes.notifySender`]: true,
        [`attributes.notifyReceiver`]: false
      }
    })
  },
  async setNoteIsDone(context, { noteId }) {
    return await context.dispatch('updateItemInFirebase', {
      collection: COLLECTION_PHONE_NOTE,
      notificationId: noteId,
      data: {
        [`attributes.isHandled`]: true,
        [`attributes.isDone`]: true
      }
    })
  },
  async setNoteIsRead(context, { noteId }) {
    return await context.dispatch('updateItemInFirebase', {
      collection: COLLECTION_PHONE_NOTE,
      notificationId: noteId,
      data: {
        [`attributes.notifyReceiver`]: false
      },
      updateTime: false
    })
  },
  async setSentNoteIsRead(context, { noteId }) {
    return await context.dispatch('updateItemInFirebase', {
      collection: COLLECTION_PHONE_NOTE,
      notificationId: noteId,
      data: {
        [`attributes.notifySender`]: false
      },
      updateTime: false
    })
  },
  async transferNote(
    context,
    {
      noteId,
      receiver: {
        id: receiverId,
        username: receiverName,
        extensionNumber,
        email
      }
    }
  ) {
    const staffId = context.rootGetters['staffId']

    return this.$firestore
      .doc(`${COLLECTION_PHONE_NOTE}/${noteId}`)
      .get()
      .then(snapshot => {
        const data = snapshot.data()
        context.dispatch('updateItemInFirebase', {
          collection: COLLECTION_PHONE_NOTE,
          notificationId: noteId,
          data: {
            receivers: {
              [receiverId]: {
                id: receiverId,
                username: receiverName,
                extensionNumber
              }
            },
            receiverIds: [receiverId],
            [`attributes.isHandled`]: false,
            [`attributes.notifySender`]: true,
            [`attributes.notifyReceiver`]: true,
            [`attributes.transferHistory`]: [
              ...data.attributes.transferHistory,
              staffId
            ]
          }
        })

        context.dispatch('sendMailWhenHasNote', {
          sender: {
            id: staffId,
            username: context.rootGetters['staffName'],
            email: context.rootGetters['staffEmail']
          },
          receiver: {
            id: receiverId,
            username: receiverName,
            extensionNumber,
            email
          },
          emailMessage: data.content.message
        })
      })
      .catch(error => {
        this.app.$sentry.captureException(error)
      })
  },
  sendMailWhenHasNote(context, { sender, receiver, emailMessage }) {
    this.$mailer.send({
      to: receiver.email,
      from: {
        email: sender.email,
        name: 'PAPP-伝言メモ'
      },
      subject: `${sender.username}さんから伝言メモがありました`,
      text:
        emailMessage +
        '\n------------------------------------\n' +
        '\n' +
        'PAPPの管理画面で上記伝言メモをご確認ください\n' +
        `詳細はこちら: ${window.location.protocol}//${window.location.host}`
    })
  },
  async addItemToFirebase(
    context,
    { receivers, title, content, attributes, type }
  ) {
    const senderId = context.rootGetters['staffId']
    const senderName = context.rootGetters['staffName']
    const receiverIds = receivers.map(receiver => receiver.id)
    let receiversList = {}
    receivers.forEach(receiver => {
      receiversList = {
        ...receiversList,
        [receiver.id]: receiver
      }
    })
    const newDocRef = this.$firestore.collection(type).doc()
    const newDoc = {
      id: newDocRef.id,
      sender: {
        id: senderId,
        username: senderName
      },
      receivers: receiversList,
      receiverIds,
      title,
      content,
      attributes,
      type,
      createdAt: this.$firebase.firestore.Timestamp.now(),
      updatedAt: this.$firebase.firestore.Timestamp.now()
    }
    await newDocRef.set(newDoc)
    return newDocRef.id
  },
  async createNewPhoneReceptionRequest(
    context,
    { receivers, title, content, receiverName }
  ) {
    const staffId = context.rootGetters['staffId']
    receivers = receivers.filter(receiver => receiver.id !== staffId)
    const receiverIds = receivers.map(receiver => receiver.id)
    return await context.dispatch('addItemToFirebase', {
      receivers,
      title,
      content,
      attributes: {
        timeout: false,
        elapsedTime: 0,
        handlerId: null,
        unreadIds: receiverIds,
        pendingIds: receiverIds,
        receiverName
      },
      type: COLLECTION_PHONE_RECEPTION_REQUEST
    })
  },
  async createNewPhoneNote(context, { receivers, title, content }) {
    const staffId = context.rootGetters['staffId']
    const staffMail = context.rootGetters['staffEmail']
    const staffName = context.rootGetters['staffName']
    await Promise.all([
      receivers.forEach(receiver => {
        context.dispatch('sendMailWhenHasNote', {
          sender: {
            id: staffId,
            username: staffName,
            email: staffMail
          },
          receiver: {
            id: receiver.id,
            username: receiver.username,
            extensionNumber: receiver.extensionNumber,
            email: receiver.email
          },
          emailMessage: content.message || ''
        })
      })
    ])

    return await context.dispatch('addItemToFirebase', {
      receivers,
      title,
      content,
      attributes: {
        isDone: false,
        isHandled: false,
        notifyReceiver: true,
        notifySender: false,
        transferHistory: []
      },
      type: COLLECTION_PHONE_NOTE
    })
  },
  async updateItemInFirebase(
    context,
    { collection, notificationId, data, updateTime = true }
  ) {
    let updateData = data
    if (updateTime) {
      updateData = {
        ...data,
        updatedAt: this.$firebase.firestore.Timestamp.now()
      }
    }
    return await this.$firestore
      .doc(`${collection}/${notificationId}`)
      .update(updateData)
  },
  watchPendingPhoneReceptionRequests(context) {
    let currentTime = Math.floor(Date.now() / 1000)
    const id = context.rootGetters['staffId']
    let query = this.$firestore
      .collection(COLLECTION_PHONE_RECEPTION_REQUEST)
      .orderBy('createdAt', 'desc')
      .where('sender.id', '==', id)
      .where('attributes.handlerId', '==', null)
      .where('attributes.timeout', '==', false)
      .onSnapshot(
        { includeMetadataChanges: true },
        snapshot => {
          if (snapshot.metadata.fromCache) {
            return
          }
          let docs = []
          snapshot.forEach(doc => {
            let data = doc.data()
            if (
              data.attributes.pendingIds.length > 0 &&
              currentTime - data.createdAt.seconds < 60 * 60
            ) {
              docs.push(data)
            }
          })
          // set docs to state
          context.commit('SET_PENDING_PHONE_RECEPTION_REQUESTS', docs)
        },
        error => this.app.$sentry.captureException(error)
      )
  },
  watchPhoneReceptionRequest(context) {
    const id = context.rootGetters['staffId']
    const {
      phoneReceptionRequestsDocsFirestoreRef,
      phoneReceptionRequestsDisplayUnhandled,
      phoneReceptionRequestsPages
    } = context.state

    phoneReceptionRequestsDocsFirestoreRef.forEach(unsubscribeFn =>
      unsubscribeFn()
    )

    let query = this.$firestore
      .collection(COLLECTION_PHONE_RECEPTION_REQUEST)
      .orderBy('createdAt', 'desc')
      .limit(phoneReceptionRequestsPages * PAGING_ITEMS_NUMBER)
    if (phoneReceptionRequestsDisplayUnhandled) {
      query = query
        .where('attributes.handlerId', '==', null)
        .where('attributes.timeout', '==', false)
        .where(`attributes.pendingIds`, 'array-contains', id)
    } else {
      query = query.where('receiverIds', 'array-contains', id)
    }

    const unsubscribe = query.onSnapshot(
      { includeMetadataChanges: true },
      snapshot => {
        if (snapshot.metadata.fromCache) {
          return
        }
        let docs = []
        snapshot.forEach(doc => {
          docs.push(doc.data())
        })

        // set docs to state
        context.commit('SET_PHONE_RECEPTION_REQUESTS', docs)
        context.commit('SET_PHONE_RECEPTION_REQUESTS_DOCS_FIRESTORE_REF', [
          ...phoneReceptionRequestsDocsFirestoreRef,
          unsubscribe
        ])

        // can load next batch of request
        const canLoadMore =
          docs.length >= phoneReceptionRequestsPages * PAGING_ITEMS_NUMBER
        if (canLoadMore) {
          context.commit('SET_CAN_LOAD_NEXT_PHONE_RECEPTION_REQUESTS', true)
        } else {
          context.commit('SET_CAN_LOAD_NEXT_PHONE_RECEPTION_REQUESTS', false)
        }
      },
      error => this.app.$sentry.captureException(error)
    )
  },
  watchPhoneReceptionRequestItems(context) {
    const id = context.rootGetters['staffId']
    // watch unread request number
    this.$firestore
      .collection(COLLECTION_PHONE_RECEPTION_REQUEST)
      .where('attributes.handlerId', '==', null)
      .where('attributes.timeout', '==', false)
      .where(`attributes.unreadIds`, 'array-contains', id)
      .onSnapshot(
        querySnapshot => {
          context.commit(
            'SET_UNREAD_PHONE_RECEPTION_REQUEST_COUNT',
            querySnapshot.size
          )
        },
        error => this.app.$sentry.captureException(error)
      )
    this.$firestore
      .collection(COLLECTION_PHONE_RECEPTION_REQUEST)
      .where('attributes.handlerId', '==', null)
      .where('attributes.timeout', '==', false)
      .where(`attributes.pendingIds`, 'array-contains', id)
      .onSnapshot(
        querySnapshot => {
          context.commit(
            'SET_UNCONFIRMED_PHONE_RECEPTION_REQUEST_COUNT',
            querySnapshot.size
          )
        },
        error => this.app.$sentry.captureException(error)
      )
  },
  watchReceivedPhoneNotes(context) {
    const id = context.rootGetters['staffId']
    const {
      phoneNotesDocsFirestoreRef,
      receivedPhoneNotesDisplayUnconfirmed,
      receivedPhoneNotesDisplayUnfinished,
      receivedPhoneNotesPages
    } = context.state

    phoneNotesDocsFirestoreRef.forEach(unsubscribeFn => unsubscribeFn())

    let query = this.$firestore
      .collection(COLLECTION_PHONE_NOTE)
      .orderBy('updatedAt', 'desc')
      .limit(receivedPhoneNotesPages * PAGING_ITEMS_NUMBER)
      .where('receiverIds', 'array-contains', id)
    if (receivedPhoneNotesDisplayUnconfirmed) {
      query = query
        .where('attributes.isHandled', '==', false)
        .where('attributes.isDone', '==', false)
    }
    if (receivedPhoneNotesDisplayUnfinished) {
      query = query
        .where('attributes.isHandled', '==', true)
        .where('attributes.isDone', '==', false)
    }

    const unsubscribe = query.onSnapshot(
      { includeMetadataChanges: true },
      snapshot => {
        if (snapshot.metadata.fromCache) {
          return
        }
        let docs = []
        snapshot.forEach(doc => {
          docs.push(doc.data())
        })

        // set docs to state
        context.commit('SET_PHONE_NOTES', docs)
        context.commit('SET_PHONE_NOTES_DOCS_FIRESTORE_REF', [
          ...phoneNotesDocsFirestoreRef,
          unsubscribe
        ])

        // can load next batch of notes
        const canLoadMore =
          docs.length >= receivedPhoneNotesPages * PAGING_ITEMS_NUMBER
        if (canLoadMore) {
          context.commit('SET_CAN_LOAD_NEXT_RECEIVED_PHONE_NOTES', true)
        } else {
          context.commit('SET_CAN_LOAD_NEXT_RECEIVED_PHONE_NOTES', false)
        }
      },
      error => this.app.$sentry.captureException(error)
    )
  },
  watchReceivedPhoneNoteItems(context) {
    const id = context.rootGetters['staffId']
    // watch unread request number
    this.$firestore
      .collection(COLLECTION_PHONE_NOTE)
      .where('attributes.isHandled', '==', false)
      .where('attributes.isDone', '==', false)
      .where('attributes.notifyReceiver', '==', true)
      .where('receiverIds', 'array-contains', id)
      .onSnapshot(
        querySnapshot => {
          context.commit('SET_UNREAD_PHONE_NOTE_COUNT', querySnapshot.size)
        },
        error => this.app.$sentry.captureException(error)
      )
    this.$firestore
      .collection(COLLECTION_PHONE_NOTE)
      .where('attributes.isHandled', '==', false)
      .where('attributes.isDone', '==', false)
      .where('receiverIds', 'array-contains', id)
      .onSnapshot(
        querySnapshot => {
          context.commit('SET_UNCONFIRMED_PHONE_NOTE_COUNT', querySnapshot.size)
        },
        error => this.app.$sentry.captureException(error)
      )
    this.$firestore
      .collection(COLLECTION_PHONE_NOTE)
      .where('attributes.isHandled', '==', true)
      .where('attributes.isDone', '==', false)
      .where('receiverIds', 'array-contains', id)
      .onSnapshot(
        querySnapshot => {
          context.commit('SET_UNFINISHED_PHONE_NOTE_COUNT', querySnapshot.size)
        },
        error => this.app.$sentry.captureException(error)
      )
  },
  watchSentPhoneNotes(context) {
    const id = context.rootGetters['staffId']
    const {
      sentPhoneNotesDocsFirestoreRef,
      sentPhoneNotesDisplayUnhandled,
      sentPhoneNotesPages
    } = context.state

    sentPhoneNotesDocsFirestoreRef.forEach(unsubscribeFn => unsubscribeFn())

    let query = this.$firestore
      .collection(COLLECTION_PHONE_NOTE)
      .orderBy('updatedAt', 'desc')
      .limit(sentPhoneNotesPages * PAGING_ITEMS_NUMBER)
      .where('sender.id', '==', id)
    if (sentPhoneNotesDisplayUnhandled) {
      query = query
        .where('attributes.isHandled', '==', false)
        .where('attributes.isDone', '==', false)
    }
    const unsubscribe = query.onSnapshot(
      { includeMetadataChanges: true },
      snapshot => {
        if (snapshot.metadata.fromCache) {
          return
        }
        let docs = []
        snapshot.forEach(doc => {
          docs.push(doc.data())
        })

        // set docs to state
        context.commit('SET_SENT_PHONE_NOTES', docs)
        context.commit('SET_SENT_PHONE_NOTES_DOCS_FIRESTORE_REF', [
          ...sentPhoneNotesDocsFirestoreRef,
          unsubscribe
        ])

        // can load next batch of notes
        const canLoadMore =
          docs.length >= sentPhoneNotesPages * PAGING_ITEMS_NUMBER
        if (canLoadMore) {
          context.commit('SET_CAN_LOAD_NEXT_SENT_PHONE_NOTES', true)
        } else {
          context.commit('SET_CAN_LOAD_NEXT_SENT_PHONE_NOTES', false)
        }
      },
      error => this.app.$sentry.captureException(error)
    )
  },
  watchSentPhoneNoteItems(context) {
    const id = context.rootGetters['staffId']
    // watch unread request number
    this.$firestore
      .collection(COLLECTION_PHONE_NOTE)
      .where('attributes.isHandled', '==', false)
      .where('attributes.isDone', '==', false)
      .where('attributes.notifySender', '==', true)
      .where('sender.id', '==', id)
      .onSnapshot(
        querySnapshot => {
          context.commit('SET_UNREAD_SENT_PHONE_NOTE_COUNT', querySnapshot.size)
        },
        error => this.app.$sentry.captureException(error)
      )
    // watch unhandled request number
    this.$firestore
      .collection(COLLECTION_PHONE_NOTE)
      .where('attributes.isHandled', '==', false)
      .where('attributes.isDone', '==', false)
      .where('sender.id', '==', id)
      .onSnapshot(
        querySnapshot => {
          context.commit('SET_UNHANDLED_PHONE_NOTE_COUNT', querySnapshot.size)
        },
        error => this.app.$sentry.captureException(error)
      )
  },
  watchNotifications(context) {
    context.dispatch('watchPhoneReceptionRequest')
    context.dispatch('watchPhoneReceptionRequestItems')
    context.dispatch('watchReceivedPhoneNotes')
    context.dispatch('watchReceivedPhoneNoteItems')
    context.dispatch('watchSentPhoneNotes')
    context.dispatch('watchSentPhoneNoteItems')
    context.dispatch('watchPendingPhoneReceptionRequests')
  },

  handleNotification(context, { notificationId, notificationType, from, to }) {
    // because this handleNotification is only called when the site is opening,
    // and the notification should be lagged behind for some moment,
    // so the data should be in the state already
    if (notificationId && notificationType) {
      if (notificationType === COLLECTION_PHONE_RECEPTION_REQUEST) {
        context.dispatch('handlePhoneReceptionRequestNotification', {
          notificationId,
          notificationType,
          from
        })
      } else if (notificationType === COLLECTION_PHONE_NOTE) {
        context.dispatch('handlePhoneNoteNotification', {
          notificationId,
          notificationType,
          from,
          to
        })
      }
    }
  },
  handlePhoneReceptionRequestNotification(
    context,
    { notificationId, notificationType, from }
  ) {
    const id = context.rootGetters['staffId']
    if (notificationId && notificationType) {
      this.$firestore
        .doc(`${notificationType}/${notificationId}`)
        .get()
        .then(snapshot => {
          const notification = snapshot.data()
          const isSender = notification.sender.id === id
          switch (from) {
            /**
             * trigger from fcm
             * isSender: show notice if elapsed time > 0
             * !isSender: do nothing
             */
            case NOTIFICATION_TRIGGER_FCM:
              if (isSender) {
                context.dispatch('showNotificationNotice', {
                  notificationId,
                  notificationType
                })
              }
              break
            /**
             * trigger from user action
             * isSender: show notice
             * !isSender: show notification detail
             */
            case NOTIFICATION_TRIGGER_USER_ACTION:
            /**
             * trigger from service worker
             * isSender: show notice
             * !isSender: show notification detail
             */
            case NOTIFICATION_TRIGGER_SW:
            /**
             * trigger from url (one-time-trigger)
             * isSender: show notice
             * !isSender: show detail
             */
            case NOTIFICATION_TRIGGER_URL:
              if (isSender) {
                context.dispatch('showNotificationNotice', {
                  notificationId,
                  notificationType
                })
              } else {
                context.dispatch('showNotificationDetail', {
                  notificationId,
                  notificationType
                })
              }
              break
          }
        })
        .catch(error => {
          this.app.$sentry.captureException(error)
        })
    }
  },
  handlePhoneNoteNotification(
    context,
    { notificationId, notificationType, from, to }
  ) {
    switch (from) {
      /**
       * trigger from fcm, when open and view page
       * do nothing
       */
      case NOTIFICATION_TRIGGER_FCM:
        break
      /**
       * trigger from user action
       */
      case NOTIFICATION_TRIGGER_USER_ACTION:
      /**
       * trigger from service worker when click on the chrome toast
       * show notification detail
       */
      case NOTIFICATION_TRIGGER_SW:
      /**
       * trigger from url (one-time-trigger)
       * show notification detail
       */
      case NOTIFICATION_TRIGGER_URL:
        context.dispatch('showNotificationDetail', {
          notificationId,
          notificationType,
          to
        })
        break
    }
  },
  showNotificationNotice(context, { notificationId, notificationType }) {
    if (notificationId && notificationType) {
      const ref = this.$firestore
        .doc(`${notificationType}/${notificationId}`)
        .onSnapshot(
          snapshot => {
            context.commit('SET_NOTIFICATION_NOTICE', {
              id: notificationId,
              type: notificationType,
              notification: snapshot.data(),
              ref
            })
          },
          error => this.app.$sentry.captureException(error)
        )
      this.app.store.set('ui/notificationNotice@open', true)
    }
  },
  hideNotificationNotice(context) {
    this.app.store.set('ui/notificationNotice@open', false)
    if (context.state.notificationNotice.ref) {
      context.state.notificationNotice.ref()
    }
    context.commit('SET_NOTIFICATION_NOTICE', {
      id: null,
      type: null,
      notification: {},
      ref: null
    })
  },
  showNotificationDetail(
    context,
    { notificationId, notificationType, to = '' }
  ) {
    const id = context.rootGetters['staffId']
    context.dispatch('hideNotificationDetail')
    if (notificationId && notificationType) {
      const ref = this.$firestore
        .doc(`${notificationType}/${notificationId}`)
        .onSnapshot(
          snapshot => {
            const notification = snapshot.data()
            if (notificationType === COLLECTION_PHONE_RECEPTION_REQUEST) {
              context.dispatch('setRequestIsRead', {
                requestId: notificationId
              })
            } else if (notificationType === COLLECTION_PHONE_NOTE) {
              if (notification.sender.id === id) {
                context.dispatch('setSentNoteIsRead', {
                  noteId: notificationId
                })
              }
              if (notification.receiverIds[0] === id) {
                context.dispatch('setNoteIsRead', {
                  noteId: notificationId
                })
              }
            }
            context.commit('SET_NOTIFICATION_DETAIL', {
              id: notificationId,
              type: notificationType,
              notification,
              ref,
              attributes: { to }
            })
          },
          error => this.app.$sentry.captureException(error)
        )
      this.app.store.set('ui/notificationDetail@open', true)
    }
  },
  hideNotificationDetail(context) {
    if (context.state.notificationDetail.ref) {
      context.state.notificationDetail.ref()
    }
    context.commit('SET_NOTIFICATION_DETAIL', {
      id: null,
      type: null,
      notification: {},
      ref: null,
      attributes: {}
    })
    this.app.store.set('ui/notificationDetail@open', false)
  },
  setNotificationIsBlocked(context, isBlocked = true) {
    context.commit('SET_IS_NOTIFICATION_BLOCKED', isBlocked)
  }
}
