import { make } from 'vuex-pathify'
import { API_USER_GROUP_URL } from '@/config/api-url'

export const state = () => ({
  data: {}
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),

  async getUserGroupInfo(context, userGroupId) {
    if (!userGroupId || userGroupId === -1) {
      context.commit('SET_DATA', {})
      return
    }
    try {
      const response = await this.$axios.get(
        `${API_USER_GROUP_URL}/${userGroupId}`
      )
      context.commit('SET_DATA', response.data)
    } catch (error) {
      context.commit('SET_DATA', {})
      this.$sentry.captureException(e)
    }
  }
}
