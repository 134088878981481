import { make } from 'vuex-pathify'

export const state = () => ({
  info: {
    isShow: false,
    isShowSearchUser: false,
    initSearchStaffName: '',
    title: '',
    area: '',
    staffName: '',
    message: '',
    staffs: []
  }
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state)
}
