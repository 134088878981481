<template>
  <div>
    <aside
      id="drawer-user-group-search"
      class="mdc-drawer -modal -right drawer-user-group-search"
      @keyup.esc="closeDrawer">
      <div class="header">
        <button
          class="navicon mdc-button mdc-ripple-upgraded"
          @click="closeDrawer"></button>
        <h1 class="title">ユーザーグループ検索</h1>
      </div>
      <div class="content">
        <drawer-user-group-search-form />
        <drawer-user-group-search-result />
      </div>
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="closeDrawer"></div>
  </div>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'
import drawerUserGroupSearchForm from '@/components/user-group-page/drawer-user-group-search-form'
import drawerUserGroupSearchResult from '@/components/user-group-page/drawer-user-group-search-result'

export default {
  components: {
    drawerUserGroupSearchForm,
    drawerUserGroupSearchResult
  },
  data() {
    return {
      drawer: {}
    }
  },
  computed: {
    groupName: get('userGroup/info@name'),
    isDrawerOpen: sync('ui/userGroupPage@drawer.isSearchUserGroupOpened')
  },
  watch: {
    isDrawerOpen(value) {
      this.drawer.open = value
      value
        ? document.body.classList.add('-notscroll')
        : document.body.classList.remove('-notscroll')
    }
  },
  mounted() {
    this.drawer = new MDCDrawer(
      document.getElementById('drawer-user-group-search')
    )
    this.drawer.open = this.isDrawerOpen
  },
  methods: {
    closeDrawer() {
      this.isDrawerOpen = false
    }
  }
}
</script>
