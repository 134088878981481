import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

const INITIAL_SEARCH_RESULT = {
  list: [],
  totalCount: 0,
  nextPage: '',
  errorMessage: ''
}

export const state = () => ({
  searchParams: {
    id: '',
    name: '',
    tel: '',
    email: ''
  },
  searchResult: INITIAL_SEARCH_RESULT,
  lastSearchResult: null
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async fetchUsers(context) {
    try {
      if (context.state.searchParams.staffId === 0) {
        context.commit('SET_SEARCH_RESULT', INITIAL_SEARCH_RESULT)
        return
      }
      const searchParams = Object.keys(context.state.searchParams).reduce(
        (accumulate, current) => {
          if (current === 'id') {
            const parsedId = parseInt(
              this.$helpers.zen2Han(context.state.searchParams[current])
            )
            return parsedId
              ? { ...accumulate, [current]: parsedId }
              : accumulate
          }
          return context.state.searchParams[current]
            ? {
                ...accumulate,
                [current]:
                  current === 'tel'
                    ? this.$helpers
                        .zen2Han(context.state.searchParams[current])
                        .replace(/-/g, '')
                    : context.state.searchParams[current]
              }
            : accumulate
        },
        {}
      )
      const resPromise = this.$axios.get(`${API_USERS_URL}`, {
        params: {
          ...searchParams,
          statuses: [1] // only get the active users
        }
      })
      context.commit('SET_LAST_SEARCH_RESULT', resPromise)
      resPromise
        .then(res => {
          if (resPromise !== context.state.lastSearchResult) {
            return
          }
          if (!res.data || res.data.length === 0) {
            context.commit('SET_SEARCH_RESULT', INITIAL_SEARCH_RESULT)
          } else {
            context.commit('SET_SEARCH_RESULT', {
              list: res.data,
              totalCount: res.headers['x-total-count'],
              nextPage: this.app.$helpers.parseLinkHeader(res.headers['link'])
                .next,
              errorMessage: ''
            })
          }
        })
        .catch(err => {
          throw err
        })
    } catch (error) {
      if (error.response && error.response.status === 400) {
        context.commit('SET_SEARCH_RESULT', {
          ...INITIAL_SEARCH_RESULT,
          errorMessage: '入力内容に誤りがございます。ご確認ください。'
        })
        return
      }

      context.commit('SET_SEARCH_RESULT', {
        ...INITIAL_SEARCH_RESULT,
        errorMessage:
          'エラーが発生しました。しばらくしてからもう一度お試しください。'
      })
    }
  },
  async updateUserInSearchResult(context, { userId, userGroupId }) {
    context.commit('SET_SEARCH_RESULT', {
      ...context.state.searchResult,
      list: context.state.searchResult.list.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            userGroupId
          }
        }
        return user
      })
    })
  },
  async getMoreUsers(context) {
    try {
      if (!context.state.searchResult.nextPage) {
        return
      }
      const res = await this.$axios.get(context.state.searchResult.nextPage)
      context.commit('SET_SEARCH_RESULT', {
        list: [...context.state.searchResult.list, ...res.data],
        totalCount: res.headers['x-total-count'],
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
