/**
 * Below functions clone from carparking-constants
 * https://github.com/azoom/carparking-constants/blob/main/src/util.ts#L50
 */
export function generateConst(registry) {
  if (_isValued(registry)) {
    const keyValue = Object.entries(registry).reduce((o, [k, v]) => {
      o[k] = v.value
      return o
    }, {})

    const entries = Object.entries(registry).map(([key, values]) => {
      return { key, ...values }
    })

    const keyOf = value => {
      const kv = Object.entries(registry).find(x => x[1].value === value)
      return kv ? kv[0] : ''
    }
    const labelOf = _isLabeled(registry)
      ? _findPropertyValue(registry, 'label')
      : undefined

    return {
      ...keyValue,
      keyOf,
      labelOf,
      entries
    }
  }
}

function _hasProperty(registry, name) {
  return Object.values(registry).filter(x => x[name] === undefined).length === 0
}

function _findPropertyValue(registry, name) {
  return value => {
    const kv = Object.entries(registry).find(x => x[1].value === value)
    return kv ? kv[1][name] : ''
  }
}

function _isValued(registry) {
  return _hasProperty(registry, 'value')
}

function _isLabeled(registry) {
  return _hasProperty(registry, 'label')
}
