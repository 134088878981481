import {
  COLLECTION_PHONE_RECEPTION_REQUEST,
  COLLECTION_PHONE_NOTE,
  NOTIFICATION_TRIGGER_SW,
  NOTIFICATION_TRIGGER_FCM,
  NOTIFICATION_TRIGGER_URL
} from '@/store/notifications'

const LOCAL_STORAGE_ID_CURRENT_LOGIN_ID = 'current_login_id'
const LOCAL_STORAGE_ID_CURRENT_LOGIN_TOKEN = 'current_login_token'
const CALL_END_TYPE = 'call_end'
const TRANSFER_CALL_END = 'transfer_call_end'

// specify how to handle incoming push when site is in foreground
const handleIncomingPush = async context => {
  const registration = await navigator.serviceWorker.register(
    `/sw.js?messageid=${process.env.FIREBASE_MESSAGING_SENDER_ID}`
  )
  registration.update()
  await context.app.$messaging.useServiceWorker(registration)

  /**
   * listen from service worker
   * when staff clicks on chrome notification,
   * sw will send a message to opening tab or tab with same url
   *
   * focus on opening tab or tab with same url
   * do nothing for sender
   * show detail notification for receiver
   */
  navigator.serviceWorker.onmessage = function(event) {
    const { notificationId, type, to = '' } = event.data
    if (notificationId && type) {
      context.store.dispatch('notifications/handleNotification', {
        notificationId,
        notificationType: type,
        from: NOTIFICATION_TRIGGER_SW,
        to
      })
    }
  }

  /**
   * listen from fcm
   * will listen only if opening a page.
   *
   * show the chrome toast notification (sender and receiver)
   * show the reminder for sender
   */
  context.app.$messaging.onMessage(payload => {
    if (payload.data.type) {
      const inProgressCallHistory = context.store.get(
        'biztel/inProgressCallHistory@info'
      )
      const staffId = context.store.getters['staffId']

      switch (payload.data.type) {
        case CALL_END_TYPE:
          if (
            parseInt(inProgressCallHistory.biztelCallId) !==
              parseInt(payload.data.callId) ||
            parseInt(staffId) !== parseInt(payload.data.staffId)
          ) {
            return
          }
          context.store.commit('ui/SET_CALL_END_DIALOG', {
            isShow: true,
            endCall: true,
            transferEndCall: false,
            isFinishedCall: payload.data.isFinishedCall === '1'
          })
          break
        case TRANSFER_CALL_END:
          if (
            parseInt(inProgressCallHistory.biztelCallId) !==
              parseInt(payload.data.callId) ||
            parseInt(staffId) !== parseInt(payload.data.staffId)
          ) {
            return
          }
          context.store.commit('ui/SET_CALL_END_DIALOG', {
            isShow: false,
            endCall: false,
            transferEndCall: true
          })
          break
        default:
          const { title, link, body, ...options } = payload.data
          registration.showNotification(title, {
            ...options,
            body: body.replace(/\<a.*\<\/a\>/g, '')
          })
          context.store.dispatch('notifications/handleNotification', {
            notificationId: payload.data.notificationId,
            notificationType: payload.data.type,
            from: NOTIFICATION_TRIGGER_FCM,
            to: payload.data.to
          })
          break
      }
    }
  })

  /**
   * watch the url to show detail notification
   * if the url contains notificationId and type,
   * show the notificationDetail for receiver
   * show reminder for sender
   */
  const urlParams = new URL(document.location).searchParams
  const notificationId = urlParams.get('notificationId')
  const notificationType = urlParams.get('type')
  const to = urlParams.get('to')
  if (notificationId && notificationType) {
    context.store.dispatch('notifications/handleNotification', {
      notificationId,
      notificationType,
      from: NOTIFICATION_TRIGGER_URL,
      to
    })
  }
  await registerDevice(context)
}

// check whether the current browser support push or not
const canPush = () => 'serviceWorker' in navigator && 'PushManager' in window

const registerDevice = context => {
  return new Promise(async (resolve, reject) => {
    const staffId = context.store.getters['staffId']
    if (staffId) {
      try {
        const oldToken = getCurrentLoggingInStaffMessagingToken()
        const currentToken = await getToken(context)
        // if no token save in ls, then it means this is the first time the user log into the system, or they just clear all the cache
        if (
          canPush() &&
          currentToken &&
          (`${staffId}` !== getCurrentLoggingInStaffId() ||
            !oldToken ||
            oldToken !== currentToken)
        ) {
          try {
            // remove old token
            try {
              // unregister the token in fcm
              if (oldToken) await context.app.$messaging.deleteToken(oldToken)
            } catch (error) {
              // if the token not found in fcm then ignore the error
              if (error.code !== 'messaging/delete-token-not-found') {
                throw error
              }
            }

            const token = await getToken(context)
            if (token) {
              await subscribeDevice(context)(staffId, token, oldToken)
              await saveCurrentDevicePushInfo(staffId, token)
            } else {
              console.log('client blocked notifications')
            }
            resolve()
          } catch (error) {
            console.log('cannot register this device to get notifications')
            reject(error)
          }
        }
      } catch (error) {
        context.app.$sentry.captureException(error)
      }
    }
    resolve()
  })
}
// save the credentials to local storage
const saveCurrentDevicePushInfo = (id, token) => {
  localStorage.removeItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_ID)
  localStorage.removeItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_TOKEN)
  localStorage.setItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_ID, id)
  localStorage.setItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_TOKEN, token)
}
// get currently logging staff id
const getCurrentLoggingInStaffId = () => {
  return localStorage.getItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_ID)
}
// get currently logging staff messaging token
const getCurrentLoggingInStaffMessagingToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_TOKEN)
}
const clearCurrentDevicePushInfo = context => {
  return async () => {
    const currentToken = getCurrentLoggingInStaffMessagingToken()
    try {
      // unregister the token in fcm
      if (currentToken) await context.app.$messaging.deleteToken(currentToken)
    } catch (error) {
      // if the token not found in fcm then ignore the error
      if (error.code !== 'messaging/delete-token-not-found') {
        throw error
      }
    }
    localStorage.removeItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_ID)
    localStorage.removeItem(LOCAL_STORAGE_ID_CURRENT_LOGIN_TOKEN)
  }
}
// save token to firestore
const subscribeDevice = context => {
  return (staffId, newToken, oldToken) => {
    context.app.$firestore
      .collection('tokens')
      .where('staffId', '==', staffId)
      .get()
      .then(tokenRecords => {
        if (tokenRecords.empty) {
          // create new doc here
          const newDocRef = context.app.$firestore.collection('tokens').doc()
          const newDoc = {
            id: newDocRef.id,
            staffId,
            deviceTokens: [newToken]
          }
          newDocRef.set(newDoc).catch(error => {
            context.app.$sentry.captureException(error)
          })
        } else {
          // update token here
          const staffTokenObj = tokenRecords.docs.pop()
          const deviceTokens = staffTokenObj.data().deviceTokens
          staffTokenObj.ref.update({
            deviceTokens: [
              ...deviceTokens.filter(
                token => token !== oldToken && token !== newToken
              ),
              newToken
            ]
          })
        }
      })
      .catch(error => {
        context.app.$sentry.captureException(error)
      })
  }
}
// ask for permission and get the token
const getToken = context => {
  return new Promise(async (resolve, reject) => {
    if (canPush()) {
      try {
        await context.app.$messaging.requestPermission()
        const token = await context.app.$messaging.getToken()
        resolve(token)
      } catch (error) {
        if (
          error.code === 'messaging/permission-blocked' ||
          error.code === 'messaging/permission-default'
        ) {
          context.store.dispatch('notifications/setNotificationIsBlocked')
          resolve(false)
        }
        reject(error)
      }
    }
    reject('cannot push as the browser does not support push notification')
  })
}

export default (context, inject) => {
  if (canPush()) {
    handleIncomingPush(context)
  }
  inject('pushNotification', {
    clearCurrentDevicePushInfo: clearCurrentDevicePushInfo(context)
  })
}
