import Vue from 'vue'
export default (context, inject) => {
  if (process.env.NODE_ENV === 'development' || !context.app.$sentry) {
    const sentryDev = {
      captureException: error => console.error(error),
      captureMessage: message => console.log(message),
      configureScope: () => {}
    }
    context.$sentry = sentryDev
    context.app.$sentry = sentryDev
    inject('sentry', sentryDev)
  }

  const staffId = context.store.getters['staffId']
  context.app.$sentry.configureScope(scope => {
    if (staffId) {
      scope.setUser({ id: staffId })
    }
  })
  Vue.config.errorHandler = error => {
    context.app.$sentry.captureException(error)
  }
  window.onerror = (msg, url, line, col, error) => {
    context.app.$sentry.captureException(error)
  }
}
