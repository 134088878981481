<template>
  <div>
    <aside
      id="user-add"
      ref="userAdd"
      class="mdc-drawer -modal -right drawer"
      @keyup.esc="closeDrawer">
      <div
        class="staffs-search">
        <div class="mdc-top-app-bar mdc-top-app-bar-fixed -x-section">
          <div class="row">
            <section
              class="section -alignstart">
              <button
                class="navicon"
                @click="closeDrawer"></button>
              <h1 class="title">{{ userAddDrawerTitle }}情報新規登録</h1>
            </section>
            <section>
              <button
                class="mdc-button add-user-header"
                @click="resetForm">
                <span class="icon"></span>
                <span class="text">新規{{ userAddDrawerTitle }}追加</span>
              </button>
            </section>
          </div>
        </div>
        <div
          id="user-add-page-content"
          ref="userAddPageContent"
          class="user-add-page-content content">
          <user-info-edit
            ref="userUpdate"
            :user-info="userInfo"
            :show-detail-redirect="true"
            @create-user-info="updateUserInfo"
            @update-user-info="updateUserInfo"/>
          <user-tel-edit
            :user-info="userInfo"
            :user-tels="userTels"/>
          <user-email-edit
            :user-info="userInfo"
            :user-emails="userEmails"/>
          <user-fax-edit
            :user-info="userInfo"
            :user-faxes="userFaxes"/>
          <user-parking-source-checked-edit
            :user-info="userInfo"
            :user-parking-source-checkeds="userParkingSourceCheckeds"/>
        </div>
      </div>
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="onClickOverlay"></div>
  </div>
</template>

<script>
import userInfoEdit from '@/components/user-page/user-info-edit'
import userEmailEdit from '@/components/user-page/user-email-edit'
import userTelEdit from '@/components/user-page/user-tel-edit'
import userFaxEdit from '@/components/user-page/user-fax-edit'
import userParkingSourceCheckedEdit from '@/components/user-page/user-parking-source-checked-edit'
import { sync, call, get, dispatch } from 'vuex-pathify'
import { MDCDrawer } from '@rsmdc/drawer'

export default {
  components: {
    userInfoEdit,
    userTelEdit,
    userEmailEdit,
    userFaxEdit,
    userParkingSourceCheckedEdit
  },
  data() {
    return {
      userInfo: {
        id: 0,
        userClass: 1,
        userType: '-1',
        postcode: '',
        name: '',
        address: '',
        cautionLevel: 1,
        status: 1,
        memo: ''
      },
      userTels: [],
      userEmails: [],
      userFaxes: [],
      userParkingSourceCheckeds: []
    }
  },
  computed: {
    userSearch: sync('ui/searchUsers'),
    userAdd: sync('ui/userAddPage'),
    curViewUser: get('user/info@data'),
    curUserTels: get('user/tels@list'),
    isAddTels: sync('ui/addTels@open'),
    isAddMails: sync('ui/addMails@open'),
    isAddFaxes: sync('ui/addFaxes@open'),
    inProgressCallHistory: sync('biztel/inProgressCallHistory@info'),
    isUserAddPageOpen: sync('ui/userAddPage@open'),
    isBiztelPage() {
      // Biztelページをチェックする
      return this.$route.path.includes('biztel')
    },
    userAddDrawerTitle() {
      // Biztelページの場合
      if (this.isBiztelPage) {
        return `${this.$options.filters.convertCurrentUserType(
          this.inProgressCallHistory.userType
        )}`
      }
      // Biztelページではないの場合
      if (this.userAdd.type === 'provider') {
        // 管理会社・オーナー新規登録の場合
        return '管理会社・オーナー'
      }
      // 問い合わせユーザー新規登録の場合
      return '問い合わせユーザー'
    }
  },
  watch: {
    userAdd(value) {
      this.userAddDrawer.open = value.open
      if (value.open) {
        this.$refs.userAddPageContent.scrollTop = 0
      }
    },
    isUserAddPageOpen(value) {
      if (!value) {
        this.resetForm()
      }
    }
  },
  mounted() {
    this.userAddDrawer = new MDCDrawer(this.$refs.userAdd)
  },
  methods: {
    getUserInfoById: call('user/info/getUserInfoById'),
    async updateUserInfo(userInfo) {
      this.userInfo = userInfo
      if (this.curViewUser.id === userInfo.id) {
        await this.getUserInfoById(userInfo.id)
      }
    },
    closeDrawer() {
      dispatch('ui/setUserAddPage', { open: false, type: '' })
    },
    onClickOverlay() {
      dispatch('ui/setUserAddPage', { open: false, type: '' })
    },
    // 新作成用
    async resetForm() {
      this.userInfo = {
        id: 0,
        userClass: 1,
        userType: '-1',
        postcode: '',
        name: '',
        address: '',
        cautionLevel: 1,
        status: 1,
        memo: ''
      }
      this.userTels = []
      this.userEmails = []
      this.userFaxes = []
      this.userParkingSourceCheckeds = []
    }
  }
}
</script>
