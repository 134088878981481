<template>
  <div 
    class="serving-contact-user-search-form">
    <div class="title">
      <h1 class="text">検索条件</h1>
    </div>
    <div class="mdc-layout-grid content">
      <div class="inner">
        <div class="cell -span6desktop -span8tablet -span4mobile serving-contact-user-search-form-input">
          <span class="label">法人/個人</span>
          <div class="checkbox -userclasses">
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="serving-contact-user-classes-checkbox-1"
                v-model.number="userClasses"
                type="checkbox"
                value="1"
                class="nativecontrol">
            </div>
            <label 
              class="checkboxlabel"
              for="serving-contact-user-classes-checkbox-1">法人</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="serving-contact-user-classes-checkbox-2"
                v-model.number="userClasses"
                type="checkbox"
                value="2"
                class="nativecontrol">
            </div>
            <label 
              class="checkboxlabel" 
              for="serving-contact-user-classes-checkbox-2">個人</label>
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile serving-contact-user-search-form-input">
          <span 
            class="label" 
            v-html="nameLabel"></span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="name"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile serving-contact-user-search-form-input">
          <span class="label">TEL</span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="tel"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile serving-contact-user-search-form-input">
          <span class="label">MAIL</span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="email"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile serving-contact-user-search-form-input">
          <span class="label">問い合わせID</span>
          <div class="mdc-text-field -ripple forminput">
            <input
              v-model.trim="contactId"
              type="text"
              class="input"
              @keyup.enter="searchUsers">
          </div>
        </div>
        <div class="cell -span6desktop -span8tablet -span4mobile serving-contact-user-search-form-input">
          <span class="label">Sansanデータ</span>
          <div class="checkbox -sansanstatuses">
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="serving-contact-user-sansan-statuses-checkbox-1"
                v-model.number="sansanStatuses"
                type="checkbox"
                value="1"
                class="nativecontrol">
            </div>
            <label 
              class="checkboxlabel" 
              for="serving-contact-user-sansan-statuses-checkbox-1">有り</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="serving-contact-user-sansan-statuses-checkbox-2"
                v-model.number="sansanStatuses"
                type="checkbox"
                value="0"
                class="nativecontrol">
            </div>
            <label 
              class="checkboxlabel" 
              for="serving-contact-user-sansan-statuses-checkbox-2">無し</label>
          </div>
        </div>
        <div class="cell -span12desktop -span12tablet -span12mobile serving-contact-user-search-form-input">
          <span class="label">状態</span>
          <div class="checkbox -userstatuses">
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="serving-contact-user-statuses-checkbox-1"
                v-model.number="statuses"
                type="checkbox"
                value="1"
                class="nativecontrol">
            </div>
            <label 
              class="checkboxlabel" 
              for="serving-contact-user-statuses-checkbox-1">有効</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="serving-contact-user-statuses-checkbox-2"
                v-model.number="statuses"
                type="checkbox"
                value="0"
                class="nativecontrol">
            </div>
            <label 
              class="checkboxlabel" 
              for="serving-contact-user-statuses-checkbox-2">無効</label>
            <div class="mdc-checkbox -unconfirmed -notlast">
              <input
                id="serving-contact-user-statuses-checkbox-3"
                v-model.number="statuses"
                type="checkbox"
                value="2"
                class="nativecontrol">
            </div>
            <label for="serving-contact-user-statuses-checkbox-3">名寄せ</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync, dispatch, call } from 'vuex-pathify'
import { userConstants } from '@/config/user'
import { paginationConstants } from '@/config/pagination'

export default {
  computed: {
    searchParams: get('search/servingContactUsers@searchParams'),
    searchUsersResult: get('search/servingContactUsers@searchResult'),
    isShowSearchHintUsers: sync('ui/searchServingContactHintUsers@isShow'),
    nameLabel() {
      if (
        this.userClasses.length === 1 &&
        this.userClasses[0] === userConstants.userClasses.CORPORATION
      ) {
        return '法人名'
      }
      if (
        this.userClasses.length === 1 &&
        this.userClasses[0] === userConstants.userClasses.INDIVIDUAL
      ) {
        return '個人名'
      }
      return '法人名<br/>個人名'
    },
    name: {
      get() {
        return this.searchParams.name
      },
      set(value) {
        dispatch('search/servingContactUsers/setSearchParams', {
          ...this.searchParams,
          name: value
        })
      }
    },
    tel: {
      get() {
        return this.searchParams.tel
      },
      set(value) {
        dispatch('search/servingContactUsers/setSearchParams', {
          ...this.searchParams,
          tel: value
        })
      }
    },
    email: {
      get() {
        return this.searchParams.email
      },
      set(value) {
        dispatch('search/servingContactUsers/setSearchParams', {
          ...this.searchParams,
          email: this.$helpers.zen2Han(value)
        })
      }
    },
    contactId: {
      get() {
        return this.searchParams.contactId
      },
      set(value) {
        dispatch('search/servingContactUsers/setSearchParams', {
          ...this.searchParams,
          contactId: value
        })
      }
    },
    userClasses: {
      get() {
        return this.searchParams.userClasses
      },
      set(value) {
        dispatch('search/servingContactUsers/setSearchParams', {
          ...this.searchParams,
          userClasses: value
        })
      }
    },
    sansanStatuses: {
      get() {
        return this.searchParams.sansanStatuses
      },
      set(value) {
        dispatch('search/servingContactUsers/setSearchParams', {
          ...this.searchParams,
          sansanStatuses: value
        })
      }
    },
    statuses: {
      get() {
        return this.searchParams.statuses
      },
      set(value) {
        dispatch('search/servingContactUsers/setSearchParams', {
          ...this.searchParams,
          statuses: value
        })
      }
    }
  },
  watch: {
    userClasses(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.searchUsers()
      }
    },
    sansanStatuses(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.searchUsers()
      }
    },
    statuses(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.searchUsers()
      }
    }
  },
  mounted() {
    this.searchUsers()
  },
  methods: {
    searchUsers() {
      // ユーザー検索属性を設定する
      dispatch('search/servingContactUsers/setSearchParams', {
        ...this.searchParams,
        searchHintUser: false,
        page: paginationConstants.userSearchDrawer.DEFAULT_PAGE,
        pageSize: paginationConstants.userSearchDrawer.DEFAULT_PAGE_SIZE
      })
      // Storeのユーザー検索メソッドを呼び出す
      dispatch('search/servingContactUsers/getUsers')
    }
  }
}
</script>
