<template>
  <div
    v-show="showProgress"
    class="progress-overlay">
    <div class="indeterminate_progress">
      <svg class="circular">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="5"
          stroke-miterlimit="10"/>
      </svg>
    </div>
  </div>
</template>
<script>
import { get } from 'vuex-pathify'
export default {
  data() {
    return {
      showProgress: true
    }
  },
  computed: {
    showIndeterminateProgress: get('ui/indeterminateProgress@isShow')
  },
  watch: {
    showIndeterminateProgress() {
      this.showProgress = this.showIndeterminateProgress
    }
  },
  mounted() {
    this.showProgress = this.showIndeterminateProgress
  }
}
</script>
