<template>
  <div class="user-info-status">
    <span 
      v-show="user.hasSansan === 1 && isShowSansanStatus"
      class="item sansan">
      <span class="icon"></span>
      <span class="text">Sansan有</span>
    </span>
    <span
      v-show="user.hasClaim"
      class="item claim">クレーム歴あり</span>
    <span
      v-show="showCautionTitle"
      :class="cautionClassName"
      class="item caution">{{ cautionTitle }}</span>
    <span
      v-show="user.subleaseType >= 1"
      class="item sublease">{{ subleaseName }}</span>
    <span
      v-show="user.servingContactsCount > 0 && isShowServingContactStatus"
      class="item in-process-contact">進行中案件有り</span>
    <span
      v-show="user.managingParkingCount > 0 && isShowManagingParkingStatus && inProgressCallHistory.userType === 'management'"
      class="item parking-slot">管理物件有り</span>
    <span
      v-show="user.owningParkingCount > 0 && isShowOwningParkingStatus && inProgressCallHistory.userType === 'owner'"
      class="item parking-slot">オーナー物件有り</span>
    <span
      v-show="user.otherBranchsCount > 0 && isShowBranchStatus"
      class="item other-branch-status">他の支店有り</span>
    <span
      v-show="isShowComplianceCheckedStatus && user.complianceCheckeds.length"
      class="item anti-social-status">反社チェック済み</span>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export default {
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
    isShowBranchStatus: {
      type: Boolean,
      default: () => false
    },
    isShowServingContactStatus: {
      type: Boolean,
      default: () => false
    },
    isShowManagingParkingStatus: {
      type: Boolean,
      default: () => false
    },
    isShowOwningParkingStatus: {
      type: Boolean,
      default: () => false
    },
    isShowSansanStatus: {
      type: Boolean,
      default: () => false
    },
    isShowComplianceCheckedStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isUserEditPageOpen: sync('ui/userEditPage@open'),
    inProgressCallHistory: sync('biztel/inProgressCallHistory@info'),
    isShowOtherBranchDialog: sync('ui/branchListDialog@isShow'),
    cautionTitle() {
      switch (this.user.cautionLevel) {
        case 2:
          return '注意'
        case 3:
          return '要注意'
        case 4:
          return '取引不可'
        default:
          return ''
      }
    },
    showCautionTitle() {
      return this.cautionTitle
    },
    cautionClassName() {
      switch (this.user.cautionLevel) {
        case 2:
          return { '-yellow': true }
        case 3:
          return { '-red': true }
        case 4:
          return { '-grey': true }
        default:
          return { '-grey': true }
      }
    },
    subleaseName() {
      switch (this.user.subleaseType) {
        case 1:
          return '直営利用中'
        case 2:
          return '過去直営利用歴あり'
        default:
          return ''
      }
    }
  }
}
</script>
