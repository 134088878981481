import Vue from 'vue'
import PhoneNumber from 'awesome-phonenumber'

Vue.filter('userStatusStr', status => {
  switch (status) {
    case 0:
      return '無効なユーザー'
    case 1:
      return '有効'
    case 2:
      return '名寄せ済'
  }
})

Vue.filter('userClassToString', userClass => {
  switch (parseInt(userClass)) {
    case -1:
      return '不明'
    case 1:
      return '法人'
    case 2:
      return '個人'
    default:
      return '不明'
  }
})

Vue.filter('convertUserClass', userClass => {
  switch (userClass) {
    case 1:
      return '-corporation'
    case 2:
      return '-person'
    default:
      return '-default'
  }
})

Vue.filter('formatPhoneNumber', (number, isPhoneBar = false) => {
  if (!number || number === '0') {
    return isPhoneBar ? '000-0000-0000' : ''
  }
  let pn = new PhoneNumber(number, 'JP')
  if (!pn.isValid() || !pn.isPossible() || pn.getType === 'unknown') {
    return number
  }
  return pn.getNumber('national')
})

Vue.filter('parkingStatusToString', status => {
  switch (parseInt(status)) {
    case -20:
      return '掲載依頼'
    case -1:
      return '携帯から登録'
    case 0:
      return '非掲載'
    case 1:
      return '掲載'
    case 2:
      return '月極駐車場として掲載'
    case -4:
      return '掲載拒否'
    case -2:
      return '廃止'
    case -3:
      return '被り物件'
    case -5:
      return '時間貸しとして運営'
    case 51:
      return '他社直営物件'
    default:
      return ''
  }
})

Vue.filter('facilities', value => {
  switch (parseInt(value)) {
    case 0:
      return '不明'
    case 1:
      return '平面（砂利)'
    case 2:
      return '平面（アスファルト）'
    case 6:
      return '平面（コンクリート）'
    case 3:
      return '平面（ガレージ）'
    case 4:
      return '平面（カーポート）'
    case 5:
      return '平面（コンテナ）'
    case 11:
      return '機械（多段式）'
    case 12:
      return '機械（地下スライド）'
    case 13:
      return '機械（メリーゴーランド）'
    case 14:
      return '機械（エレベーター式）'
    case 15:
      return '機械（パズル式）'
    case 21:
      return '自走（ビルイン）'
    case 22:
      return '自走（立体駐車場）'
    case 23:
      return '自走（カーリフト）'
    default:
      return ''
  }
})

Vue.filter('convertCurrentUserType', (userType, isShowCompanyText = true) => {
  if (userType === 'management') {
    if (isShowCompanyText) {
      return '管理会社'
    }
    return '管理'
  }
  if (userType === 'owner') {
    return 'オーナー'
  }
  return 'ユーザー'
})

// 着信履歴に対応中ユーザー種別を設定する
Vue.filter('getUserTypeInProgressCallHistory', user => {
  if (user.managingParkingCount && user.managingParkingCount > 0) {
    return 'management'
  }
  if (user.owningParkingCount && user.owningParkingCount > 0) {
    return 'owner'
  }
  return 'customer'
})
