import { make } from 'vuex-pathify'
import { API_PARKS_URL } from '@/config/api-url'

export const state = () => ({
  list: [],
  totalCount: 0,
  nextPage: '',
  errorMessage: ''
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),

  async getParking(context, params = {}) {
    try {
      const result = await this.$axios.get(`${API_PARKS_URL}`, {
        params: {
          page: 1,
          pageSize: 5,
          ...params
        }
      })
      context.commit('SET_LIST', result.data)
      context.commit('SET_TOTAL_COUNT', result.headers['x-total-count'] || 0)
      context.commit(
        'SET_NEXT_PAGE',
        this.app.$helpers.parseLinkHeader(result.headers['link']).next || ''
      )
      context.commit('SET_ERROR_MESSAGE', '')
    } catch (error) {
      context.commit('SET_LIST', [])
      context.commit('SET_TOTAL_COUNT', 0)
      context.commit('SET_NEXT_PAGE', '')
      context.commit('SET_ERROR_MESSAGE', 'エラーが発生しました')
      this.$sentry.captureException(error)
    }
  },

  async getMoreParking(context) {
    try {
      if (!context.state.nextPage) {
        return
      }
      const result = await this.$axios.get(context.state.nextPage)
      context.commit('SET_LIST', [...context.state.list, ...result.data])
      context.commit('SET_TOTAL_COUNT', result.headers['x-total-count'] || 0)
      context.commit(
        'SET_NEXT_PAGE',
        this.app.$helpers.parseLinkHeader(result.headers['link']).next || ''
      )
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}
