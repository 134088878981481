/**
 *
 * @param context
 * @param msg
 * {
 * to: 'test@example.com',
 * from: 'test@example.com',
 * subject: 'Sending with SendGrid is Fun',
 * text: 'and easy to do anywhere, even with Node.js',
 * html: '<strong>and easy to do anywhere, even with Node.js</strong>',
 * }
 */
async function sendMail(context, msg) {
  try {
    if (process.env.NODE_ENV === 'production') {
      await context.$axios.post(
        `${window.location.protocol}//${window.location.host}/mailer/send`,
        msg
      )
    }
  } catch (e) {
    this.$sentry.captureException(e)
  }
}

export default (context, inject) => {
  inject('mailer', {
    send: msg => {
      return sendMail(context, msg)
    }
  })
}
