<template>
  <div class="copy-button">
    <button
      class="mdc-icon-button -copy"
      @click="buttonOnClick"
      @mouseenter="showTooltip"
      @mouseleave="closeTooltip">
      <img
        :src="img"
        class="img">
    </button>
    <span
      :class="{'-show': isShowTooltip}"
      class="tooltip -top">{{ copyMessage }}</span>
  </div>
</template>
<script>
export default {
  props: {
    elId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      img: require('../assets/svg/baseline-filter_none-24px.svg'),
      copyMessage: 'コピー済',
      isShowTooltip: false
    }
  },
  methods: {
    buttonOnClick() {
      const range = document.createRange()
      range.selectNode(document.getElementById(this.elId))
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      this.copyMessage = 'コピー済'
      this.isShowTooltip = true
      setTimeout(() => {
        this.isShowTooltip = false
        window.getSelection().removeAllRanges()
      }, 700)
    },
    showTooltip() {
      this.copyMessage = 'コピー'
      this.isShowTooltip = true
    },
    closeTooltip() {
      this.isShowTooltip = false
    }
  }
}
</script>
