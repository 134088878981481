import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({
  list: []
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async createUserTel(context, params = {}) {
    try {
      const createTelObj = await this.$axios.post(
        `${API_USERS_URL}/${params.userId}/tels`,
        {
          tel: params.tel || '',
          name: params.name || '',
          nameFurigana: params.nameFurigana || '',
          memo: params.memo || '',
          staffId: params.staffId || 0
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: '電話番号を保存しました'
      })
      return createTelObj
    } catch (e) {
      this.$sentry.captureMessage(e)
    }
  },
  async updateUserTel(context, params = {}) {
    try {
      const updatedTel = await this.$axios.patch(
        `${API_USERS_URL}/${params.userId}/tels/${params.telId}`,
        {
          tel: params.tel || '',
          name: params.name || '',
          nameFurigana: params.nameFurigana || '',
          memo: params.memo || '',
          staffId: params.staffId || 0
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: '電話番号を保存しました'
      })
      return updatedTel
    } catch (e) {
      this.$sentry.captureMessage(e)
    }
  },
  async deleteUserTel(context, params = {}) {
    try {
      await this.$axios.delete(
        `${API_USERS_URL}/${params.userId}/tels/${params.telId}`,
        {
          params: { staffId: params.staffId || 0 }
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: '電話番号を削除しました'
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
