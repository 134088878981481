<template>
  <div class="parking-waiting-contacts-drawer-edit-form">
    <div class="formcontainer">
      <parking-content-card class="section -basicinfo">
        <template v-slot:header>
          <div class="title -basicinfo">基本情報</div>
        </template>
        <template v-slot:content>
          <div class="mdc-layout-grid parking-waiting-contacts-drawer-edit-form-container -basicinfo">
            <div class="inner">
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -parkingid">
                <p class="label -parkingid">駐車場ID</p>
                <div class="mdc-text-field -outlined forminput -parkingid">
                  <input
                    v-model.number="parkingId"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -contactid">
                <p class="label -contactid">問い合わせID</p>
                <div class="mdc-text-field -outlined forminput -contactid">
                  <input
                    v-model.number="contactId"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -status">
                <p class="label -status">ステータス</p>
                <div class="forminput -status">
                  <div class="radiocontainer">
                    <div class="mdc-radio">
                      <input
                        id="contact-status-enabled"
                        v-model.number="status"
                        value="1"
                        type="radio"
                        class="nativecontrol">
                    </div>
                    <label
                      class="label"
                      for="contact-status-enabled">有効</label>
                  </div>
                  <div
                    class="radiocontainer">
                    <div class="mdc-radio">
                      <input
                        id="contact-status-disabled"
                        v-model.number="status"
                        value="0"
                        type="radio"
                        class="nativecontrol">
                    </div>
                    <label
                      class="label"
                      for="contact-status-disabled">無効</label>
                  </div>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -suggestionfee">
                <p class="label -suggestionfee">提案賃料</p>
                <div class="mdc-text-field -outlined forminput -suggestionfee">
                  <input
                    v-model.number="suggestionFee"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </parking-content-card>
      <parking-content-card class="section -carinfo">
        <template v-slot:header>
          <div class="title -carinfo">車情報</div>
        </template>
        <template v-slot:content>
          <div class="mdc-layout-grid parking-waiting-contacts-drawer-edit-form-container -carinfo">
            <div class="inner">
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -carlength">
                <p class="label -length">全長</p>
                <div class="mdc-text-field -outlined forminput -length">
                  <input
                    v-model.number="contactCarLength"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -carwidth">
                <p class="label -width">全幅</p>
                <div class="mdc-text-field -outlined forminput -width">
                  <input
                    v-model.number="contactCarWidth"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -carheight">
                <p class="label -height">全高</p>
                <div class="mdc-text-field -outlined forminput -height">
                  <input
                    v-model.number="contactCarHeight"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -carweight">
                <p class="label -length">重量</p>
                <div class="mdc-text-field -outlined forminput -length">
                  <input
                    v-model.number="contactCarWeight"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -cargroundheight">
                <p class="label -tirewidth">地上高</p>
                <div class="mdc-text-field -outlined forminput -cargroundheight">
                  <input
                    v-model.number="contactCarGroundHeight"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
              <div class="cell -span6desktop -span8tablet -span4mobile inputcontainer -with-helper-text -cartirewidth">
                <p class="label -tirewidth">タイヤ幅</p>
                <div class="mdc-text-field -outlined forminput -cartirewidth">
                  <input
                    v-model.number="contactCarTireWidth"
                    type="number"
                    class="input">
                  <p
                    class="mdc-text-field-helper-text -persistent helpertext"
                    aria-hidden="true">半角数字のみ</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </parking-content-card>
      <button
        :disabled="isPristine"
        class="mdc-button registerbtn"
        @click="submitForm">{{ formSubmitButtonText }}</button>
    </div>
  </div>
</template>
<script>
import { get, sync, dispatch } from 'vuex-pathify'
import { EDIT_MODE_EDIT } from '@/store/parking/waitingContacts'
import ParkingContentCard from '@/components/parking/content-card'

export default {
  components: {
    ParkingContentCard
  },
  computed: {
    editFormData: get('parking/waitingContacts/editFormData'),
    parkingId: sync('parking/waitingContacts/editFormData@parkingId'),
    contactId: sync('parking/waitingContacts/editFormData@contactId'),
    status: sync('parking/waitingContacts/editFormData@status'),
    suggestionFee: sync('parking/waitingContacts/editFormData@suggestionFee'),
    contactCarWidth: sync(
      'parking/waitingContacts/editFormData@contactCarWidth'
    ),
    contactCarHeight: sync(
      'parking/waitingContacts/editFormData@contactCarHeight'
    ),
    contactCarLength: sync(
      'parking/waitingContacts/editFormData@contactCarLength'
    ),
    contactCarWeight: sync(
      'parking/waitingContacts/editFormData@contactCarWeight'
    ),
    contactCarGroundHeight: sync(
      'parking/waitingContacts/editFormData@contactCarGroundHeight'
    ),
    contactCarTireWidth: sync(
      'parking/waitingContacts/editFormData@contactCarTireWidth'
    ),
    isPristine: get('parking/waitingContacts/editForm@isPristine'),
    drawerFormMode: get('parking/waitingContacts@editForm.mode'),
    formSubmitButtonText() {
      return this.drawerFormMode === EDIT_MODE_EDIT ? '更新' : '登録'
    }
  },
  watch: {
    editFormData() {
      dispatch('parking/waitingContacts/checkFormPristine')
    }
  },
  methods: {
    submitForm() {
      dispatch('parking/waitingContacts/submitEditContactForm')
    }
  }
}
</script>
