export const API_URL = `${process.env.API_URL}`
export const API_AUTH_URL = `${API_URL}/auth/login`
export const API_USERS_URL = `${API_URL}/users`
export const API_TELS_URL = `${API_URL}/tels`
export const API_CALL_HISTORIES_URL = `${API_URL}/call-histories`
export const API_PARKS_URL = `${API_URL}/parking`
export const API_AREAS_URL = `${API_URL}/areas`
export const API_STATION_URL = `${API_URL}/stations`
export const API_STAFFS_URL = `${API_URL}/staffs`
export const API_NOTIFICATIONS_URL = `${API_URL}/notifications`
export const API_SERVING_CONTACTS_URL = `${API_URL}/servingContacts`
export const API_ORIGINS_URL = `${API_URL}/origins`
export const API_DIVISIONS_URL = `${API_URL}/divisions`
export const API_DEPARTMENTS_URL = `${API_URL}/departments`
export const API_USER_GROUP_URL = `${API_URL}/user-group`
export const API_PARKING_WAITING_CONTACTS = `${API_URL}/parking/waiting-contacts`
export const API_ANTI_SOCIAL_FORCES_CHECKED = `${API_URL}/anti-social-forces-checked`

export const OLD_BASE_URL = `${process.env.OLD_BASE_URL}`
export const OLD_CREATE_USER_URL = `${OLD_BASE_URL}/admin/user/new.php`
export const OLD_EDIT_USER_URL = `${OLD_BASE_URL}/admin/user/edit.php`
export const OLD_CREATE_PARKING_URL = `${OLD_BASE_URL}/admin/search/edit.php?`
export const OLD_EDIT_PARKING_URL = `${OLD_BASE_URL}/admin/search/edit.php?p_id=`
export const OLD_EDIT_CONTACT_LOG_URL = `${OLD_BASE_URL}/admin/user/edit.php?c_id=`
export const OLD_EDIT_USER_SUBLEASE_CONTRACT_URL = `${OLD_BASE_URL}/admin/sublease/user_contract/edit.php?suc_id=`

export const API_CONNECT_METHOD_GET = 'get'
export const API_CONNECT_METHOD_POST = 'post'
export const API_CONNECT_METHOD_PUT = 'put'
export const API_CONNECT_METHOD_PATCH = 'patch'
export const API_CONNECT_METHOD_DELETE = 'delete'
