<template>
  <div
    :class="{active: isActive}"
    class="search-input -elevation1">
    <div class="titlearea">
      <img
        class="icon"
        src="@/assets/svg/local_parking-24px.svg">
      <p>駐車場IDで確認</p>
    </div>
    <div
      class="mdc-text-field -fullwidth -ripple">
      <input
        id="parking_tab"
        v-model="keyword"
        class="input"
        type="text"
        placeholder="駐車場IDで確認"
        aria-label="駐車場IDを入力"
        autocomplete="off"
        @click="parkingOnClick"
        @keyup.enter="getStaffByParking"
        @keyup.tab="parkingOnClick">
    </div>
    <span class="description">※ 数字入力のみ許可します</span>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import { PARKING_SEARCH_STAFFS } from '@/store/search'
import { OLD_EDIT_PARKING_URL } from '@/config/api-url'
export default {
  data() {
    return {
      id: PARKING_SEARCH_STAFFS
    }
  },
  computed: {
    searchType: sync('search/staffs@searchType'),
    searchKeyword: sync('search/staffs@keyword'),
    isShowListAreas: sync('ui/search@isShowListAreas'),
    isFirstAccess: get('search/staffs@isFirstAccess'),
    searchTitle: sync('search/staffs@searchTitle'),
    errorMessage: sync('search/staffs@errorMessage'),
    staffs: sync('search/staffs@staffs'),
    isActive() {
      return (
        (!this.isFirstAccess || this.errorMessage) &&
        (this.searchType.localeCompare(this.id) === 0 && !this.isShowListAreas)
      )
    },
    keyword: {
      get() {
        if (this.searchType.localeCompare(this.id) === 0) {
          return this.searchKeyword
        }
        return ''
      },
      set(event) {
        this.searchKeyword = this.$helpers.zen2Han(event)
      }
    }
  },
  methods: {
    getStaffs: call('staffs/getStaffsByParkingId'),
    parkingOnClick() {
      if (document.getElementById('search-staffs-content')) {
        document.getElementById('search-staffs-content').scroll({
          top: 0,
          behavior: 'smooth'
        })
      }

      if (this.searchType.localeCompare(this.id) !== 0) {
        this.searchKeyword = ''
        this.searchType = this.id
      }
    },
    async getStaffByParking() {
      if (!this.keyword) {
        this.errorMessage = '見つかりませんでした'
        this.staffs = []
        return
      }
      this.getStaffs({
        parkingId: this.$helpers
          .zen2Han(this.keyword)
          .replace(/-/g, '')
          .trim()
      })
      this.searchTitle = `${
        this.searchKeyword
      }　<a href="${OLD_EDIT_PARKING_URL}${
        this.searchKeyword
      }" style="color: #42a5f5" target="_blank">（ 駐車場情報を開く ）</a>`
    }
  }
}
</script>
