import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({
  data: {}
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  async getUserInfoById(context, userId) {
    try {
      const res = await this.$axios.get(`${API_USERS_URL}/${userId}`)
      let {
        tels,
        emails,
        faxes,
        userParkingSourceCheckeds,
        ...userInfo
      } = res.data
      this.app.store.commit('user/tels/SET_LIST', tels || [])
      this.app.store.commit('user/emails/SET_LIST', emails || [])
      this.app.store.commit('user/faxes/SET_LIST', faxes || [])
      this.app.store.commit(
        'user/userParkingSourceCheckeds/SET_LIST',
        userParkingSourceCheckeds || []
      )
      context.commit('SET_DATA', userInfo)
    } catch (e) {
      context.commit('SET_DATA', {})
      this.$sentry.captureException(e)
    }
  },
  async updateUserInfo(context, params = {}) {
    try {
      if (!params.userId) {
        this.$sentry.captureMessage(
          'ユーザー編集：ユーザーIDは見つかりませんでした'
        )
        return
      }
      const result = await this.$axios.patch(
        `${API_USERS_URL}/${params.userId}`,
        params
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'ユーザー情報が更新されました。'
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  // ユーザー新規登録用
  async createUserInfo(context, params = {}) {
    try {
      const res = await this.$axios.post(`${API_USERS_URL}`, params)
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'ユーザー情報が登録されました。'
      })

      return res.data
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
