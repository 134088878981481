import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a978b56 = () => interopDefault(import('../pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _6361b689 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _1ea92731 = () => interopDefault(import('../pages/auth/login-failed.vue' /* webpackChunkName: "pages/auth/login-failed" */))
const _55e6231a = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _1d7c2d5e = () => interopDefault(import('../pages/parking/waiting-contacts.vue' /* webpackChunkName: "pages/parking/waiting-contacts" */))
const _0e8c8806 = () => interopDefault(import('../pages/biztel/callId/_callId/extensionNumber/_extensionNumber.vue' /* webpackChunkName: "pages/biztel/callId/_callId/extensionNumber/_extensionNumber" */))
const _5ce3aa6f = () => interopDefault(import('../pages/user-group/_id.vue' /* webpackChunkName: "pages/user-group/_id" */))
const _42913088 = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _1806f05b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth/callback",
    component: _6a978b56,
    name: "auth-callback"
  }, {
    path: "/auth/login",
    component: _6361b689,
    name: "auth-login"
  }, {
    path: "/auth/login-failed",
    component: _1ea92731,
    name: "auth-login-failed"
  }, {
    path: "/auth/logout",
    component: _55e6231a,
    name: "auth-logout"
  }, {
    path: "/parking/waiting-contacts",
    component: _1d7c2d5e,
    name: "parking-waiting-contacts"
  }, {
    path: "/biztel/callId/:callId?/extensionNumber/:extensionNumber?",
    component: _0e8c8806,
    name: "biztel-callId-callId-extensionNumber-extensionNumber"
  }, {
    path: "/user-group/:id?",
    component: _5ce3aa6f,
    name: "user-group-id"
  }, {
    path: "/users/:id?",
    component: _42913088,
    name: "users-id"
  }, {
    path: "/",
    component: _1806f05b,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
