<template>
  <div class="phone-note-list-container">
    <div class="status">
      <div class="statuschooser">
        <div class="mdc-checkbox -unhandled">
          <input
            v-model="unhandled"
            type="checkbox"
            class="nativecontrol">
        </div>
        <p class="text">承認待ち</p>
        <span
          :class="{'-empty': unhandledPhoneNoteCount === 0 }"
          class="notification">{{ unhandledPhoneNoteCount }}</span>
      </div>
    </div>
    <div class="phone-note-container">
      <div
        v-if="sentPhoneNotes.length === 0"
        class="nonotification">
        現在送信済み伝言がありません。
      </div>
      <div
        v-for="note in sentPhoneNotes"
        :key="note.id"
        :class="{ '-unread': note.attributes.notifySender === true }"
        class="item">
        <div class="addtionalinfo">
          <span class="date">{{ $helpers.formatDateFromFirestore(note.updatedAt) }} to {{ note.receivers[note.receiverIds[0]].username }}</span>
        </div>
        <div class="phone-note-content">
          <div
            class="content"
            @click="showDetailNotification(note)">
            <p
              v-show="note.content.message"
              class="text"
              v-html="$helpers.nl2br($helpers.formatMessage(note.content.message, 2, 20))"></p>
            <div class="actions">
              <div class="statebox">
                <div
                  :class="{'-waiting': !note.attributes.isDone && !note.attributes.isHandled, '-handled': note.attributes.isDone || note.attributes.isHandled}"
                  class="state">
                  <span class="text">{{ !note.attributes.isDone && !note.attributes.isHandled ? '承諾待ち' : '承諾済' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="canLoadNextSentPhoneNotes"
        class="loadmore">
        <button
          class="mdc-button loadmorebtn"
          @click="showNextNote">
          もっと見る
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { get, call, sync } from 'vuex-pathify'
import { PHONE_NOTE_DETAIL_TYPE_SENDER } from '@/store/notifications'

export default {
  data() {
    return {
      unhandled: false
    }
  },
  computed: {
    staffId: get('staffId'),
    sentPhoneNotes: get('notifications/sentPhoneNotes'),
    sentPhoneNotesPages: sync('notifications/sentPhoneNotesPages'),
    sentPhoneNotesDisplayUnhandled: sync(
      'notifications/sentPhoneNotesDisplayUnhandled'
    ),
    unhandledPhoneNoteCount: get('notifications/unhandledPhoneNoteCount'),
    canLoadNextSentPhoneNotes: get('notifications/canLoadNextSentPhoneNotes'),
    open: sync('ui/phoneNoteList@open')
  },
  watch: {
    unhandled() {
      this.sentPhoneNotesDisplayUnhandled = this.unhandled
      this.watchSentPhoneNotes()
    }
  },
  methods: {
    showNotificationDetail: call('notifications/showNotificationDetail'),
    watchSentPhoneNotes: call('notifications/watchSentPhoneNotes'),
    showNextNote() {
      this.sentPhoneNotesPages = this.sentPhoneNotesPages + 1
      this.watchSentPhoneNotes()
    },
    showDetailNotification(note) {
      this.showNotificationDetail({
        notificationId: note.id,
        notificationType: note.type,
        to: PHONE_NOTE_DETAIL_TYPE_SENDER
      })
      this.open = false
    }
  }
}
</script>
