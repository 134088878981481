import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
import { userConstants } from '@/config/user'
import { paginationConstants } from '@/config/pagination'

const INITIAL_SEARCH_RESULT = {
  list: [],
  totalCount: 0,
  nextPage: '',
  errorMessage: ''
}

// 必須なパラメタをチェックする
function validateParams(params) {
  return params.name || params.tel || params.email || params.contactId
}

// エラーメッセージを設定する
function convertErrorMessageByUserClass(userClasses) {
  const initMessage =
    '「TEL」、「MAIL」、「問い合わせID」のいずれかを必ず入力してください。'
  if (
    userClasses.length === 1 &&
    parseInt(userClasses[0]) === userConstants.userClasses.CORPORATION
  ) {
    return `'「法人名」'、${initMessage}`
  }
  if (
    userClasses.length === 1 &&
    parseInt(userClasses[0]) === userConstants.userClasses.INDIVIDUAL
  ) {
    return `'「個人名」'、${initMessage}`
  }
  return `「法人名/個人名」、${initMessage}`
}

// 検索パラメーターをフォマットする
function formatSearchParams(context, params = {}) {
  return Object.keys(params).reduce((previous, current) => {
    return params[current]
      ? {
          ...previous,
          [current]:
            current === 'tel'
              ? context.$helpers.zen2Han(params[current]).replace(/-/g, '')
              : params[current]
        }
      : previous
  }, {})
}

export const state = () => ({
  searchParams: {
    name: '',
    tel: '',
    email: '',
    userClasses: [
      userConstants.userClasses.CORPORATION,
      userConstants.userClasses.INDIVIDUAL
    ],
    sansanStatuses: [
      userConstants.sansanStatuses.ACTIVE,
      userConstants.sansanStatuses.INACTIVE
    ],
    statuses: [userConstants.userStatuses.ACTIVE],
    searchType: userConstants.userSearchTypes.CUSTOMER,
    contactId: '',
    sort: '',
    searchHintUser: false,
    page: paginationConstants.userSearchDrawer.DEFAULT_PAGE,
    pageSize: paginationConstants.userSearchDrawer.DEFAULT_PAGE_SIZE
  },
  searchResult: INITIAL_SEARCH_RESULT,
  searchHintResult: INITIAL_SEARCH_RESULT
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),

  async getUsers(context) {
    // ヒントユーザーを非表示にする
    this.app.store.dispatch('ui/showServingContactHintUsers', {
      isShow: false
    })

    // Null、ブランク、False以外の値がある検索パラメータをフィルタする
    const searchParams = formatSearchParams(this, context.state.searchParams)

    // 必須な項目をチェックする
    if (!validateParams(searchParams)) {
      context.commit('SET_SEARCH_RESULT', {
        ...INITIAL_SEARCH_RESULT,
        errorMessage: convertErrorMessageByUserClass(searchParams.userClasses)
      })
      return
    }

    try {
      // ユーザー検索実施
      const res = await this.$axios.get(`${API_USERS_URL}`, {
        params: searchParams
      })
      // ユーザーデータがない場合
      if (!res || !res.data || res.data.length === 0) {
        context.commit('SET_SEARCH_RESULT', INITIAL_SEARCH_RESULT)

        // ヒントユーザーを表示する
        this.app.store.dispatch('ui/showServingContactHintUsers', {
          isShow: true
        })
      } else {
        // ユーザーデータがある場合
        context.commit('SET_SEARCH_RESULT', {
          list: res.data,
          totalCount: res.headers['x-total-count'],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          errorMessage: ''
        })
      }
    } catch (e) {
      // エラー発生の場合
      context.commit('SET_SEARCH_RESULT', {
        ...INITIAL_SEARCH_RESULT,
        errorMessage:
          e.response && e.response.status === 400
            ? '入力内容に誤りがございます。ご確認ください。'
            : 'エラーが発生しました。しばらくしてからもう一度お試しください。'
      })
    }
  },
  async getHintUsers(context) {
    // Null、ブランク、False以外の値がある検索パラメータをフィルタする
    const searchParams = formatSearchParams(this, context.state.searchParams)

    try {
      // ユーザー検索実施
      const res = await this.$axios.get(`${API_USERS_URL}`, {
        params: searchParams
      })
      // ユーザーデータがない場合
      if (!res || !res.data || res.data.length === 0) {
        context.commit('SET_SEARCH_HINT_RESULT', INITIAL_SEARCH_RESULT)
      } else {
        // ユーザーデータがある場合
        context.commit('SET_SEARCH_HINT_RESULT', {
          list: res.data,
          totalCount: res.headers['x-total-count'],
          nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next,
          errorMessage: ''
        })
      }
    } catch (e) {
      // エラー発生の場合
      context.commit('SET_SEARCH_HINT_RESULT', {
        list: [],
        totalCount: 0,
        nextPage: '',
        errorMessage: 'エラーが発生しました。'
      })
    }
  }
}
