<template>
  <div
    :class="{active: isActive}"
    class="search-input -elevation1">
    <div class="titlearea">
      <img
        class="icon"
        src="@/assets/svg/location_on-24px.svg">
      <p>住所で確認</p>
    </div>
    <div
      :class="{'-showoverlay': isOverlayShow}"
      class="mdc-text-field -fullwidth -ripple">
      <input
        id="parking_tab"
        v-model="keyword"
        class="input"
        type="text"
        placeholder="住所で確認"
        aria-label="住所を入力"
        autocomplete="off"
        @click="areasOnClick"
        @keyup="inputKeyup"
        @keydown.enter="areasInputEnter"
        @keyup.tab="areasOnClick">
    </div>

    <ul
      v-show="isShowAddressList"
      :class="{'-showoverlay': isOverlayShow}"
      class="mdc-list google-autocomplete"
      aria-orientation="vertical">
      <li
        v-for="(address, key) in addressList"
        :id="key"
        :class="{'-current': addressIndex === key}"
        :key="key"
        class="item result"
        @click="getListArea(key)">
        <span class="location-icon"></span>
        <span class="address">{{ address.description.replace('日本、', '') }}</span>
      </li>
    </ul>
    <div
      v-show="isOverlayShow"
      id="overlay"
      class="component-overlay -transparent"
      @click="overlayClick"></div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { get, sync, call } from 'vuex-pathify'
import { AREAS_SEARCH_STAFFS } from '@/store/search'
export default {
  data() {
    return {
      id: AREAS_SEARCH_STAFFS,
      isOverlayShow: false,
      isShowAddressList: false,
      inputKeyword: '',
      addressList: [],
      addressIndex: 0
    }
  },
  computed: {
    searchType: sync('search/staffs@searchType'),
    searchKeyword: sync('search/staffs@keyword'),
    areaKeyword: sync('search/staffs@areaKeyword'),
    listAreas: sync('search/staffs@listAreas'),
    isShowListAreas: sync('ui/search@isShowListAreas'),
    isFirstAccess: sync('search/staffs@isFirstAccess'),
    areasTitle: sync('search/staffs@areasTitle'),
    searchTitle: sync('search/staffs@searchTitle'),
    errorMessage: sync('search/staffs@errorMessage'),
    staffs: sync('search/staffs@staffs'),
    isActive() {
      return (
        (!this.isFirstAccess || this.isShowListAreas || this.errorMessage) &&
        this.searchType.localeCompare(this.id) === 0
      )
    },
    keyword: {
      get() {
        if (this.searchType.localeCompare(this.id) === 0) {
          return this.searchKeyword
        }
        return ''
      },
      set(event) {
        this.searchKeyword = event
      }
    }
  },
  methods: {
    getStaffs: call('staffs/getStaffsByAreas'),
    getAreas: call('search/getListAreaSearchStaffs'),
    areasOnClick() {
      if (document.getElementById('search-staffs-content')) {
        document.getElementById('search-staffs-content').scroll({
          top: 0,
          behavior: 'smooth'
        })
      }

      if (this.searchType.localeCompare(this.id) !== 0) {
        this.searchKeyword = ''
        this.searchType = this.id
      }
    },
    async inputKeyup(event) {
      if (event.key === 'Enter') {
        return
      }

      if (event.key === 'ArrowDown' && this.addressList.length > 0) {
        this.addressIndex =
          this.addressIndex + 1 === this.addressList.length
            ? this.addressIndex
            : this.addressIndex + 1
        return
      }

      if (event.key === 'ArrowUp' && this.addressList.length > 0) {
        this.addressIndex =
          this.addressIndex - 1 >= 0 ? this.addressIndex - 1 : this.addressIndex
        return
      }

      this.addressIndex = 0
      this.addressList = []
      await this.getAddressData(event.target.value)
    },
    areasInputEnter(event) {
      if (
        (!event.target.value && !this.searchKeyword) ||
        (event.target.value === this.searchKeyword &&
          this.addressList.length === 0)
      ) {
        this.errorMessage = 'ご指定された住所は見つかりませんでした'
        return
      }

      if (
        event.target.value === this.searchKeyword &&
        this.addressList.length > 0
      ) {
        this.getListArea(this.addressIndex >= 0 ? this.addressIndex : 0)
        this.isShowAddressList = false
        this.isOverlayShow = false
      }
    },
    getAddressData: debounce(async function(areaName) {
      this.inputKeyword = areaName

      if (this.inputKeyword === '') {
        this.addressList = {}
        this.isShowAddressList = false
        this.isOverlayShow = false
        return
      }
      await this.$axios
        .get(
          `${window.location.protocol}//${
            window.location.host
          }/googleMaps/placesAutoComplete`,
          {
            params: { address: areaName }
          }
        )
        .then(result => {
          if (result.data.predictions && result.data.predictions.length > 0) {
            this.addressList = result.data.predictions
            this.isShowAddressList = true
            this.isOverlayShow = true
          }
        })
    }, 200),
    overlayClick(event) {
      this.isOverlayShow = false
      this.isShowAddressList = false
    },
    async getListArea(index) {
      const googleArea = this.addressList[index]
      const geocodeRes = await this.$axios.get(
        `${window.location.protocol}//${
          window.location.host
        }/googleMaps/geocode`,
        {
          params: { address: googleArea.description }
        }
      )

      const addressObj = geocodeRes.data.results[0].address_components
        .filter(
          obj =>
            obj.types.includes('political') &&
            !obj.types.includes('country') &&
            googleArea.description.includes(obj.long_name)
        )
        .reverse()
      const areaParams = this.getAreasByGoogle(addressObj)

      await this.getAreas(areaParams)

      this.isOverlayShow = false
      this.isShowAddressList = false
      this.searchKeyword = googleArea.description.replace('日本、', '')
      this.searchTitle = this.searchKeyword

      if (this.listAreas.length === 0) {
        this.staffs = {}
        return
      }
      if (this.listAreas.length === 1) {
        this.isShowListAreas = false
        this.getStaffs({
          areaName: this.searchKeyword,
          prefCode: this.listAreas[0].prefectureCode,
          cityCode: this.listAreas[0].cityCode,
          regionCode: this.listAreas[0].regionCode
        })
        return
      }
      this.isShowListAreas = true
      this.areasTitle = this.searchKeyword
    },
    getAreasByGoogle(addressObj) {
      let params = {}
      const prefObj = addressObj.filter(obj =>
        obj.types.includes('administrative_area_level_1')
      )
      const cityObj = addressObj.filter(
        obj =>
          (obj.types.includes('locality') ||
            (obj.types.includes('sublocality') &&
              obj.types.includes('sublocality_level_1'))) &&
          !obj.types.includes('administrative_area_level_1')
      )
      const regionObj = addressObj.filter(obj =>
        obj.types.includes('sublocality_level_2')
      )

      const prefName = prefObj.reduce(
        (prefN, obj) => `${prefN}${obj.long_name}`,
        ''
      )

      const cityName = cityObj.reduce(
        (cityN, obj) => `${cityN}${obj.long_name}`,
        ''
      )
      const regionName = regionObj.reduce(
        (regionN, obj) => `${regionN}${obj.long_name}`,
        ''
      )

      if (prefName) {
        params.prefName = prefName
      }
      if (cityName) {
        params.cityName = cityName
      }
      if (regionName) {
        params.regionName = regionName
      }

      return params
    }
  }
}
</script>
