import Auth from './auth'

import './middleware'

// Active chemes
import scheme_a12aafa4 from './schemes/custom.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":true,"watchLoggedIn":true,"redirect":{"login":"/auth/login","logout":"/","home":"/","callback":"/auth/callback","loginError":"/auth/login-failed"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"google"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Inject it to nuxt context as $auth
  inject('auth', $auth)

  // Register strategies

  // google
  $auth.registerStrategy('google', new scheme_a12aafa4($auth, {"client_id":"478397459378-hquat770c9sa38ddtae65jvv1hb20k9r.apps.googleusercontent.com","response_type":"code","token_key":"id_token","do_refresh_token":true,"_name":"google","authorization_endpoint":"https://accounts.google.com/o/oauth2/auth","userinfo_endpoint":"https://www.googleapis.com/oauth2/v3/userinfo","scope":["openid","profile","email"]}))

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.browser) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
