<template>
  <header class="the-header">
    <div class="mdc-top-app-bar mdc-top-app-bar-fixed -x-header -elevation2">
      <div class="row">
        <section class="section -alignstart">
          <a
            href="/"
            class="logolink">
            <span class="logo"></span>
            <h1 class="title">PARKING PLATFORM</h1>
          </a>
          <p
            v-if="biztel"
            class="bizteltitle">powered by</p>
        </section>
        <section class="section -alignend">
          <div
            v-show="staffId"
            class="mdc-top-app-bar-action-item">
            <div class="notification-phone-reception-requests-container">
              <button
                class="mdc-icon-button"
                @click="switchDisplayNotificationList"
                @mouseenter="showTooltip('notificationTooltip')"
                @mouseleave="closeTooltip('notificationTooltip')">
                <span
                  v-show="hasPhoneReceptionRequestNotification"
                  class="notification -circle">{{ phoneReceptionRequestNotificationCount }}</span>
                <span
                  :class="{'-show': notificationTooltip}"
                  class="tooltip -top">新規対応可否</span>
              </button>
              <phone-reception-request-list />
            </div>
            <div class="notification-phone-notes-container">
              <button
                class="mdc-icon-button -callmemo"
                @click="switchDisplayMessageList"
                @mouseenter="showTooltip('messageTooltip')"
                @mouseleave="closeTooltip('messageTooltip')">
                <span
                  v-show="hasPhoneNoteNotification"
                  class="notification -circle">{{ phoneNoteNotificationCount }}</span>
                <span
                  :class="{'-show': messageTooltip}"
                  class="tooltip -top">伝言メモ</span>
              </button>
              <phone-note-list />
            </div>
            <div
              v-show="phoneReceptionRequestListSwitch || phoneNoteListSwitch"
              class="backdrop"
              @click="handleBackdropClick"></div>
          </div>
          <p class="text">{{ !loginFailed ? staffName : 'ゲスト' }}さん</p>
          <button
            v-show="!loginFailed"
            class="mdc-button"
            @click="logout">ログアウト</button>
        </section>
      </div>
    </div>
  </header>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import phoneReceptionRequestList from '@/components/notification-phone-reception-request-list'
import phoneNoteList from '@/components/notification-phone-note-list'

export default {
  components: {
    phoneReceptionRequestList,
    phoneNoteList
  },
  data() {
    return {
      biztel: this.$route.path.includes('biztel'),
      loginFailed: this.$route.path.includes('login-failed'),
      notificationTooltip: false,
      messageTooltip: false
    }
  },
  computed: {
    phoneReceptionRequestListSwitch: sync('ui/phoneReceptionRequestList@open'),
    phoneNoteListSwitch: sync('ui/phoneNoteList@open'),
    staffId: get('staffId'),
    staffName: get('auth@user.username'),
    hasPhoneReceptionRequestNotification: get(
      'notifications/hasPhoneReceptionRequestNotification'
    ),
    hasPhoneNoteNotification: get('notifications/hasPhoneNoteNotification'),
    phoneReceptionRequestNotificationCount: get(
      'notifications/phoneReceptionRequestNotificationCount'
    ),
    phoneNoteNotificationCount: get('notifications/phoneNoteNotificationCount')
  },
  async mounted() {
    if (this.staffId) {
      this.watchNotifications()
    }
  },
  methods: {
    watchNotifications: call('notifications/watchNotifications'),
    switchDisplayNotificationList() {
      this.phoneReceptionRequestListSwitch = !this
        .phoneReceptionRequestListSwitch
      this.phoneNoteListSwitch = false
    },
    switchDisplayMessageList() {
      this.phoneNoteListSwitch = !this.phoneNoteListSwitch
      this.phoneReceptionRequestListSwitch = false
    },
    logout() {
      window.location.href = '/auth/logout'
    },
    handleBackdropClick() {
      this.phoneReceptionRequestListSwitch = false
      this.phoneNoteListSwitch = false
    },
    showTooltip(item) {
      this[item] = true
    },
    closeTooltip(item) {
      this[item] = false
    }
  }
}
</script>
