<template>
  <div class="parking-waiting-contacts-edit-drawer">
    <aside
      id="parking-waiting-contacts-edit-drawer"
      class="mdc-drawer -modal -right drawer drawercontainer"
      @keyup.esc="closeDrawer">
      <div class="header">
        <button
          class="mdc-button mdc-ripple-upgraded navicon"
          @click="closeDrawer"></button>
        <h1 class="title">{{ drawerTitle }}</h1>
      </div>
      <contact-edit-form />
    </aside>
    <div
      class="mdc-drawer-scrim"
      @click="closeDrawer"></div>
  </div>
</template>

<script>
import { MDCDrawer } from '@rsmdc/drawer'
import { get, sync } from 'vuex-pathify'
import { EDIT_MODE_EDIT } from '@/store/parking/waitingContacts'
import ContactEditForm from '@/components/parking/waiting-contacts/drawer/edit-form'

export default {
  components: {
    ContactEditForm
  },
  data() {
    return {
      parkingWaitingContactsEditDrawer: {}
    }
  },
  computed: {
    drawerOpening: sync('ui/parkingWaitingContacts@drawer.editContact.open'),
    drawerFormMode: get('parking/waitingContacts@editForm.mode'),
    drawerTitle() {
      return this.drawerFormMode === EDIT_MODE_EDIT
        ? '空き待ち情報更新'
        : '空き待ち新規登録'
    }
  },
  watch: {
    async drawerOpening(value) {
      this.parkingWaitingContactsEditDrawer.open = value
      if (value) {
        document.body.classList.add('-notscroll')
      } else {
        document.body.classList.remove('-notscroll')
      }
    }
  },
  mounted() {
    this.parkingWaitingContactsEditDrawer = new MDCDrawer(
      document.getElementById('parking-waiting-contacts-edit-drawer')
    )
    this.parkingWaitingContactsEditDrawer.open = this.drawerOpening
  },
  methods: {
    closeDrawer() {
      this.drawerOpening = false
    }
  }
}
</script>
