<template>
  <div
    v-show="isShow"
    ref="alertBox"
    class="alert-box"
    draggable="true"
    @dragend="dragEnd($event)">
    <div
      class="title">
      対応可否リクエスト返事待ち中
    </div>
    <div class="items">
      <div class="in">
        <rep-waiting-item
          v-for="item in staffs"
          :key="item.id"
          :item="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import repWaitingItem from '@/components/alert/rep-waiting-item'

export default {
  components: {
    repWaitingItem
  },
  data() {
    return {
      isShow: false,
      staffs: [],
      isDrag: false
    }
  },
  computed: {
    notification: get('notifications/notificationNotice@notification'),
    pendingNotifications: get('notifications/pendingPhoneReceptionRequests')
  },
  watch: {
    pendingNotifications() {
      this.staffs = this.pendingNotifications.map(obj => {
        return {
          id: obj.id,
          receiverName: obj.attributes.receiverName,
          createdTime: obj.createdAt.seconds
        }
      })
      this.isShow = this.staffs.length > 0
    },
    notification() {
      if (!this.notification.id) {
        return
      }
      // TIME OUT OR REFUSED OR HANDLED の場合
      if (
        this.notification.attributes.timeout ||
        this.notification.attributes.handlerId ||
        this.notification.attributes.pendingIds.length === 0
      ) {
        let repWaitingList = this.staffs.filter(
          item => item.id !== this.notification.id
        )
        this.staffs = [...repWaitingList]
        this.isShow = this.staffs.length > 0
      } else {
        // WAITINGの場合
        this.isShow = true
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      if (!this.isDrag) {
        return
      }
      // get alert box element
      let elm = this.$refs.alertBox
      // get position of alert box
      let posLeft = elm.getBoundingClientRect().left
      let posTop = elm.getBoundingClientRect().top
      this.moveAlertBox(posLeft, posTop)
    },
    dragEnd(e) {
      this.isDrag = true
      // get position of alert box
      let posLeft = e.clientX
      let posTop = e.clientY
      this.moveAlertBox(posLeft, posTop)
    },
    moveAlertBox(posLeft, posTop) {
      // get alert box element
      let elm = this.$refs.alertBox

      // get size of alert box
      let alertWidth = elm.offsetWidth
      let alertHeight = elm.offsetHeight

      if (posLeft < 0) {
        elm.style.left = '0px'
      } else if (posLeft > window.innerWidth - alertWidth) {
        elm.style.left = window.innerWidth - alertWidth + 'px'
      } else {
        elm.style.left = posLeft + 'px'
      }

      if (posTop < 0) {
        elm.style.top = '0px'
      } else if (posTop > window.innerHeight - alertHeight) {
        elm.style.top = window.innerHeight - alertHeight + 'px'
      } else {
        elm.style.top = posTop + 'px'
      }
      elm.style.right = 'auto'
      elm.style.bottom = 'auto'
    }
  }
}
</script>
