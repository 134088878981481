<template>
  <div
    v-show="show"
    class="notification-detail-phone-note">
    <div class="additionalinfo">
      <p class="text">{{ additionalInfo }}</p>
      <div class="notificationstatus">
        <p
          :class="statusClass"
          class="status -ellipse">{{ statusText }}</p>
      </div>
    </div>
    <div class="note-notification-detail">
      <div class="content">
        <p
          class="text"
          v-html="detailMessage"></p>
      </div>
      <button
        v-show="!isDone"
        :class="{
          '-done': isHandled,
          '-accept': !isHandled
        }"
        class="mdc-button -outlined"
        @click="noteClickAction(notification)">{{ isHandled | formatIsHandledButtonText }}</button>
      <div
        v-show="!isDone"
        class="transfer">
        <p class="text">このメモを他の人に転送する</p>
        <name-search-input @set-selected-staff="setSelectedStaff"/>
        <button
          :disabled="!transferStaff.id || transferStaff.id < 0"
          class="mdc-button -outlined"
          @click="transferNoteAction(notification.id)">転送する</button>
      </div>
    </div>
  </div>
</template>
<script>
import { get, call } from 'vuex-pathify'
import {
  COLLECTION_PHONE_NOTE,
  PHONE_NOTE_DETAIL_TYPE_RECEIVER
} from '@/store/notifications'
import nameSearchInput from '@/components/search-staffs/name-search-input'

export default {
  components: {
    nameSearchInput
  },
  filters: {
    formatIsHandledButtonText(isHandled) {
      return isHandled ? '完了する' : '承諾する'
    }
  },
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      transferToUserId: null,
      transferStaff: []
    }
  },
  computed: {
    staffId: get('staffId'),
    authStaffExtensionNumber: get('auth@user.extensionNumber'),
    notificationAttributes: get('notifications/notificationDetail@attributes'),
    isNotification() {
      return this.notification && this.notification.id
    },
    isDone() {
      return (
        this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.isDone
      )
    },
    isHandled() {
      return (
        this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.isHandled
      )
    },
    show() {
      return (
        this.isNotification &&
        this.notification.type === COLLECTION_PHONE_NOTE &&
        this.notificationAttributes &&
        this.notificationAttributes.to === PHONE_NOTE_DETAIL_TYPE_RECEIVER
      )
    },
    additionalInfo() {
      if (
        this.isNotification &&
        this.notification.sender &&
        this.notification.updatedAt &&
        this.notification.receivers &&
        this.notification.receiverIds
      ) {
        if (
          this.notification.sender.id === this.staffId &&
          this.notification.receivers[this.notification.receiverIds[0]]
        ) {
          return `${this.$helpers.formatDateFromFirestore(
            this.notification.updatedAt
          )} to ${
            this.notification.receivers[this.notification.receiverIds[0]]
              .username
          }`
        }
        return `${this.$helpers.formatDateFromFirestore(
          this.notification.updatedAt
        )} from ${this.notification.sender.username}`
      }
      return ''
    },
    statusClass() {
      if (this.isDone) {
        return { '-finished': true }
      }
      if (this.isHandled) {
        return { '-confirmed': true }
      }
      return { '-waiting': true }
    },
    statusText() {
      if (this.isDone) {
        return '完了済'
      }
      if (this.isHandled) {
        return '未完了'
      }
      return '未承諾'
    },
    detailMessage() {
      return this.isNotification &&
        this.notification.content &&
        this.notification.content.message
        ? this.$helpers.nl2br(this.notification.content.message)
        : ''
    }
  },
  methods: {
    hideNotificationDetail: call('notifications/hideNotificationDetail'),
    setNoteIsDone: call('notifications/setNoteIsDone'),
    setNoteHandler: call('notifications/setNoteHandler'),
    transferNote: call('notifications/transferNote'),
    noteClickAction(note) {
      if (note.attributes.isHandled) {
        this.setNoteIsDone({ noteId: note.id })
      } else {
        this.setNoteHandler({ noteId: note.id })
      }
    },
    transferNoteAction(noteId) {
      try {
        this.transferNote({
          noteId,
          receiver: {
            id: this.transferStaff.id,
            username: this.transferStaff.username,
            extensionNumber: this.authStaffExtensionNumber,
            email: this.transferStaff.email
          }
        })
      } catch (e) {
        this.$sentry.captureException(e)
      }
      this.hideNotificationDetail()
    },
    setSelectedStaff(obj) {
      this.transferStaff = obj.staff
    }
  }
}
</script>
