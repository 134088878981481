<template>
  <div 
    v-show="show" 
    class="user-group-create-form">
    <button 
      :class="{'-openingform': isOpeningForm}"
      class="mdc-button creategroupbtn"
      @click="toggleForm">
      新規グループ作成
    </button>
    <div 
      v-show="isOpeningForm" 
      class="formcontainer">
      <div class="item">
        <div class="label">グループ名</div>
        <div class="mdc-text-field field -name -outlined">
          <input
            v-model.trim="model.name"
            type="text"
            class="input"
            @keyup="onInput">
        </div>
      </div>
      <div class="item">
        <div class="label">メモ</div>
        <div class="mdc-text-field field -memo -outlined">
          <textarea
            id="textarea"
            v-model.trim="model.memo"
            rows="5"
            class="input textarea -memo"
            @keyup="onInput">
          </textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => ({
        name: '',
        memo: ''
      })
    },
    onInput: {
      type: Function,
      default: () => () => {}
    }
  },
  data() {
    return {
      isOpeningForm: false
    }
  },
  methods: {
    toggleForm() {
      this.isOpeningForm = !this.isOpeningForm
    }
  }
}
</script>
