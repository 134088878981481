<template>
  <div>
    <aside
      id="notification-push-dialog"
      class="mdc-dialog notification-push-dialog"
      role="alertdialog"
      aria-labelledby="my-mdc-dialog-label"
      aria-describedby="my-mdc-dialog-description">
      <div class="surface">
        <header class="header">
          <h2 class="title">{{ title }}</h2>
        </header>
        <section
          class="body">
          <div class="mdc-text-field -ripple -fullwidth -textarea">
            <textarea
              id="notification-push-message"
              v-model="message"
              rows="8"
              class="input"></textarea>
            <label
              for="notification-push-message"
              class="label">対応可否リクエスト内容</label>
          </div>
        </section>
        <footer class="footer">
          <div
            v-if="notificationPush.isShowSearchUser"
            class="searchusername">
            <name-search-input
              :init-staff-name="searchStaffName"
              class="searchbox"
              @set-selected-staff="setSelectedStaff"/>
            <button
              :disabled="!notificationPush.staffs || notificationPush.staffs.length === 0"
              class="mdc-button mdc-ripple-upgraded"
              @click="submitNotification">新規対応可否リクエスト送信</button>
          </div>
          <div
            v-else
            class="btns">
            <button
              class="mdc-button -cancel"
              @click="overlayClick">キャンセル</button>
            <button
              class="mdc-button mdc-ripple-upgraded"
              @click="submitNotification">送信する</button>
          </div>
        </footer>
      </div>
      <div
        class="backdrop"
        @click="overlayClick"></div>
    </aside>
  </div>
</template>
<script>
import { get, call, dispatch } from 'vuex-pathify'
import { MDCDialog } from '@rsmdc/dialog'
import { OLD_EDIT_PARKING_URL } from '@/config/api-url'
import nameSearchInput from '@/components/search-staffs/name-search-input'

export default {
  components: {
    nameSearchInput
  },
  props: {
    showSearchUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchStaffName: ''
    }
  },
  computed: {
    notificationPush: get('notifications/notificationPush@info'),
    title() {
      if (this.notificationPush.title) {
        return this.notificationPush.title
      } else if (this.notificationPush.staffName) {
        return `${
          this.notificationPush.staffName
        }さんに対応可否リクエストを送りますか？`
      } else if (this.notificationPush.area) {
        return `${
          this.notificationPush.area
        }エリアメンバーに対応可否リクエストを送りますか？`
      }

      return `対応可否リクエストを送りますか？`
    },
    message: {
      get() {
        return this.notificationPush.message
      },
      set(value) {
        dispatch('notifications/notificationPush/setInfo', {
          ...this.notificationPush,
          message: value
        })
      }
    }
  },
  watch: {
    notificationPush() {
      this.showDialog()
    }
  },
  mounted() {
    this.showDialog()
    //You can't have a focus-trap without at least one focusable element
    dispatch('notifications/notificationPush/setInfo', {})
  },
  methods: {
    createNewPhoneReceptionRequest: call(
      'notifications/createNewPhoneReceptionRequest'
    ),
    showSnackBar: call('ui/showSnackBar'),
    submitNotification() {
      let notificationMessage = this.notificationPush.message
      notificationMessage = notificationMessage.replace(
        /駐車場ID　：(\d+) /g,
        `駐車場ID　：$1 <a href="${OLD_EDIT_PARKING_URL}$1" style="color: #42a5f5" target="_blank">（ 駐車場情報を開く ）</a>`
      )
      // 対応可否リクエスト返事待ち中というアラート表示用
      let receiverName = ''
      if (this.notificationPush.area) {
        receiverName = this.notificationPush.area + 'エリアメンバー'
      } else if (this.notificationPush.staffName) {
        receiverName = this.notificationPush.staffName + 'さん'
      }
      this.createNewPhoneReceptionRequest({
        title: '新規対応の可否',
        content: {
          message: notificationMessage
        },
        receivers: this.notificationPush.staffs,
        receiverName
      })

      this.showSnackBar({ message: '対応可否リクエストを送信しました' })
      dispatch('notifications/notificationPush/setInfo', {
        ...this.notificationPush,
        isShow: false,
        staffs: []
      })
      this.searchStaffName = ''
    },
    showDialog() {
      this.notificationDialog = new MDCDialog(
        document.getElementById('notification-push-dialog')
      )
      this.notificationPush.isShow
        ? this.notificationDialog.show()
        : this.notificationDialog.close()
    },
    overlayClick() {
      dispatch('notifications/notificationPush/setInfo', {
        ...this.notificationPush,
        isShow: true
      })
    },
    setSelectedStaff(staffObj) {
      dispatch('notifications/notificationPush/setInfo', {
        ...this.notificationPush,
        area: '',
        staffName: staffObj.staffName,
        staffs:
          staffObj.staff && staffObj.staff.id && staffObj.staff.id > 0
            ? [staffObj.staff]
            : []
      })
      this.searchStaffName = staffObj.staffName
    }
  }
}
</script>
