<template>
  <div>
    <div
      v-show="isShowResult"
      class="search-result -elevation1">
      <div class="titlearea">
        <img
          :src="resultIcon"
          class="icon">
        <p v-html="searchTitle"></p>
      </div>
      <div class="stafftitle">
        <img
          class="icon"
          src="@/assets/svg/person_pin-24px.svg">
        <p class="areatitle">担当営業：{{ area }} エリア</p>
      </div>
      <ul
        v-show="staffs.length > 0"
        class="arearesult">
        <li
          v-for="staff in staffs"
          :key="staff.id"
          class="staffsresult">
          <span class="staffname">{{ staff.username }}</span>
          <span class="inner_line">内線：{{ staff.extensionNumber || 'なし' }}</span>
        </li>
      </ul>
      <div
        v-show="staffs.length === 0"
        class="arearesult">
        <p class="message">担当者が見つかりませんでした</p>
      </div>

      <p class="notificationtitle">{{ staffs.length > 1 ? '担当者全員に' : (staffs.length === 0 ? '' : `${staffName}さんに` ) }}対応可否リクエストを送る</p>

      <div class="notificationinfo">
        <div class="mdc-text-field -ripple -fullwidth -textarea">
          <textarea
            id="staff-notification"
            v-model="staffsMessage"
            rows="8"
            class="input"
            type="text">
          </textarea>
          <label
            for="staff-notification"
            class="label -floatabove">対応可否リクエスト内容</label>
        </div>
      </div>
      <div class="notificationconfirm">
        <button
          class="mdc-button mdc-ripple-upgraded -nopush"
          @click="proposalRegistration">自分で新規案件登録する</button>
        <button
          :disabled = "staffs.length === 0"
          class="mdc-button mdc-ripple-upgraded"
          @click="notificationHandle">{{ staffs.length > 1 ? '担当者全員に' : (staffs.length === 0 ? '' : `${staffName}さんに` ) }}対応可否リクエストを送る</button>
      </div>
    </div>

    <div
      v-show="isShowListAreas && !errorMessage"
      class="search-result -elevation1">
      <div class="titlearea">
        <img
          class="icon"
          src="@/assets/svg/location_on-24px.svg">
        <p>{{ areasTitle }}の検索結果</p>
      </div>
      <ul class="mdc-list areas">
        <li
          v-for="(area, key) in listAreas"
          :id="key"
          :key="key"
          class="item result"
          @click="areaOnClick(key)">
          <p class="areaname">{{ area.prefectureName || '' }}{{ area.cityName || '' }}{{ area.regionName || '' }}</p>
        </li>
      </ul>
    </div>
    <!--エラーメッセージ-->
    <div
      v-show="isShowError"
      class="search-result -elevation1">
      <p class="errormessage">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { get, sync, call, dispatch } from 'vuex-pathify'
import { OLD_CREATE_USER_URL } from '@/config/api-url'
import {
  STATION_SEARCH_STAFFS,
  PARKING_SEARCH_STAFFS,
  AREAS_SEARCH_STAFFS
} from '@/store/search'

export default {
  data() {
    return {
      iconImage: {
        parkingIcon: require('../../assets/svg/local_parking-24px.svg'),
        stationIcon: require('../../assets/svg/directions_transit-24px.svg'),
        areasIcon: require('../../assets/svg/location_on-24px.svg')
      },
      resultIcon: '',
      notificationMessage: '',
      notificationTitle: '',
      staffName: ''
    }
  },
  computed: {
    searchType: get('search/staffs@searchType'),
    searchKeyword: get('search/staffs@keyword'),
    areasTitle: get('search/staffs@areasTitle'),
    searchTitle: get('search/staffs@searchTitle'),
    isShowListAreas: sync('ui/search@isShowListAreas'),
    isFirstAccess: sync('search/staffs@isFirstAccess'),
    area: sync('staffs/area@name'),
    staffs: sync('staffs/staffs'),
    listAreas: get('search/staffs@listAreas'),
    staffsMessage: sync('search/staffs@message'),
    errorMessage: get('search/staffs@errorMessage'),
    inProgressCallHistory: get('biztel/inProgressCallHistory@info'),
    isShowResult() {
      return !this.isShowListAreas && !this.errorMessage && !this.isFirstAccess
    },
    isShowError() {
      return this.errorMessage
    }
  },
  watch: {
    staffs(value) {
      this.isFirstAccess = false
      switch (this.searchType) {
        case PARKING_SEARCH_STAFFS:
          this.resultIcon = this.iconImage.parkingIcon
          break
        case STATION_SEARCH_STAFFS:
          this.resultIcon = this.iconImage.stationIcon
          break
        case AREAS_SEARCH_STAFFS:
          this.resultIcon = this.iconImage.areasIcon
          break
      }
      this.staffName = this.staffs.length > 0 ? this.staffs[0].username : 'なし'
      this.setStaffsMessage()
    }
  },
  methods: {
    getStaffs: call('staffs/getStaffsByAreas'),
    setStaffsMessage: call('search/setStaffsMessage'),
    async areaOnClick(index) {
      const area = this.listAreas[index]
      this.getStaffs({
        areaName:
          (area.prefectureName || '') +
          (area.cityName || '') +
          (area.region || ''),
        prefCode: area.prefectureCode,
        cityCode: area.cityCode,
        regionCode: area.regionCode
      })
      this.isShowListAreas = false
    },
    async notificationHandle() {
      dispatch('notifications/notificationPush/setInfo', {
        area: this.area,
        message: this.staffsMessage,
        staffs: this.staffs,
        isShow: true
      })
    },
    proposalRegistration() {
      const params = {
        tel: this.inProgressCallHistory.inProgressTel,
        userID: this.inProgressCallHistory.userId || 'anonymous',
        callID: this.inProgressCallHistory.id
      }

      const query = Object.keys(params)
        .filter(key => params[key])
        .map(key => `${key}=${params[key]}`)
        .join('&')
      window.open(`${OLD_CREATE_USER_URL}?${query}`, '_blank')
    }
  }
}
</script>
