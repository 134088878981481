import { API_RESPONSE_ERROR_MESSAGE } from '@/config/response-error-message'
import PhoneNumber from 'awesome-phonenumber'

function formatDateFromFirestore(firestoreTimeObject) {
  if (firestoreTimeObject && firestoreTimeObject.seconds) {
    const createdDate = new Date(firestoreTimeObject.seconds * 1000)
    const year = createdDate.getFullYear()
    const month =
      createdDate.getMonth() + 1 >= 10
        ? `${createdDate.getMonth() + 1}`
        : `0${createdDate.getMonth() + 1}`
    const date =
      createdDate.getDate() >= 10
        ? `${createdDate.getDate()}`
        : `0${createdDate.getDate()}`
    const hours =
      createdDate.getHours() >= 10
        ? `${createdDate.getHours()}`
        : `0${createdDate.getHours()}`
    const minutes =
      createdDate.getMinutes() >= 10
        ? `${createdDate.getMinutes()}`
        : `0${createdDate.getMinutes()}`
    const seconds =
      createdDate.getSeconds() >= 10
        ? `${createdDate.getSeconds()}`
        : `0${createdDate.getSeconds()}`
    return `${year}/${month}/${date} ${hours}:${minutes}:${seconds}`
  }
  return ''
}

function checkValidPhoneNumber(number) {
  if (!number) {
    return false
  }
  let pn = new PhoneNumber(number, 'JP')
  if (!pn.isValid() || !pn.isPossible() || pn.getType === 'unknown') {
    return false
  }
  return true
}

function zen2Han(zenkakuString) {
  if (typeof zenkakuString !== 'string' || !zenkakuString) {
    return ''
  }

  const hankakuString = zenkakuString
    .replace(/[Ａ-Ｚａ-ｚ０-９＠]/g, s => {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    })
    .replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '-') // ハイフンなど
    .replace(/[～〜]/g, '~') // チルダ
    .replace(/　/g, ' ') // スペース
  return hankakuString
}

function getResponseErrorMessage(params = {}) {
  const { statusCode } = params

  if (!API_RESPONSE_ERROR_MESSAGE[statusCode]) {
    return API_RESPONSE_ERROR_MESSAGE[500]
  }

  return API_RESPONSE_ERROR_MESSAGE[statusCode]
}

function parseLinkHeader(header) {
  if (!header || header.length === 0) {
    return {}
  }

  // Split parts by comma
  const parts = header.split(',')
  const links = {}
  // Parse each part into a named link
  parts.forEach(p => {
    const section = p.split(';')
    if (section.length !== 2) {
      return {}
    }
    const url = section[0].replace(/<(.*)>/, '$1').trim()
    const name = section[1].replace(/rel="(.*)"/, '$1').trim()
    links[name] = url
  })

  return links
}

function nl2br(string) {
  if (string && string.trim().length > 0) {
    return string.replace(/\r\n/g, '<br />').replace(/(\n|\r)/g, '<br />')
  }
  return ''
}

function copyToClipBoard(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy')
  textArea.remove()
}

function setFavicon(favicon) {
  const icon = document.querySelector("link[rel*='icon']")
  if (!icon) return
  icon.href = favicon
}

function setTitle(text) {
  const title = document.querySelector('title')
  if (!title) return
  title.innerHTML = text
}

function formatMessage(message, line = 4, limit = 20) {
  let trunk = message.split('\n')
  let displayTrunk = trunk.slice(0, line)
  let lastLine = line - 1
  if (typeof displayTrunk[lastLine] !== 'undefined') {
    if (displayTrunk[lastLine].length > limit || trunk.length > line) {
      displayTrunk[lastLine] = `${displayTrunk[lastLine].slice(0, limit)}...`
    }
  }
  return displayTrunk.join('\n')
}

function formatLabel(label, limit = 15) {
  if (label !== null) {
    if (label.length <= limit) {
      return label
    }
    const shortLabel = `${label.slice(0, limit)}...`
    return shortLabel
  }
}

function convertContactLogStatusToString(contactLogStatus) {
  const contactStatusNames = {
    '1': '受付',
    '2': 'ヒアリング済',
    '11': '空室有メール送信',
    '22': '空室有メール送信（直営）',
    '23': '空室有メール送信（レオパ）',
    '16': '追客メール送信',
    '12': '満室メール送信',
    '14': '連絡つかず',
    '13': '見込み',
    '21': '契約調整',
    '31': '成約',
    '32': '終了',
    '34': '他決',
    '35': 'クレーム',
    '19': '保有',
    '15': '逆引き中',
    '17': 'テレアポ中',
    '-21': '名寄せ済',
    '301': 'レオパレス問合',
    '302': '業者問合',
    '18': '自動対応メール送信済',
    '401': '自動満室対応対象',
    '411': '自動満室メール送信',
    '421': '自動名寄せ',
    '201': 'フォローメール送信'
  }
  return contactStatusNames[contactLogStatus]
}

function convertContactLogStatusClassName(status) {
  const statusClasses = {
    '-duplicated': [-21, 421],
    '-inprogress': [1, 2, 11, 12, 13, 14, 16, 18, 21, 22, 23, 201, 411],
    '-source': [301, 302],
    '-singed': [31]
  }
  for (let key in statusClasses) {
    if (statusClasses[key].includes(status)) {
      return key
    }
  }
  return ''
}

function parseUseContactTiming(useTiming) {
  if (!useTiming) {
    return '不明'
  }
  const monthParts = {
    1: '初旬',
    2: '中旬',
    3: '下旬'
  }
  const [year, month, monthPart] = useTiming.split(',')
  if (year && month && monthParts[monthPart]) {
    return `${year}年${month}月${monthParts[monthPart]}`
  }
  if (year && !month && !monthParts[monthPart]) {
    return `${year}年 月、時期は不明`
  }
  if (year && month && !monthParts[monthPart]) {
    return `${year}年${month}月 時期は不明`
  }

  return '不明'
}

export default (context, inject) => {
  inject('helpers', {
    formatDateFromFirestore,
    checkValidPhoneNumber,
    zen2Han,
    getResponseErrorMessage,
    parseLinkHeader,
    nl2br,
    copyToClipBoard,
    setFavicon,
    setTitle,
    formatMessage,
    formatLabel,
    convertContactLogStatusToString,
    parseUseContactTiming,
    convertContactLogStatusClassName
  })
}
