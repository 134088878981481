import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),

  addUserComplianceCheck(context, { userId, complianceCheckList }) {
    return this.$axios.post(
      `${API_USERS_URL}/${userId}/user-compliance-check`,
      {
        complianceCheckList
      }
    )
  },
  deleteUserComplianceCheck(context, { userId, complianceCheckIds }) {
    return this.$axios.delete(
      `${API_USERS_URL}/${userId}/user-compliance-check`,
      { params: { complianceCheckIds } }
    )
  }
}
