<template>
  <div
    class="tels-content">
    <div class="input">
      <div class="mdc-text-field -outlined -focused tel">
        <input
          v-model.trim="telNumber"
          type="text"
          class="input">
        <p
          v-show="telNumberErrorMessage"
          class="telNumber">{{ telNumberErrorMessage }}</p>
      </div>
      <div class="mdc-text-field -outlined name">
        <input
          v-model.trim="name"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field -outlined namefurigana">
        <input
          v-model.trim="nameFurigana"
          type="text"
          class="input">
      </div>
      <div class="mdc-text-field -outlined supplement">
        <input
          v-model.trim="memo"
          type="text"
          class="input">
      </div>
    </div>
    <div class="btns">
      <button
        :ref="`saveTel${id}`"
        :disabled="saveDisabled"
        class="mdc-button -save"
        @click="saveTel"><svg class="icon" />保存</button>
      <button
        class="mdc-button -delete"
        @click="deleteTel"><svg class="icon" />削除</button>
    </div>
  </div>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'
export default {
  props: {
    tel: {
      type: Object,
      default: function() {
        return {}
      }
    },
    userId: {
      type: Number,
      default: function() {
        return 0
      }
    },
    index: {
      type: Number,
      default: function() {
        return 0
      }
    },
    tels: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      id: 0,
      telNumber: '',
      name: '',
      nameFurigana: '',
      memo: '',
      telNumberErrorMessage: '',
      saveDisabled: true
    }
  },
  computed: {
    staffId: get('auth@user.id')
  },
  watch: {
    tel() {
      this.setTel()
    },
    telNumber() {
      this.updateSaveDisabled()

      if (!this.telNumber && this.id) {
        this.telNumberErrorMessage = '電話番号を入力してください'
        this.setSaveDisabled(true)
        return
      }

      if (!this.telNumber && !this.id) {
        this.telNumberErrorMessage = ''
        this.setSaveDisabled(true)
        return
      }

      let bol = this.$helpers.checkValidPhoneNumber(
        this.convertTel(this.telNumber)
      )
      if (!bol) {
        this.telNumberErrorMessage = '電話番号を確認してください'
        this.setSaveDisabled(true)
        return
      }

      const checkExistTel = this.tels.filter(
        tel => this.convertTel(tel.tel) === this.convertTel(this.telNumber)
      )
      if (checkExistTel.length > 0 && this.id !== checkExistTel[0].id) {
        this.setSaveDisabled(true)
        this.telNumberErrorMessage = '電話番号が登録されました'
        return
      }
      this.telNumberErrorMessage = ''
    },
    memo() {
      this.updateSaveDisabled()
    },
    nameFurigana() {
      this.updateSaveDisabled()
    },
    name() {
      this.updateSaveDisabled()
    }
  },
  created() {
    this.setTel()
  },
  methods: {
    showSnackBar: call('ui/showSnackBar'),
    createUserTel: call('user/tels/createUserTel'),
    updateUserTel: call('user/tels/updateUserTel'),
    deleteUserTel: call('user/tels/deleteUserTel'),
    async saveTel() {
      try {
        let bol = this.$helpers.checkValidPhoneNumber(
          this.convertTel(this.telNumber)
        )
        if (!bol) {
          this.telNumberErrorMessage = '電話番号を確認してください'
          return
        }

        if (this.id) {
          const updateTel = await this.updateUserTel({
            userId: this.userId,
            telId: this.id,
            tel: this.telNumber ? this.convertTel(this.telNumber) : '',
            name: this.name || '',
            nameFurigana: this.nameFurigana || '',
            memo: this.memo || '',
            staffId: this.staffId || 0
          })

          this.id = updateTel.data.telId
          this.setSaveDisabled(true)
          this.$emit('update-tel')
          return
        }

        const checkExistTel = this.tels.filter(
          tels => tels.tel === this.telNumber
        )
        if (checkExistTel.length > 0) {
          this.telNumberErrorMessage = '電話番号が登録されました'
          return
        }
        const creatTelObj = await this.createUserTel({
          userId: this.userId,
          tel: this.telNumber ? this.convertTel(this.telNumber) : '',
          name: this.name || '',
          nameFurigana: this.nameFurigana || '',
          memo: this.memo || '',
          staffId: this.staffId || 0
        })
        this.setSaveDisabled(true)
        this.id = creatTelObj.data.id
        this.$emit('update-tel')
      } catch (e) {
        this.showSnackBar({ message: '問題が発生されました' })
        this.$sentry.captureMessage('ユーザーに話番号追加が失敗されました。')
      }
    },
    async deleteTel() {
      if (!this.id) {
        this.$emit('remove', { index: this.index })
        return
      }

      if (
        confirm(
          `【${this.tel.tel}】の電話番号が削除されますが、よろしいでしょうか？`
        )
      ) {
        await this.deleteUserTel({
          staffId: this.staffId || 0,
          telId: this.id,
          userId: this.userId
        })
        this.$emit('update-tel')
      }
    },
    setTel() {
      this.id = this.tel.id
      this.telNumber = this.$options.filters.formatPhoneNumber(this.tel.tel)
      this.name = this.tel.name
      this.nameFurigana = this.tel.nameFurigana
      this.memo = this.tel.memo
    },
    updateSaveDisabled() {
      if (this.id === 0) {
        this.setSaveDisabled(true)
      }
      if (
        (!this.telNumber && !this.tel.tel) ||
        this.convertTel(this.telNumber) === this.convertTel(this.tel.tel)
      ) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if ((!this.memo && !this.tel.memo) || this.memo === this.tel.memo) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if ((!this.name && !this.tel.name) || this.name === this.tel.name) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }

      if (
        (!this.nameFurigana && !this.tel.nameFurigana) ||
        this.nameFurigana === this.tel.nameFurigana
      ) {
        this.setSaveDisabled(true)
      } else {
        this.setSaveDisabled(false)
        return
      }
    },
    setSaveDisabled(value) {
      this.saveDisabled = value
    },
    convertTel(tel) {
      return this.$helpers.zen2Han(tel).replace(/-/g, '')
    }
  }
}
</script>
