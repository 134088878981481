<template>
  <div class="drawer-user-group-edit-form">
    <div class="title">
      <h2 class="text">基本情報</h2>
    </div>
    <div class="content -elevation1">
      <div class="item -name">
        <p class="label -name">グループ名<span class="caution -necessary">(必要)</span></p>
        <div class="mdc-text-field -outlined forminput -name">
          <input
            v-model.trim="editingGroupName"
            type="text"
            class="input">
          <span
            v-show="groupNameError"
            class="error">{{ groupNameError }}</span>
        </div>
      </div>
      <div class="item -memo">
        <p class="label -memo">メモ</p>
        <div class="mdc-text-field -outlined forminput -memo">
          <textarea
            id="textarea"
            v-model.trim="editingGroupMemo"
            rows="5"
            class="input">
          </textarea>
        </div>
      </div>
      <div
        v-if="editForm.mode === 'edit'"
        class="item -isenabled">
        <p class="label -isenabled">ステータス</p>
        <div
          :key="radioKey"
          class="forminput -isenabled">
          <div class="radiocontainer">
            <div class="mdc-radio">
              <input
                id="group-is-enabled-1"
                v-model.number="editingGroupStatus"
                value="1"
                type="radio"
                class="nativecontrol">
            </div>
            <label
              class="label"
              for="group-is-enabled-1">有効</label>
          </div>
          <div
            class="radiocontainer">
            <div class="mdc-radio">
              <input
                id="group-is-enabled-0"
                v-model.number="editingGroupStatus"
                value="0"
                type="radio"
                class="nativecontrol">
            </div>
            <label
              class="label"
              for="group-is-enabled-0">無効</label>
          </div>
        </div>
      </div>
      <div class="action">
        <button
          :disabled="disableSave"
          class="mdc-button savebtn"
          @click="onClickSaveBtn">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import { sync, get, dispatch, call } from 'vuex-pathify'
import confirmBox from '@/components/confirm-box'

export default {
  components: {
    confirmBox
  },
  data() {
    return {
      // use this key to force re-render radio part in the form, because the radio button still accept the click even when user cancel the select action
      radioKey: Date.now()
    }
  },
  computed: {
    staffId: get('staffId'),
    groupInfo: get('userGroup/info'),
    editForm: sync('userGroup/editForm'),
    isDrawerOpen: sync('ui/userGroupPage@drawer.isEditUserGroupOpened'),
    editingGroupName: {
      get() {
        return this.editForm.name
      },
      set(value) {
        dispatch('userGroup/setEditForm', {
          ...this.editForm,
          name: value,
          isPristine: this.isFormPristine('name', value)
        })
      }
    },
    editingGroupMemo: {
      get() {
        return this.editForm.memo
      },
      set(value) {
        dispatch('userGroup/setEditForm', {
          ...this.editForm,
          memo: value,
          isPristine: this.isFormPristine('memo', value)
        })
      }
    },
    editingGroupStatus: {
      get() {
        return this.editForm.isEnabled
      },
      set(value) {
        if (
          value === 0 &&
          !confirm(
            '無効にしましたら、配属されている支店はすべてグループに除外されます。'
          )
        ) {
          this.radioKey = Date.now() // to fix cancel select bug
          return
        }
        dispatch('userGroup/setEditForm', {
          ...this.editForm,
          isEnabled: value,
          isPristine: this.isFormPristine('isEnabled', value)
        })
      }
    },
    disableSave() {
      return this.editForm.isPristine || !this.editingGroupName
    },
    groupNameError() {
      if (!this.editForm.isPristine && !this.editingGroupName) {
        return 'グループ名を入力してください'
      }
      return ''
    }
  },
  methods: {
    saveUserGroup: call('userGroup/saveUserGroup'),
    async onClickSaveBtn() {
      // cannot await dispatch, have to use call
      await this.saveUserGroup()
      if (
        this.editForm.mode === 'add' &&
        this.editForm.newlyCreatedGroupId !== null
      ) {
        this.$router.push({
          path: `/user-group/${this.editForm.newlyCreatedGroupId}`
        })
        this.isDrawerOpen = false
      }
    },
    isFormPristine(attribute, value) {
      const toBeComparedAttributes =
        this.editForm.mode === 'edit'
          ? ['name', 'memo', 'isEnabled']
          : ['name', 'memo']
      return toBeComparedAttributes.every(toBeComparedAttribute => {
        if (toBeComparedAttribute === attribute) {
          return this.groupInfo[toBeComparedAttribute] === value
        }
        return (
          this.editForm[toBeComparedAttribute] ===
          this.groupInfo[toBeComparedAttribute]
        )
      })
    }
  }
}
</script>
