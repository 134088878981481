<template>
  <div
    :class="{'disable-mails': disableFlag}"
    class="user-parking-source-checked-edit"
  >
    <div class="title">
      <h1 class="text">物件確認先</h1>
    </div>
    <div class="content -elevation1">
      <div class="main-content">
        <div class="header">
          <div class="labels">
            <p class="label -source-type">方法</p>
            <p class="label -company-info">URL</p>
            <p class="label -same-parking-ids">管理会社物件ID</p>
            <p class="label -memo">注意事項</p>
          </div>
          <div class="addnew">
            <button
              class="mdc-button"
              @click="addNewUserParkingSourceCheckedObj"
            >
              <svg class="icon"/>
              追加
            </button>
          </div>
        </div>
        <div
          v-if="isUserEditPageOpen"
          class="user-parking-source-checked-obj"
        >
          <user-parking-source-checked-edit-box
            v-for="(userParkingSourceChecked, index) in currentUserParkingSourceCheckeds"
            :key="index"
            :user-parking-source-checked="userParkingSourceChecked"
            :index="index"
            :user-id="userInfo.id"
            @remove="removeUserParkingSourceChecked"
            @update-user-parking-source-checked="updateUserParkingSourceChecked"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import userParkingSourceCheckedEditBox from '@/components/user-page/user-parking-source-checked-edit-box'

const defaultUserParkingSourceChecked = {
  id: 0,
  sourceType: '',
  companyName: '',
  companySource: '',
  sameParkingIds: '',
  memo: ''
}

export default {
  components: { userParkingSourceCheckedEditBox },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {
          id: 0,
          userClass: 1,
          postcode: '',
          name: '',
          address: '',
          cautionLevel: 1,
          status: 1,
          memo: ''
        }
      }
    },
    userParkingSourceCheckeds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentUserParkingSourceCheckeds: []
    }
  },
  computed: {
    isUserEditPageOpen: get('ui/userEditPage@open'),
    disableFlag() {
      return !this.userInfo.id
    }
  },
  watch: {
    userParkingSourceCheckeds() {
      this.setCurrentUserParkingSourceCheckeds()
    }
  },
  created() {
    this.setCurrentUserParkingSourceCheckeds()
  },
  mounted() {
    this.setCurrentUserParkingSourceCheckeds()
  },
  methods: {
    showSnackBar: call('ui/showSnackBar'),
    addNewUserParkingSourceCheckedObj() {
      this.currentUserParkingSourceCheckeds = [
        ...this.currentUserParkingSourceCheckeds,
        { ...defaultUserParkingSourceChecked }
      ]
    },
    removeUserParkingSourceChecked(params) {
      this.currentUserParkingSourceCheckeds.splice(params.index, 1)
      this.$emit('update-user-parking-source-checkeds')
    },
    updateUserParkingSourceChecked() {
      this.$emit('update-user-parking-source-checkeds')
    },
    setCurrentUserParkingSourceCheckeds() {
      this.currentUserParkingSourceCheckeds =
        this.userParkingSourceCheckeds &&
        this.userParkingSourceCheckeds.length > 0
          ? this.userParkingSourceCheckeds
          : [{ ...defaultUserParkingSourceChecked }]

      this.currentUserParkingSourceCheckeds = this.currentUserParkingSourceCheckeds
        .slice()
        .reverse()
    }
  }
}
</script>
