<template>
  <div
    v-if="isShowSearchHintUsers && searchHintUsersResult.totalCount > 0" 
    class="serving-contact-user-search-hint">
    <div class="hint">
      <button
        :class="isShowResult ? 'open' : 'close'"
        class="mdc-button"
        @click="showHintUsers()">
        {{ hintButtonLabel }}
      </button>
    </div>
    <serving-contact-user-search-result
      v-if="isShowResult"
      :users="searchHintUsersResult"
      :is-searching-hint-user="true"/>
  </div>
</template>
<script>
import { get, dispatch, call } from 'vuex-pathify'
import servingContactUserSearchResult from '@/components/drawer/serving-contact-user-search-result'

export default {
  components: {
    servingContactUserSearchResult
  },
  data() {
    return {
      isShowResult: false
    }
  },
  computed: {
    searchParams: get('search/servingContactUsers@searchParams'),
    searchHintUsersResult: get('search/servingContactUsers@searchHintResult'),
    isShowSearchHintUsers: get('ui/searchServingContactHintUsers@isShow'),
    hintButtonLabel() {
      return this.isShowResult
        ? '管理会社あるいはオーナーを閉じる'
        : `管理会社あるいはオーナーも表示 (${
            this.searchHintUsersResult.totalCount
          }件)`
    }
  },
  watch: {
    isShowSearchHintUsers(value) {
      if (value) {
        this.isShowResult = false
        this.getHintUsers()
      }
    }
  },
  methods: {
    getHintUsers() {
      // Storeに検索パラメーターを設定する
      dispatch('search/servingContactUsers/setSearchParams', {
        ...this.searchParams,
        searchHintUser: true
      })
      // ヒントユーザーリストを取得する
      dispatch('search/servingContactUsers/getHintUsers')
    },
    showHintUsers() {
      this.isShowResult = !this.isShowResult
    }
  }
}
</script>
