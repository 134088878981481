<template>
  <div class="phone-note-list-container">
    <div class="status">
      <div class="statuschooser">
        <div class="mdc-checkbox -unconfirmed -notlast">
          <input
            v-model="unconfirmed"
            type="checkbox"
            class="nativecontrol">
        </div>
        <p class="text">未承諾</p>
        <span
          :class="{'-empty': unconfirmedPhoneNoteCount === 0 }"
          class="notification">{{ unconfirmedPhoneNoteCount }}</span>
      </div>
      <div class="statuschooser">
        <div class="mdc-checkbox -unfinished">
          <input
            v-model="unfinished"
            type="checkbox"
            class="nativecontrol">
        </div>
        <p class="text">未完了</p>
        <span
          :class="{'-empty': unfinishedPhoneNoteCount === 0 }"
          class="notification">{{ unfinishedPhoneNoteCount }}</span>
      </div>
    </div>
    <div class="phone-note-container">
      <div
        v-if="phoneNotes.length === 0"
        class="nonotification">
        現在受信伝言がありません。
      </div>
      <div
        v-for="note in phoneNotes"
        :key="note.id"
        :class="{ '-unread': note.attributes.notifyReceiver === true }"
        class="item">
        <div class="addtionalinfo">
          <span class="date">{{ $helpers.formatDateFromFirestore(note.updatedAt) }} from {{ note.sender.username }}</span>
        </div>
        <div class="phone-note-content">
          <div
            class="content"
            @click="showDetailNotification(note)">
            <p
              v-show="note.content.message"
              class="text"
              v-html="$helpers.nl2br($helpers.formatMessage(note.content.message, 2, 20))"></p>
            <div class="actions">
              <div class="statebox">
                <div
                  :class="{'-confirmed': !note.attributes.isDone && note.attributes.isHandled, '-finished': note.attributes.isDone}"
                  class="state">
                  <span class="text">{{ getStatusText(note) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="canLoadNextReceivedPhoneNotes"
        class="loadmore">
        <button
          class="mdc-button loadmorebtn"
          @click="showNextNote">
          もっと見る
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { get, call, sync } from 'vuex-pathify'
import {
  MESSAGE_CONFIRM_FINISH_NOTE,
  PHONE_NOTE_DETAIL_TYPE_RECEIVER
} from '@/store/notifications'

export default {
  data() {
    return {
      unconfirmed: false,
      unfinished: false
    }
  },
  computed: {
    phoneNotes: get('notifications/phoneNotes'),
    canLoadNextReceivedPhoneNotes: get(
      'notifications/canLoadNextReceivedPhoneNotes'
    ),
    receivedPhoneNotesPages: sync('notifications/receivedPhoneNotesPages'),
    receivedPhoneNotesDisplayUnconfirmed: sync(
      'notifications/receivedPhoneNotesDisplayUnconfirmed'
    ),
    receivedPhoneNotesDisplayUnfinished: sync(
      'notifications/receivedPhoneNotesDisplayUnfinished'
    ),
    unconfirmedPhoneNoteCount: get('notifications/unconfirmedPhoneNoteCount'),
    unfinishedPhoneNoteCount: get('notifications/unfinishedPhoneNoteCount'),
    open: sync('ui/phoneNoteList@open')
  },
  watch: {
    unconfirmed() {
      this.receivedPhoneNotesDisplayUnconfirmed = this.unconfirmed
      if (this.unconfirmed) {
        this.unfinished = false
        this.receivedPhoneNotesDisplayUnfinished = false
      }
      this.watchReceivedPhoneNotes()
    },
    unfinished() {
      this.receivedPhoneNotesDisplayUnfinished = this.unfinished
      if (this.unfinished) {
        this.unconfirmed = false
        this.receivedPhoneNotesDisplayUnconfirmed = false
      }
      this.watchReceivedPhoneNotes()
    }
  },
  methods: {
    showNotificationDetail: call('notifications/showNotificationDetail'),
    watchReceivedPhoneNotes: call('notifications/watchReceivedPhoneNotes'),
    getStatusText(note) {
      if (note.attributes.isDone) {
        return '完了済'
      }
      if (note.attributes.isHandled) {
        return '未完了'
      }
      return '未承諾'
    },
    showNextNote() {
      this.receivedPhoneNotesPages = this.receivedPhoneNotesPages + 1
      this.watchReceivedPhoneNotes()
    },
    showDetailNotification(note) {
      this.showNotificationDetail({
        notificationId: note.id,
        notificationType: note.type,
        to: PHONE_NOTE_DETAIL_TYPE_RECEIVER
      })
      this.open = false
    }
  }
}
</script>
