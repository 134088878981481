import { make } from 'vuex-pathify'
import { API_STAFFS_URL } from '@/config/api-url'

export const state = () => ({
  summaries: {
    callHistories: {}
  },
  callHistories: {
    list: [],
    nextPage: ''
  }
})

export const mutations = { ...make.mutations(state) }

export const actions = {
  ...make.actions(state),
  async getCallHistorySummaries(context) {
    const staffId = context.rootGetters['staffId']
    try {
      const response = await this.$axios.get(
        `${API_STAFFS_URL}/${staffId}/call-history-summary`
      )
      context.commit('SET_SUMMARIES', {
        ...context.state.summaries,
        callHistories: response.data
      })
    } catch (error) {
      context.commit('SET_SUMMARIES', {
        ...context.state.summaries,
        callHistories: {}
      })
      this.$sentry.captureException(error)
    }
  },
  async getCallHistoryList(context) {
    const staffId = context.rootGetters['staffId']
    try {
      const requestUrl = context.state.callHistories.nextPage
        ? context.state.callHistories.nextPage
        : `${API_STAFFS_URL}/${staffId}/call-histories`
      const response = await this.$axios.get(requestUrl)
      context.commit('SET_CALL_HISTORIES', {
        ...context.state.callHistories,
        list: [...context.state.callHistories.list, ...response.data],
        nextPage: this.app.$helpers.parseLinkHeader(response.headers['link'])
          .next
      })
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}
