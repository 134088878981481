export const API_RESPONSE_ERROR_MESSAGE = {
  400: '検索条件が間違っています。もう一度ご確認ください。',
  401: 'ログイン認証に失敗しました。もう一度実行してください。',
  404: 'サーバーにアクセスが集中しています。しばらく経ってから実行してください。',
  500: 'サーバーに問題が発生しました。管理者にご連絡ください。'
}

export const BIZTEL_INDEX_PAGE_ERRORS = {
  CALL_HISTORY_CREATE_FAIL: '着信履歴の作成が失敗しました。',
  CALL_HISTORY_SEARCH_FAIL: '着信履歴の検索が失敗しました。',
  USERS_SEARCH_FAIL: 'ユーザー検索が失敗しました。',
  TEL_INVALID: '電話番号が間違いました。',
  CALL_ID_INVALID: 'コールIDが間違いました。'
}
