import pathify, { make } from 'vuex-pathify'

export const plugins = [pathify.plugin]

export const state = () => ({})

export const getters = {
  ...make.getters(state),
  staffId: _ =>
    _.auth && _.auth.user && _.auth.user.id ? _.auth.user.id : undefined,
  staffName: _ =>
    _.auth && _.auth.user && _.auth.user.username
      ? _.auth.user.username
      : undefined,
  staffEmail: _ =>
    _.auth && _.auth.user && _.auth.user.email ? _.auth.user.email : undefined
}

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  // nuxtServerInit is called by Nuxt.js before server-rendering every page
  async nuxtServerInit({ commit }, { app, req, redirect }) {}
}
