<template>
  <div
    v-show="show"
    class="notification-detail-phone-reception-request">
    <div class="additionalinfo">
      <p class="text">{{ updatedTime }} from {{ senderName }}</p>
      <div class="notificationstatus">
        <span
          :class="{ '-group': isSendToGroup }"
          class="icon -circle"></span>
        <p
          :class="{ '-done': isDone }"
          class="status -ellipse">{{ status }}</p>
      </div>
    </div>
    <div class="phone-reception-request-notification-detail">
      <div class="content">
        <p
          class="text"
          v-html="notificationMessage"></p>
      </div>
      <div
        v-show="!isDone"
        class="btns" >
        <button
          :disabled="refusedNotification"
          class="mdc-button -disresponse"
          @click="refuseRequest(notification.id)">対応不可</button>
        <button
          :disabled="refusedNotification"
          class="mdc-button -raised"
          @click="acceptRequest(notification.id)">対応する</button>
      </div>
      <div
        v-show="isDone"
        class="acceptedstaff">{{ handledStaffName }}さんが対応しました</div>
    </div>
  </div>
</template>
<script>
import { get, call } from 'vuex-pathify'
import { COLLECTION_PHONE_RECEPTION_REQUEST } from '@/store/notifications'
export default {
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    staffId: get('staffId'),
    show() {
      return (
        this.isNotification &&
        this.notification.type === COLLECTION_PHONE_RECEPTION_REQUEST
      )
    },
    updatedTime() {
      return this.isNotification
        ? this.$helpers.formatDateFromFirestore(this.notification.updatedAt)
        : ''
    },
    senderName() {
      return this.isNotification && this.notification.sender
        ? this.notification.sender.username
        : ''
    },
    status() {
      if (this.isNotification && this.notification.attributes) {
        if (this.notification.attributes.handlerId) {
          return '対応済み'
        }
        if (this.notification.attributes.timeout) {
          return 'タイムアウト'
        }
        return '未対応'
      }
      return ''
    },
    notificationMessage() {
      return this.isNotification && this.notification.content
        ? this.$helpers.nl2br(this.notification.content.message)
        : ''
    },
    handledStaffName() {
      if (
        this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.handlerId
      ) {
        const handlerId = this.notification.attributes.handlerId
        if (
          this.notification.receivers &&
          this.notification.receivers[handlerId]
        ) {
          return this.notification.receivers[handlerId].username
        }
        if (
          this.notification.sender &&
          handlerId === this.notification.sender.id
        ) {
          return this.notification.sender.username
        }
      }
      return ''
    },
    isNotification() {
      // check notification format
      return !!this.notification.id
    },
    isSendToGroup() {
      return (
        this.isNotification &&
        this.notification.receiverIds &&
        this.notification.receiverIds.length > 1
      )
    },
    isDone() {
      return (
        this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.handlerId
      )
    },
    refusedNotification() {
      return (
        this.isNotification &&
        this.notification.attributes &&
        this.notification.attributes.pendingIds &&
        !this.notification.attributes.pendingIds.includes(this.staffId)
      )
    }
  },
  methods: {
    hideNotificationDetail: call('notifications/hideNotificationDetail'),
    setRequestIsAccepted: call('notifications/setRequestIsAccepted'),
    setRequestIsRefused: call('notifications/setRequestIsRefused'),
    acceptRequest(requestId) {
      if (requestId) {
        this.setRequestIsAccepted({ requestId })
        this.hideNotificationDetail()
      }
    },
    refuseRequest(requestId) {
      if (requestId) {
        this.setRequestIsRefused({ requestId })
        this.hideNotificationDetail()
      }
    }
  }
}
</script>
