<template>
  <div
    v-show="showSnackBar"
    class="dialog-snack-bar">
    <p class="text">{{ message }}</p>
  </div>
</template>
<script>
import { get } from 'vuex-pathify'
export default {
  computed: {
    showSnackBar: get('ui/snackBar@isShow'),
    message: get('ui/snackBar@message')
  }
}
</script>
