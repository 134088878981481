<template>
  <div
    v-show="open"
    class="phone-note-list -elevation8">
    <div class="title">
      <p class="text">伝言メモ</p>
    </div>
    <div
      class="mdc-tab-bar -elevation1"
      role="tablist">
      <div class="mdc-tab-scroller">
        <div class="scrollarea">
          <div class="scrollcontent tab-content">
            <button
              class="mdc-tab -active"
              role="tab"
              tabindex="0"
              aria-selected="true"
              @click="tabClick(DISPLAY_RECEIVED)">
              <span
                class="content">
                <span class="text-label">受信</span>
                <span
                  v-show="hasReceivedPhoneNoteNotification"
                  class="notification -circle">{{ unconfirmedPhoneNoteCount + unfinishedPhoneNoteCount }}</span>
                <div class="indicator -active">
                  <div class="content -underline">
                  </div>
                </div>
              </span>
            </button>
            <button
              class="mdc-tab"
              role="tab"
              tabindex="1"
              @click="tabClick(DISPLAY_SENT)">
              <span
                class="content">
                <span class="text-label">送信</span>
                <span
                  v-show="hasSentPhoneNoteNotification"
                  class="notification -circle">{{ unhandledPhoneNoteCount }}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <phone-note-list-receive v-show="display === DISPLAY_RECEIVED" />
    <phone-note-list-sent v-show="display === DISPLAY_SENT" />
  </div>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import phoneNoteListReceive from '@/components/notification-phone-note-list-receive'
import phoneNoteListSent from '@/components/notification-phone-note-list-sent'

const DISPLAY_RECEIVED = 'received'
const DISPLAY_SENT = 'sent'

export default {
  components: {
    phoneNoteListReceive,
    phoneNoteListSent
  },
  data() {
    return {
      DISPLAY_RECEIVED,
      DISPLAY_SENT,
      display: DISPLAY_RECEIVED
    }
  },
  computed: {
    open: sync('ui/phoneNoteList@open'),
    hasReceivedPhoneNoteNotification: get(
      'notifications/hasReceivedPhoneNoteNotification'
    ),
    hasSentPhoneNoteNotification: get(
      'notifications/hasSentPhoneNoteNotification'
    ),
    unconfirmedPhoneNoteCount: get('notifications/unconfirmedPhoneNoteCount'),
    unfinishedPhoneNoteCount: get('notifications/unfinishedPhoneNoteCount'),
    unhandledPhoneNoteCount: get('notifications/unhandledPhoneNoteCount')
  },
  methods: {
    tabClick(type) {
      this.display = type
    }
  }
}
</script>
