import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({
  beforeContracts: {
    list: [],
    total: 0,
    nextPage: ''
  },
  effectiveContracts: {
    list: [],
    total: 0,
    nextPage: ''
  },
  endedContracts: {
    list: [],
    total: 0,
    nextPage: ''
  }
})

export const mutations = { ...make.mutations(state) }

export const actions = {
  ...make.actions(state),
  async getBeforeContractFirstPage(context, params = {}) {
    try {
      const res = await this.$axios.get(
        `${API_USERS_URL}/${params.userId || 0}/subleases`,
        { params: { type: 'beforeContract' } }
      )
      context.commit('SET_BEFORE_CONTRACTS', {
        list: res.data || [],
        total: res.headers['x-total-count'] || res.data.length || 0,
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async getBeforeContractNextPage(context) {
    try {
      const res = await this.$axios.get(context.state.beforeContracts.nextPage)
      context.commit('SET_BEFORE_CONTRACTS', {
        list: [...context.state.beforeContracts.list, ...res.data] || [],
        total: res.headers['x-total-count'] || res.data.length || 0,
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async getEffectiveContractFirstPage(context, params = {}) {
    try {
      const res = await this.$axios.get(
        `${API_USERS_URL}/${params.userId || 0}/subleases`,
        { params: { type: 'effectiveContract' } }
      )
      context.commit('SET_EFFECTIVE_CONTRACTS', {
        list: res.data || [],
        total: res.headers['x-total-count'] || res.data.length || 0,
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async getEffectiveContractNextPage(context) {
    try {
      const res = await this.$axios.get(
        context.state.contracts.effectiveContracts.nextPage
      )
      context.commit('SET_EFFECTIVE_CONTRACTS', {
        list:
          [...context.state.contracts.effectiveContracts.list, ...res.data] ||
          [],
        total: res.headers['x-total-count'] || res.data.length || 0,
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async getEndedContractFirstPage(context, params = {}) {
    try {
      const res = await this.$axios.get(
        `${API_USERS_URL}/${params.userId || 0}/subleases`,
        { params: { type: 'endedContract' } }
      )
      context.commit('SET_ENDED_CONTRACTS', {
        list: res.data || [],
        total: res.headers['x-total-count'] || res.data.length || 0,
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async getEndedContractNextPage(context) {
    try {
      const res = await this.$axios.get(
        context.state.contracts.endedContracts.nextPage
      )
      context.commit('SET_ENDED_CONTRACTS', {
        list:
          [...context.state.contracts.endedContracts.list, ...res.data] || [],
        total: res.headers['x-total-count'] || res.data.length || 0,
        nextPage: this.app.$helpers.parseLinkHeader(res.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
