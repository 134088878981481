import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({
  lastSearchResult: null,
  otherUserSearchResult: {
    list: [],
    totalCount: 0,
    nextPage: '',
    errorMessage: ''
  }
})

export const mutations = {
  ...make.mutations(state)
}

export const actions = {
  ...make.actions(state),
  // 他の支店アリストを取得する
  async getOtherUsers(context, params = {}) {
    try {
      const result = await this.$axios.get(`${API_USERS_URL}`, {
        params
      })
      if (!result || !result.data || result.data.length === 0) {
        context.commit('SET_OTHER_USER_SEARCH_RESULT', {
          list: [],
          totalCount: 0,
          nextPage: '',
          errorMessage: ''
        })
      } else {
        context.commit('SET_OTHER_USER_SEARCH_RESULT', {
          list: result.data,
          totalCount: result.headers['x-total-count'],
          nextPage: this.app.$helpers.parseLinkHeader(result.headers['link'])
            .next,
          errorMessage: ''
        })
      }
    } catch (e) {
      context.commit('SET_OTHER_USER_SEARCH_RESULT', {
        list: [],
        totalCount: 0,
        nextPage: '',
        errorMessage: 'エラーが発生しました。'
      })
    }
  },

  // 次の他の支店アリストを取得する
  async getMoreOtherUsers(context) {
    try {
      if (!context.state.otherUserSearchResult.nextPage) {
        return
      }
      const result = await this.$axios.get(
        context.state.otherUserSearchResult.nextPage
      )
      context.commit('SET_OTHER_USER_SEARCH_RESULT', {
        list: [...context.state.otherUserSearchResult.list, ...result.data],
        totalCount: result.headers['x-total-count'],
        nextPage: this.app.$helpers.parseLinkHeader(result.headers['link']).next
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
