<template>
  <div class="company-phone-list">
    <div
      class="mdc-tab-bar -elevation1"
      role="tablist">
      <div class="mdc-tab-scroller">
        <div class="scrollarea">
          <div class="scrollcontent tab-content">
            <button
              id="company-phones-leasing"
              class="mdc-tab -active"
              role="tab"
              tabindex="0"
              aria-selected="true"
              @click="tabClick('leasing')">
              <span
                class="content">
                <span class="textlabel">リーシングマネジメント部</span>
                <div class="indicator -active">
                  <div class="content -underline">
                  </div>
                </div>
              </span>
              <span class="mdc-tab-indicator">
                <span class="content -underline"></span>
              </span>
              <span class="tabripple"></span>
            </button>

            <button
              class="mdc-tab"
              role="tab"
              tabindex="1"
              @click="tabClick('solution')">
              <span
                class="content">
                <span class="textlabel">ソリューション部</span>
              </span>
              <span class="mdc-tab-indicator">
                <span class="content -underline"></span>
              </span>
              <span class="tabripple"></span>
            </button>

            <button
              class="mdc-tab"
              role="tab"
              tabindex="2"
              @click="tabClick('branchOffice')">
              <span
                class="content">
                <span class="textlabel">支社</span>
              </span>
              <span class="mdc-tab-indicator">
                <span class="content -underline"></span>
              </span>
              <span class="tabripple"></span>
            </button>

            <button
              class="mdc-tab"
              role="tab"
              tabindex="3"
              @click="tabClick('other')">
              <span
                class="content">
                <span class="textlabel">他部署・その他</span>
              </span>
              <span class="mdc-tab-indicator">
                <span class="content -underline"></span>
              </span>
              <span class="tabripple"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <section
      id="company-phones-result"
      class="result">
      <div
        v-for="(department, index) in departments"
        :key="index"
        class="company-phone-list-title">
        <p class="text">{{ department.name }}</p>
        <div class="tel">
          <p
            :id="`${department.name.trim()}${index}`"
            class="text">{{ department.tel || department.biztelNumber || '電話番号なし' }}</p>
          <copy-button
            v-show="department.tel || department.extensionNumber"
            :el-id="`${department.name.trim()}${index}`" />
        </div>
      </div>

      <div class="items">
        <div
          v-show="isShowStaffs"
          class="company-phone-list-item -subject">
          <p class="item">名前</p>
          <div class="item -extension">
            <p class="text">内線</p>
          </div>
          <div class="item -extension">
            <p class="text">携帯電話</p>
          </div>
        </div>
        <div
          v-show="isShowStaffs"
          class="company-phone-list-item -subject">
          <p class="item">名前</p>
          <div class="item -extension">
            <p class="text">内線</p>
          </div>
          <div class="item -extension">
            <p class="text">携帯電話</p>
          </div>
        </div>
      </div>

      <div class="items">
        <div
          v-for="(staff, index) in staffs"
          :key="index"
          class="company-phone-list-item">
          <p class="item">{{ staff.username || 'なし' }}</p>
          <div class="item -extension">
            <p
              :id="`staff_extension_${staff.id}`"
              class="text">{{ staff.extensionNumber || 'なし' }}</p>
            <copy-button
              v-show="staff.extensionNumber"
              :el-id="`staff_extension_${staff.id}`"/>
          </div>
          <div class="item">
            <p
              :id="`staff_tel_${staff.id}`"
              class="text">{{ $options.filters.formatPhoneNumber(staff.tel) || 'なし' }}</p>
            <copy-button
              v-show="staff.tel"
              :el-id="`staff_tel_${staff.id}`"/>
          </div>
        </div>
      </div>

      <div
        v-show="isShowPagination"
        class="company-phone-pagination">
        <pagination
          :page-count = "totalCount"
          :page-range = "pageSize"
          @click-handler="paginationClickHandler"/>
      </div>

    </section>
  </div>
</template>
<script>
import {
  API_DEPARTMENTS_URL,
  API_ORIGINS_URL,
  API_DIVISIONS_URL
} from '@/config/api-url'
import copyButton from '@/components/copy-button'
import pagination from '@/components/pagination'
import { get, sync } from 'vuex-pathify'
export default {
  components: {
    copyButton,
    pagination
  },
  data() {
    return {
      departments: [],
      staffs: [],
      totalCount: 0,
      page: 1,
      pageSize: 11,
      tabName: 'leasing',
      isShowStaffs: true,
      departmentsStaffsLimit: 30
    }
  },
  computed: {
    authStaff: get('auth@user'),
    isShowCompanyPhones: sync('ui/companyPhones@isShow')
  },
  watch: {
    staffs() {
      this.isShowStaffs = this.staffs.length !== 0
    },
    isShowAreasStaffs(value) {
      if (value) {
        document.getElementById('company-phones-result').scroll({
          top: 0,
          behavior: 'smooth'
        })
        document.getElementById('head-office').click()
      }
    }
  },
  async mounted() {
    await this.getDepartmentsStaffs({ department: 'leasing' })
  },
  methods: {
    isShowPagination() {
      return this.totalCount !== 0
    },
    async getDepartmentsStaffs(params = {}) {
      const { department, pageSize } = params
      const res = await this.$axios.get(`${API_DEPARTMENTS_URL}/staffs`, {
        params: {
          department: department,
          originId: this.authStaff.originId || 1,
          page: this.page,
          pageSize: pageSize || this.departmentsStaffsLimit
        }
      })
      this.departments = res.data.department ? [res.data.department] : []
      this.staffs = res.data.staffs || []
      this.totalCount = res.headers['x-total-count']
        ? Math.floor(
            res.headers['x-total-count'] /
              (pageSize || this.departmentsStaffsLimit)
          ) + 1
        : 0
    },
    async getBranchOffice() {
      const res = await this.$axios.get(`${API_ORIGINS_URL}`)
      this.staffs = []
      this.departments = res.data
      this.totalCount = 0
    },
    async getOther(params = {}) {
      const { searchType } = params
      const res = await this.$axios.get(`${API_DIVISIONS_URL}`, {
        params: {
          searchType: searchType || 'other'
        }
      })
      this.staffs = []
      this.departments = res.data
      this.totalCount = 0
    },
    paginationClickHandler(params = {}) {
      this.page = params.curPage
      document.getElementById('company-phones-result').scroll({
        top: 0,
        behavior: 'smooth'
      })
      switch (this.tabName) {
        case 'leasing':
          this.getDepartmentsStaffs({ department: 'leasing' })
          break
        case 'solution':
          this.getDepartmentsStaffs({ department: 'solution' })
          break
      }
    },
    tabClick(tabName) {
      if (this.tabName === tabName) {
        return
      }

      this.page = 1
      this.tabName = tabName
      switch (tabName) {
        case 'leasing':
          this.getDepartmentsStaffs({ department: 'leasing' })
          this.pageSize = 6
          break
        case 'solution':
          this.getDepartmentsStaffs({ department: 'solution' })
          this.pageSize = 6
          break
        case 'branchOffice':
          this.getBranchOffice()
          break
        case 'other':
          this.getOther()
          break
      }
    }
  }
}
</script>
