import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/messaging'

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

let firestore = {}
let messaging = {}

if (!process.server) {
  firestore = firebase.firestore()
  messaging = firebase.messaging()
}

export default (context, inject) => {
  inject('firebase', firebase)
  inject('firestore', firestore)
  inject('messaging', messaging)
}
export { firebase }
