<template>
  <div class="drawer-user-group-search-form">
    <div class="user-group-search-form -elevation1">
      <div class="title">他の条件でユーザーグループ検索</div>
      <div class="mdc-layout-grid formcontainer">
        <div class="inner">
          <div class="cell -span6desktop -span8tablet -span4mobile user-group-form-input -groupname">
            <span class="label">グループ名</span>
            <div class="mdc-text-field -ripple forminput">
              <input
                v-model.trim="groupName"
                type="text"
                class="input"
                @keyup.enter="searchUserGroup">
            </div>
          </div>
          <div class="cell -span6desktop -span8tablet -span4mobile user-group-form-input -createdmanagerid">
            <span class="label">作成者</span>
            <name-search-input
              id="autocomplete-staff-for-search-users"
              :placeholder-disabled="true"
              class="searchbox forminput"
              @set-selected-staff="setSelectedStaff"
              @keyupEnter="searchUserGroup" />
          </div>
          <div class="cell -span6desktop -span8tablet -span4mobile user-group-form-input -createdat">
            <span class="label">作成日</span>
            <div class="mdc-text-field -trailingicon -ripple forminput -createdat">
              <input
                v-model.trim="createdAt"
                type="date"
                class="input"
                @keyup.enter="searchUserGroup">
              <span
                class="icon -createdat"
                tabindex="0"
                role="button"></span>
            </div>
          </div>
          <div class="cell -span6desktop -span8tablet -span4mobile user-group-form-input -groupid">
            <span class="label">グループID</span>
            <div class="mdc-text-field -ripple forminput">
              <input
                v-model.trim="groupId"
                type="text"
                class="input"
                @keyup.enter="searchUserGroup">
            </div>
          </div>
          <div class="cell -span6desktop -span8tablet -span4mobile user-group-form-input -status">
            <span class="label">状態</span>
            <div class="checkbox forminput -status">
              <div class="mdc-checkbox -unconfirmed -notlast">
                <input
                  id="status-checkbox-1"
                  v-model.number="isEnabled"
                  type="checkbox"
                  value="1"
                  class="nativecontrol">
              </div>
              <label
                class="checkboxlabel"
                for="status-checkbox-1">有効</label>
              <div class="mdc-checkbox -unconfirmed -notlast">
                <input
                  id="status-checkbox-0"
                  v-model.number="isEnabled"
                  type="checkbox"
                  value="0"
                  class="nativecontrol">
              </div>
              <label
                class="checkboxlabel"
                for="status-checkbox-0">無効</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, sync, dispatch } from 'vuex-pathify'
import nameSearchInput from '@/components/search-staffs/name-search-input'

export default {
  components: {
    nameSearchInput
  },
  computed: {
    searchParams: get('search/userGroup/searchParams'),
    createdManagerId: sync('search/userGroup/searchParams@createdManagerId'),
    hasError: sync('search/userGroup/hasError'),
    groupName: {
      get() {
        return this.searchParams.groupName
      },
      set(value) {
        dispatch('search/userGroup/setSearchParams', {
          ...this.searchParams,
          groupName: value
        })
      }
    },
    createdAt: {
      get() {
        return this.searchParams.createdAt
      },
      set(value) {
        dispatch('search/userGroup/setSearchParams', {
          ...this.searchParams,
          createdAt: value
        })
      }
    },
    groupId: {
      get() {
        return this.searchParams.groupId
      },
      set(value) {
        dispatch('search/userGroup/setSearchParams', {
          ...this.searchParams,
          groupId: value
        })
      }
    },
    isEnabled: {
      get() {
        return this.searchParams.isEnabled
      },
      set(value) {
        dispatch('search/userGroup/setSearchParams', {
          ...this.searchParams,
          isEnabled: value
        })
      }
    }
  },
  watch: {
    isEnabled(value, oldValue) {
      if (value !== oldValue) {
        this.searchUserGroup()
      }
    }
  },
  methods: {
    searchUserGroup() {
      dispatch('search/userGroup/fetchUserGroup')
    },
    setSelectedStaff({ staff, staffName }) {
      if (staffName && staff.id) {
        this.createdManagerId = staff.id
        this.hasError = false
      } else if (!staffName && !staff.id) {
        this.createdManagerId = ''
        this.hasError = false
      } else {
        this.createdManagerId = ''
        this.hasError = true
      }
    }
  }
}
</script>
