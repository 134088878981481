<template>
  <div class="serving-contact-user-search-result section">
    <template v-if="users.list.length > 0">
      <div class="title">
        <div class="text">全{{ users.totalCount }}件</div>
        <div class="mdc-select -outlined papp-select">
          <select
            class="nativecontrol"
            @change="sortUsersList">
            <option
              v-for="(item, index) in filters"
              :value="item.value"
              :key="index">
              {{ item.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="mdc-layout-grid resultcontainer">
        <div class="inner">
          <user-search-info-card
            v-for="user in users.list"
            :key="user.id"
            :user="user"
            :reading-page="'searchUser'"
            :search-type="'customer'"
            class="cell -span6desktop -span8tablet -span4mobile"/>
        </div>
      </div>
      <pagination 
        :cur-page="currentPage"
        :page-count="numberOfPages"
        @click-handler="onClickPaginationHandler"/>
    </template>
    <template v-else>
      <div class="error-message -elevation1">
        {{ users.errorMessage ? users.errorMessage : customErrorMessage }}
      </div>
    </template>
  </div>
</template>
<script>
import { get, dispatch, call, sync } from 'vuex-pathify'
import userSearchInfoCard from '@/components/drawer/user-search-info-card'
import pagination from '@/components/pagination'

export default {
  components: {
    userSearchInfoCard,
    pagination
  },
  props: {
    users: {
      type: Object,
      default: () => {}
    },
    searchHintUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filters: [
        { value: 'updatedAt', text: '新着順' },
        { value: 'managingParkingCount', text: '管理物件数順' },
        { value: 'owningParkingCount', text: 'オーナー物件数順' },
        { value: 'servingContactsCount', text: '進行中案件数順' }
      ],
      currentPage: 1,
      pageSize: 10
    }
  },
  computed: {
    searchParams: get('search/servingContactUsers@searchParams'),
    numberOfPages() {
      return Math.ceil(this.users.totalCount / this.pageSize)
    },
    customErrorMessage() {
      return this.searchHintUser
        ? '管理会社あるいはオーナー情報がありません'
        : '問い合わせユーザー情報がありません'
    }
  },
  methods: {
    getServingContactUsers: call('search/servingContactUsers/getUsers'),
    async onClickPaginationHandler({ curPage }) {
      // Storeに検索パラメーターを設定する
      dispatch('search/servingContactUsers/setSearchParams', {
        ...this.searchParams,
        searchHintUser: this.searchHintUser,
        page: curPage,
        pageSize: this.pageSize
      })
      // Storeのユーザー検索メソッドを呼び出す
      await this.getServingContactUsers()
      // Topページへスクロールする
      document.getElementById('serving-contact-user-search-content').scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    async sortUsersList(event) {
      // Storeにソートバリューを設定する
      dispatch('search/servingContactUsers/setSearchParams', {
        ...this.searchParams,
        sort: `${event.target.value}+DESC`,
        searchHintUser: this.searchHintUser
      })
      // Storeのユーザー検索メソッドを呼び出す
      await this.getServingContactUsers()
      // Topページへスクロールする
      document.getElementById('serving-contact-user-search-content').scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
