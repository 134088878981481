import { make } from 'vuex-pathify'
import { API_USERS_URL } from '@/config/api-url'

export const state = () => ({
  list: []
})

export const mutations = { ...make.mutations(state) }

export const actions = {
  ...make.actions(state),
  async createUserFax(context, params = {}) {
    try {
      const createFaxObj = await this.$axios.post(
        `${API_USERS_URL}/${params.userId}/faxes`,
        {
          fax: params.fax || '',
          name: params.name || '',
          nameFurigana: params.nameFurigana || '',
          memo: params.memo || '',
          staffId: params.staffId || 0,
          isEnabled: 1
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'FAX番号を保存しました'
      })
      return createFaxObj
    } catch (e) {
      this.$sentry.captureMessage(e)
    }
  },
  async updateUserFax(context, params = {}) {
    try {
      const updatedFax = await this.$axios.patch(
        `${API_USERS_URL}/${params.userId}/faxes/${params.faxId}`,
        {
          userId: params.userId,
          fax: params.fax || '',
          name: params.name || '',
          nameFurigana: params.nameFurigana || '',
          memo: params.memo || '',
          createdManagerId: params.staffId || 0,
          updatedManagerId: params.staffId || 0
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'FAX番号を保存しました'
      })
      return updatedFax
    } catch (e) {
      this.$sentry.captureMessage(e)
    }
  },
  async deleteUserFax(context, params = {}) {
    try {
      await this.$axios.delete(
        `${API_USERS_URL}/${params.userId}/faxes/${params.faxId}`,
        {
          data: {
            updatedManagerId: params.staffId || 0
          }
        }
      )
      this.app.store.dispatch('ui/showSnackBar', {
        message: 'FAX番号を削除しました'
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
